import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import useResponseHandler from "./useResponseHandler"
import { listDevices } from "../services/device"

export default function useFetchDevices(props) {
  const { limit, locationId, search, offset, order = "asc", orderBy = "name", organizationId } = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()

  useEffect(() => {
    getData(locationId, search, limit || rowsPerPages, offset, order, orderBy)
  }, [locationId, search, rowsPerPages, offset, order, orderBy])

  const getData = (locationId, search, limit, offset, order, orderBy) => {
    setLoading(true)
    listDevices({ organizationId, locationId, search, limit, offset, order, orderBy })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, totalCount }
}
