import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { getListMessagesLog } from "../../../services/device"

export default function useFetchDiagnostics(props) {
  const { isRunning, offset, externalId } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()

  useEffect(() => {
    getData(externalId)
    if (isRunning) {
      const interval = setInterval(() => {
        getData(externalId)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [externalId, isRunning])

  const getData = (externalId) => {
    setLoading(true)
    getListMessagesLog(externalId || "all")
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data: data.slice(offset, offset + rowsPerPages), loading, totalCount }
}
