import { Link, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function Crumb({ crumb, isLast }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { text, Icon } = crumb.handle.crumb

  const crumbItem = [text && t(text), crumb.data].filter(Boolean).join(" ")

  return (
    <>
      {isLast ? (
        <Typography color="text.primary" fontSize="1.25rem" sx={{ cursor: "default", display: "flex", alignItems: "center" }}>
          {Icon && <Icon sx={{ mr: 1 }} fontSize="inherit" />} {crumbItem}
        </Typography>
      ) : (
        <Link
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          color="inherit"
          fontSize="1.25rem"
          underline="hover"
          onClick={() => navigate(crumb.pathname)}>
          {Icon && <Icon sx={{ mr: 1 }} fontSize="inherit" />} {crumbItem}
        </Link>
      )}
    </>
  )
}
