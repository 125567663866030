import { LinearProgress, Link, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import NoData from "../../components/NoData"

import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchDevices from "../../hooks/useFetchDevices"
import CheckedIcon from "../../components/CheckedIcon"
import { useTranslation } from "react-i18next"

function DeviceStatus({ isOnline }) {
  const { t } = useTranslation()
  return isOnline ? (
    <Typography variant="body2" color="success.main">
      {t("online")}
    </Typography>
  ) : (
    <Typography variant="body2" color="error.main">
      {t("offline")}
    </Typography>
  )
}

function RelayState({ state }) {
  const { t } = useTranslation()
  if (state?.relay_on) {
    return <b>{t("On")}</b>
  } else {
    return t("Off")
  }
}

function stateComponent(value, item) {
  const state = JSON.parse(value)
  switch (item.stateKind) {
    case 1:
      return <RelayState state={state} />
    default:
      return null
  }
}

export default function DeviceTable({ locationId, search }) {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const { data, loading, totalCount } = useFetchDevices({ locationId, search, offset, order, orderBy, organizationId })

  const toDeviceInfo = (value, item) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`${item.id}`)}>
      {value}
    </Link>
  )

  const cells = [
    { label: "#", name: "id", width: 10, isUnorderable: true },
    { label: "Device name", name: "name", component: toDeviceInfo },
    { label: "Device type", name: "typeName" },
    { label: "Location Name", name: "locationName" },
    { label: "Status", name: "isOnline", component: (value) => <DeviceStatus isOnline={value} />, width: 10 },
    { label: "State", name: "state", component: (value, item) => stateComponent(value, item), width: 10 },
    { label: "Time sync", name: "timeSyncOk", component: (value) => <CheckedIcon state={value} />, isUnorderable: true },
  ]

  return (
    <>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <TableDataLayout
          {...{
            loading,
            data,
            totalCount,
            order,
            setOrder,
            orderBy,
            setOrderBy,
            offset,
            setOffset,
            cells,
            page,
            setPage,
          }}
        />
      )}
      {loading && <LinearProgress />}
    </>
  )
}
