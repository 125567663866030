import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function getUser(id, organizationId) {
  return client.apis.UserService.UserService_Get({ id, organizationId })
}

export async function getUserIdByEmail(email) {
  return client.apis.UserService.UserService_GetUserIDByEmail({ email })
}

export async function createUser(data) {
  return client.apis.UserService.UserService_Create({ body: data })
}

export async function updateUser(user, organizationId) {
  return client.apis.UserService.UserService_Update({ body: { user, organizationId } })
}

export async function updatePassword(id, password) {
  return client.apis.UserService.UserService_UpdatePassword({ id, body: { password } })
}

export async function updatePasswordByAdmin(id, password, organizationId) {
  return client.apis.UserService.UserService_UpdatePasswordByAdmin({ id, body: { password, organizationId } })
}

export async function listUsers(filters) {
  return client.apis.UserService.UserService_List(filters)
}

export async function deleteUser(id) {
  return client.apis.UserService.UserService_Delete({ id })
}

export async function getListLogs(filters) {
  return client.apis.UserService.UserService_ListLogs(filters)
}
