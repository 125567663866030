import { Fab, Slide } from "@mui/material"
import { useEffect, useState } from "react"
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

export default function FAButton() {
  const [visible, setVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
        const scrolled = document.documentElement.scrollTop
        scrolled > 0 ? setVisible(true) : setVisible(false)
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Slide direction="up" in={visible}>
        <Fab
          onClick={scrollToTop}
          color="primary"
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(10),
            right: (theme) => theme.spacing(10),
          }}>
          <ExpandLessIcon />
        </Fab>
      
    </Slide>
  )
}
