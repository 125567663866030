import { Paper, Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { LayerGroup, LayersControl, MapContainer, TileLayer } from "react-leaflet"
import DeviceMarker from "./DeviceMarker"

export default function MarkersOnMap({ loading, data, withPopup }) {
  const { t } = useTranslation()
  // const defaultCenter = [55.75168, 37.618775]
  // const center = defaultCenter ||
  const size = { width: "100wh", height: "600px" }
  const bounds = data.map((item) => [item.latitude, item.longitude])

  const onlineDevices = data.filter((item) => item.isOnline)
  const offlineDevices = data.filter((item) => !item.isOnline)

  return (
    <Paper>
      {loading ? (
        <Skeleton variant="rounded" {...size} animation="wave" />
      ) : (
        <MapContainer bounds={bounds} attributionControl={false} style={{ minHeight: "600px", width: "100wh" }} zoom={15} scrollWheelZoom={true}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LayersControl position="topright">
            <LayersControl.Overlay checked name={t("online") + ` (${onlineDevices.length})`}>
              <LayerGroup>
                {onlineDevices.map((item, index) => {
                  const state = JSON.parse(item.state)
                  {
                    return (
                      <DeviceMarker
                        id={item.id}
                        isRelay={Boolean(item.stateKind)}
                        isOnline={item.isOnline}
                        state={state?.relay_on}
                        withPopup
                        key={index}
                        name={item.name}
                        position={[item.latitude, item.longitude]}
                      />
                    )
                  }
                })}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name={t("offline") + ` (${offlineDevices.length})`}>
              <LayerGroup>
                {offlineDevices.map((item, index) => {
                  const state = JSON.parse(item.state)
                  {
                    return (
                      <DeviceMarker
                        id={item.id}
                        isRelay={Boolean(item.stateKind)}
                        isOnline={item.isOnline}
                        state={state?.relay_on}
                        withPopup
                        key={index}
                        name={item.name}
                        position={[item.latitude, item.longitude]}
                      />
                    )
                  }
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      )}
    </Paper>
  )
}
