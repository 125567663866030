import { Collapse, TableCell, TableRow } from "@mui/material"

export default function CollapsibleTableItemRow({ cells, Item, dataItem, open }) {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={cells.length + 1}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Item dataItem={dataItem} />
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
