import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { listDeviceTypes } from "../../../services/device"

export default function useFetchDeviceTypes(props) {
 const {search, offset, order, orderBy, organizationId, limit} = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer  ) 
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()
 
  useEffect(() => {
    getData(search, limit || rowsPerPages, offset, order, orderBy)
  }, [search, limit, rowsPerPages, offset, order, orderBy])

  const getData = (search, limit, offset, order, orderBy) => {
    setLoading(true)
    listDeviceTypes({ search, limit, offset, order, orderBy, organizationId })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, totalCount }
}
