import { Button, LinearProgress, Link } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"

import NoData from "../../components/NoData"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchDeviceTypes from "./hooks/useFetchDeviceTypes"

export default function DeviceTypes() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  
  const { organizationId } = useParams()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")

  const params = { search, offset, order, orderBy, organizationId }
  const { data, loading, totalCount } = useFetchDeviceTypes(params)

  const toDeviceTypeCard = (value, item) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`${item.id}/edit`)}>
      {value}
    </Link>
  )

  const cells = [
    { label: "#", name: "id", width: 10, isUnorderable: true },
    { label: "Device type name", name: "name", component: toDeviceTypeCard },
  ]

  return (
    <ContentLayout
      title="Device Types"
      actions={[
        <SearchBar {...{ search, setSearch }} />,
        <Private roleRequired="globalAdmin">
          <Button onClick={() => navigate("create")} variant="contained" startIcon={<AddIcon />}>
            {t("Create")}
          </Button>
        </Private>,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <TableDataLayout
          {...{ data, cells, orderBy, setOrderBy, order, setOrder, setOffset, page, setPage, totalCount }}
        />
      )}
      {loading && <LinearProgress />}
    </ContentLayout>
  )
}
