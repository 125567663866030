import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"

import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function StateSwitcher({ isRunning, setIsRunning }) {
    const { t } = useTranslation()
    if (isRunning) {
      return (
        <Button
        variant="outlined"
          startIcon={<PauseIcon />}
          onClick={() => {
            setIsRunning(false)
          }}>
          {t("Autorefresh")}
        </Button>
      )
    } else {
      return (
        <Button
        variant="contained"
          startIcon={<PlayArrowIcon />}
          onClick={() => {
            setIsRunning(true)
          }}>
          {t("Autorefresh")}
        </Button>
      )
    }
  }