import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  alerts: [],
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    addAlerts: (state, action) => {
      const items = action.payload
      state.alerts = [...items, ...state.alerts]
      // state.alerts.push(...items)
    },
    removeAlert: (state, action) => {
      const id = action.payload
      state.alerts.splice(id, 1)
    },
    clearAllAlerts: (state, action) => {
      state.alerts.length = 0
    },
  },
})

export const { addAlerts, removeAlert, clearAllAlerts } = alertSlice.actions
export const alertReducer = alertSlice.reducer
