import { Button, Grid2 as Grid, TextField, Typography } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"

export default function EditUserData({ data, loading, handleUpdateUser }) {
  const { t } = useTranslation()
  const { email, name, surname, phone, company, position, description, isActive, isAdmin, createdAt, updatedAt } = data
  const isValid = [name.isValid, surname.isValid, email.isValid, company.isValid].every(Boolean)

  const isChanged = [
    name.isChanged,
    surname.isChanged,
    email.isChanged,
    company.isChanged,
    phone.isChanged,
    position.isChanged,
    description.isChanged,
    isActive.isChanged,
    isAdmin.isChanged,
  ].some(Boolean)

  const isDisabled = loading || !isValid || !isChanged

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" py={2}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="email"
          disabled={loading}
          fullWidth
          value={email.value}
          onChange={email.onChange}
          onBlur={email.onBlur}
          label={email.label}
          helperText={email.helperText}
          error={email.error}
          variant="outlined"
          required
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="phone"
          disabled={loading}
          fullWidth
          value={phone.value}
          onChange={phone.onChange}
          onBlur={phone.onBlur}
          label={phone.label}
          helperText={phone.helperText}
          error={phone.error}
          variant="outlined"
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="name"
          disabled={loading}
          fullWidth
          value={name.value}
          onChange={name.onChange}
          onBlur={name.onBlur}
          label={name.label}
          helperText={name.helperText}
          error={name.error}
          variant="outlined"
          required
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="surname"
          disabled={loading}
          fullWidth
          value={surname.value}
          onChange={surname.onChange}
          onBlur={surname.onBlur}
          label={surname.label}
          helperText={surname.helperText}
          error={surname.error}
          variant="outlined"
          required
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="company"
          disabled={loading}
          fullWidth
          value={company.value}
          onChange={company.onChange}
          onBlur={company.onBlur}
          label={company.label}
          helperText={company.helperText}
          error={company.error}
          variant="outlined"
          required
          multiline
          minRows={2}
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="position"
          value={position.value}
          onChange={position.onChange}
          onBlur={position.onBlur}
          label={position.label}
          helperText={position.helperText}
          error={position.error}
          fullWidth
          disabled={loading}
          variant="outlined"
          multiline
          minRows={2}
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="description"
          fullWidth
          value={description.value}
          onChange={description.onChange}
          onBlur={description.onBlur}
          label={description.label}
          helperText={description.helperText}
          error={description.error}
          disabled={loading}
          variant="outlined"
          multiline
          minRows={3}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography color="text.secondary">
          {t("Created at")}: {moment(createdAt).format("lll") || ""}
        </Typography>
        <Typography color="text.secondary">
          {t("Updated at")}: {moment(updatedAt).format("lll") || ""}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button disabled={isDisabled} type="submit" onClick={handleUpdateUser} variant="contained">
          {t("Apply")}
        </Button>
      </Grid>
    </Grid>
  )
}
