import { Box, Button, Grid2 as Grid, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import DeleteIcon from "@mui/icons-material/Delete"

import ChangePassword from "../../components/ChangePassword"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import ChangePermissions from "./components/ChangePermissions"
import EditUserData from "./EditUserData"
import useOrganizationUser from "./hooks/useOrganizationUser"

export default function UserCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { userId, organizationId } = useParams()
  const [selectedTab, setSelectedTab] = useState("editUserData")
  const props = useOrganizationUser()
  const { loading, handleDeleteOrganizationUser } = props
  
  return (
    <>
      <ItemCardLayout
        title={t("Edit User")}
        actions={
          userId && (
            <Grid>
              <Button
                disabled={loading}
                color="error"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    showConfirmDialog({
                      title: t("You can't undo this operation"),
                      message: t("Are you sure to exclude this user?"),
                      onConfirm: () => {
                        handleDeleteOrganizationUser(organizationId, userId)
                      },
                    })
                  )
                }}
                startIcon={<DeleteIcon />}>
                {t("Delete")}
              </Button>
            </Grid>
          )
        }
        state={loading}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue)
            }}>
            <Tab label={t("Info")} value="editUserData" />
            <Tab label={t("Permissions")} value="permissions" />
            <Tab label={t("Change password")} value="changePassword" />
          </Tabs>
        </Box>
        <Box py={2}>
          {selectedTab === "editUserData" && <EditUserData {...props} />}
          {selectedTab === "permissions" && <ChangePermissions {...props} />}
          {selectedTab === "changePassword" && <ChangePassword {...props} />}
        </Box>
      </ItemCardLayout>
    </>
  )
}
