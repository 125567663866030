import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  relayTimeouts: [],
  locationsTimeouts: [],
}

export const relayTimeout = createSlice({
  name: "relayTimeout",
  initialState,
  reducers: {
    addRelay: (state, action) => {
      const id = action.payload
      state.relayTimeouts = [...state.relayTimeouts, id]
    },
    removeRelay: (state, action) => {
      const id = action.payload
      state.relayTimeouts = state.relayTimeouts.filter((item) => item !== id)
    },
    addLocation: (state, action) => {
      const id = action.payload
      state.locationsTimeouts = [...state.locationsTimeouts, id]
    },
    removeLocation: (state, action) => {
      const id = action.payload
      state.locationsTimeouts = state.locationsTimeouts.filter((item) => item !== id)
    },
  },
})

export const { addRelay, removeRelay, addLocation, removeLocation } = relayTimeout.actions
export const timeoutReducer = relayTimeout.reducer
