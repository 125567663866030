import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation, emailValidation, nameValidation, passwordValidation } from "../../../helpers/validations"
import useCheckBoxInput from "../../../hooks/useCheckboxInput"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { deleteOrganizationUser, getOrganizationUser, organizationAddUser, updateOrganizationUser } from "../../../services/organization"
import { createUser, getUserIdByEmail, updatePasswordByAdmin, updateUser } from "../../../services/user"
import { showNotification } from "../../../slices/notificationSlice"

export default function useOrganizationUser() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const responseHandler = useResponseHandler()
  const [selectedTab, setSelectedTab] = useState("Exist")
  const { userId, organizationId } = useParams()
  const [loading, setLoading] = useState(false)
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")
  const name = useInput({ label: t("Name") /*helperText: t("Name")*/ }, nameValidation)
  const surname = useInput({ label: t("Surname") /*helperText: t("Surname")*/ }, nameValidation)
  const phone = useInput({ label: t("Phone") /*helperText: t("Phone")*/ }, {})
  const email = useInput({ label: t("E-mail") /*helperText: t("Email")*/ }, emailValidation)
  const company = useInput({ label: t("Company") /*helperText: t("Company")*/ }, baseValidation)
  const description = useInput({ label: t("Notes") })
  const position = useInput({ label: t("Position / Subdivision") })
  const sessionTTL = useInput({ init: 60, label: t("User session TTL (in minutes)") })
  const isActive = useCheckBoxInput({ init: true, label: t("Is active") })
  const isAdmin = useCheckBoxInput({ init: false, label: t("Is admin") })

  const password = useInput(
    {
      label: t("Password"),
      helperText: t("Must have at least X uppercase characters, X lowercase characters, X numbers, X special, and X chars min length", {
        minUpperChar: 2,
        minLowerChar: 2,
        minDigits: 2,
        minSpecChar: 1,
        minLength: 13,
        specChars: "-_+=#@&^()*/><{}?!|`~,.",
      }),
    },
    passwordValidation
  )

  useEffect(() => {
    getData(organizationId, userId)
  }, [])

  const data = {
    userId,
    name,
    surname,
    email,
    phone,
    company,
    position,
    description,
    sessionTTL,
    createdAt,
    updatedAt,
    isActive,
    isAdmin,
    password,
    organizationId,
  }

  const getData = (organizationId, userId) => {
    setLoading(true)
    getOrganizationUser(organizationId, userId)
      .then((response) => {
        const { organizationUser, createdAt, updatedAt } = response.body
        name.set(organizationUser.name)
        surname.set(organizationUser.surname)
        email.set(organizationUser.email)
        company.set(organizationUser.company)
        phone.set(organizationUser.phone)
        position.set(organizationUser.position)
        description.set(organizationUser.description)
        sessionTTL.set(organizationUser.sessionTTL / 60)
        isActive.set(organizationUser.isActive)
        isAdmin.set(organizationUser.role === "admin" ? true : false)
        setCreatedAt(createdAt)
        setUpdatedAt(updatedAt)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateOrganizationUser = () => {
    setLoading(true)
    createUser({
      password: password.value,
      user: {
        name: name.value,
        surname: surname.value,
        email: email.value,
        phone: phone.value,
        company: company.value,
        position: position.value,
        description: description.value,
        sessionTTL: sessionTTL.value * 60,
        isActive: isActive.value,
      },
      organizationId,
    })
      .then((response) => {
        const { id } = response.body
        organizationAddUser(organizationId, { organizationUser: { organizationId, userId: id, role: isAdmin.value ? "admin" : "user", } })
          .then(() => {
            dispatch(showNotification({ message: t("User is created and added to organization"), type: "success" }))
            navigate("..")
          })
          .catch(responseHandler)
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(responseHandler)
  }
  
  const handleChangePassword = () => {
    updatePasswordByAdmin(userId, password.value, organizationId)
      .then(() => {
        dispatch(showNotification({ message: t("Password is updated!"), type: "success" }))
      })
      .catch(responseHandler)
  }

  const handleDeleteOrganizationUser = (organizationId, userId) => {
    deleteOrganizationUser(organizationId, userId)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleUpdateOrganizationUser = () => {
    updateOrganizationUser(organizationId, {
      organizationId,
      role: isAdmin.value ? "admin" : "user",
      userId,
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  function handleUpdateUser() {
    updateUser(
      {
        id: userId,
        organizationId,
        email: email.value,
        name: name.value,
        surname: surname.value,
        phone: phone.value,
        company: company.value,
        position: position.value,
        description: description.value,
        sessionTTL: sessionTTL.value * 60,
        isActive: isActive.value,
        role: isAdmin.value ? "admin" : "user",
      },
      organizationId
    )
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  function addUserToOrganization() {
    setLoading(true)
    getUserIdByEmail(email.value)
      .then((response) => {
        const { id } = response.body
        organizationAddUser(organizationId, {
          organizationUser: {
            organizationId,
            userId: id,
            role: isAdmin.value ? "admin" : "user",
          },
        })
          .then((response) => {
            dispatch(showNotification({ message: t("The user has been added to the organization"), type: "success" }))
            navigate("..")
          })
          .catch(responseHandler)
      })
      .catch((response) => {
        responseHandler(response)
        dispatch(showNotification({ message: t("User is not exist! Please create a new user"), type: "warning" }))
        setSelectedTab("New")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    data,
    loading,
    setLoading,
    handleUpdateOrganizationUser,
    handleDeleteOrganizationUser,
    handleCreateOrganizationUser,
    handleUpdateUser,
    addUserToOrganization,
    handleChangePassword,
    selectedTab,
    setSelectedTab,
  }
}
