import { ThemeProvider, createTheme } from "@mui/material"
import { useMemo } from "react"
import { useSelector } from "react-redux"

export default function ThemeWrapper({ children }) {
    const mode = useSelector((state) => state.toggleColorModeReducer)
  
    const defaultTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode,
          },
        }),
      [mode]
    )

  // const customTheme = (primary, secondary) => createTheme({palette: {
  //   mode,
  //   primary: {
  //     light: primary[300],
  //     main: primary[500],
  //     dark: primary[700],
  //   },
  //   secondary: {
  //     light: secondary[300],
  //     main: secondary[500],
  //     dark: secondary[700],
  //   },
  // }     })

  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}
