import L from "leaflet"
import { Marker, Popup } from "react-leaflet"
import { Link } from "react-router-dom"
import "leaflet.awesome-markers"

export default function DeviceMarker({ position, isOnline, isRelay, state, id, name, withPopup }) {
  // const onlineMarker = L.AwesomeMarkers.icon({ prefix: "bi", icon: "bi-circle", markerColor: "blue" })
  // const offlineMarker = L.AwesomeMarkers.icon({ prefix: "bi", icon: "bi-circle", markerColor: "gray" })
  // const relayOpened = L.AwesomeMarkers.icon({ prefix: "bi", icon: "bi-circle", markerColor: "green" })
  // const relayClosed = L.AwesomeMarkers.icon({ prefix: "bi", icon: "bi-circle", markerColor: "red" })

  function icon(isOnline, isRelay, state) {
    let icon = "bi-circle"
    const markerColor = isOnline ? "blue" : "lightgray"
    if (isRelay) {
      icon = state ? "bi-circle-fill" : "bi-circle"
    }
    return L.AwesomeMarkers.icon({ prefix: "bi", icon, markerColor })
  }

  return (
    <Marker position={position} icon={icon(isOnline, isRelay, state)}>
      {withPopup && (
        <Popup>
          <Link to={id}>{name}</Link>
        </Popup>
      )}
    </Marker>
  )
}
