import "moment/locale/ru"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { getDevice } from "../../../services/device"
import { getLocation } from "../../../services/location"

export default function useFetchDeviceInfo() {
  const { deviceId, organizationId } = useParams()
  const responseHandler = useResponseHandler()
  const [loading, setLoading] = useState(true)
  const [isRunning, setIsRunning] = useState(true)
  const [deviceInfo, setDeviceInfo] = useState({})
  const autoUpdateInterval = 10

  useEffect(() => {
    if (deviceId && organizationId) {
      getData(deviceId, organizationId)
      if (isRunning) {
        const interval = setInterval(() => {
          if (document.visibilityState === "visible") {
            getData(deviceId, organizationId)
          }
        }, autoUpdateInterval * 1e3)
        return () => clearInterval(interval)
      }
    }
  }, [])

  const getData = (deviceId, organizationId) => {
    getDevice(deviceId, organizationId)
      .then((response) => {
        const { createdAt, updatedAt, lastSeenAt, device, typeName, isOnline, stateKind, state } = response.body
        getLocation(device.locationId, organizationId)
          .then((response) => {
            const { location } = response.body
            setDeviceInfo({
              name: device.name,
              externalId: device.externalId,
              description: device.description,
              scheduleGroupId: device.scheduleGroupId,
              latitude: device.latitude,
              longitude: device.longitude,
              createdAt,
              updatedAt,
              lastSeenAt,
              locationId: device.locationId,
              deviceTypeId: device.deviceTypeId,
              typeName,
              state: JSON.parse(state),
              stateKind,
              isOnline,
              location: location.name,
            })
          })
          .catch(responseHandler)
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(responseHandler)
  }

  return { loading, deviceInfo }
}
