import { Link } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { listLocations } from "../../../services/location"

export default function useLocations(initLimit) {
  const { organizationId } = useParams()
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState("")
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  // const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()
  const navigate = useNavigate()
  const { state } = useLocation()

  const toLocationInfoLink = (value, item) => (
    <Link onClick={() => navigate(`/organization/${organizationId}/locations/${item.id}`)} style={{ cursor: "pointer" }}>
      {value}
    </Link>
  )

  const toDeviceInfoLink = (value, item) => (
    <Link onClick={() => navigate(`/organization/${organizationId}/devices/`, { state: { ...state, selectedLocation: item } })} style={{ cursor: "pointer" }}>
      {value}
    </Link>
  )

  const toEventInfoLink = (value, item) => (
    <Link onClick={() => navigate(`/organization/${organizationId}/events/`)} style={{ cursor: "pointer" }}>
      {value}
    </Link>
  )

  const cells = [
    { label: "#", name: "id", width: 10, isUnorderable: true },
    { label: "Location Name", name: "name", component: toLocationInfoLink },
    { label: "Address", name: "address", isUnorderable: true },
    { label: "Devices qty", name: "deviceCount", component: toDeviceInfoLink },
    { label: "Events qty", name: "eventCount", component: toEventInfoLink, isUnorderable: true },
  ]

  useEffect(() => {
    getData(search, initLimit || rowsPerPages, offset, order, orderBy)
  }, [search, rowsPerPages, offset, order, orderBy])

  const getData = (search, limit, offset, order, orderBy) => {
    setLoading(true)
    // setData([])
    // setTotalCount()
    listLocations({ organizationId, search, limit, offset, order, orderBy })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    data,
    cells,
    loading,
    search,
    setSearch,
    // limit,
    // setLimit,
    offset,
    setOffset,
    page,
    setPage,
    totalCount,
    organizationId,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  }
}
