import { useTheme } from "@emotion/react"
import { Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { JSONTree } from "react-json-tree"

import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useState } from "react"

export default function AdvancedState({ state }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const muiTheme = useTheme()

  const theme = {
    scheme: "mui",
    base00: muiTheme.palette.background.paper,
  }

  return (
    <>
      <TableRow>
        <TableCell colSpan={2}>
          <Typography variant="h7">
            {t("Details")}
            <IconButton
              size="small"
              onClick={() => {
                setOpen((prev) => !prev)
              }}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <JSONTree shouldExpandNodeInitially={() => true} keyPath={["state"]} data={state} theme={theme} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

