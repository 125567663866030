import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"
import "./i18n"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import "./typeface-pantone.css"

import MomentLocale from "./components/MomentLocale"
import SnackbarNotification from "./components/SnackbarNotification"
import ThemeWrapper from "./components/ThemeWrapper"
import router from "./router"
import store from "./store"

import "leaflet/dist/leaflet.css"
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css"
// import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <ThemeWrapper>
        {/* <Loading/> */}
        <MomentLocale/>
        <RouterProvider router={router} />
        <SnackbarNotification />
      </ThemeWrapper>
    </Provider>
  //   </React.StrictMode> 
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
