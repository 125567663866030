import { Button, Grid2 as Grid, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import DeleteIcon from "@mui/icons-material/Delete"

import MarkerPickerMap from "../../components/MarkerPickerMap"
import ScheduleSelector from "../../components/ScheduleSelector"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import useLocationCard from "./hooks/useLocationCard"

export default function LocationCard() {
  const { locationId } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { name, description, scheduleGroupId, latitude, longitude, address, loading, handleCreateLocation, handleUpdateLocation, handleDeleteLocation } =
    useLocationCard()

  const isValid = [name.isValid, address.isValid, scheduleGroupId.isValid, longitude.isValid, latitude.isValid].every(Boolean)
  const isChanged = [name.isChanged, description.isChanged, scheduleGroupId.isChanged, address.isChanged, latitude.isChanged, longitude.isChanged].some(Boolean)
  const isDisabled = loading || !isValid || !isChanged

  return (
    <ItemCardLayout
      state={loading}
      actions={
        locationId && (
          <Grid>
            <Button
              disabled={loading}
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(
                  showConfirmDialog({
                    title: t("You can't undo this operation"),
                    message: t("Are you sure to delete this location?"),
                    onConfirm: () => {
                      handleDeleteLocation(locationId)
                    },
                  })
                )
              }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </Grid>
        )
      }
    >
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" py={1}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="name"
            fullWidth
            disabled={loading}
            variant="outlined"
            label={name.label}
            onBlur={name.onBlur}
            value={name.value}
            error={name.error}
            onChange={name.onChange}
            helperText={name.helperText}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <ScheduleSelector {...{ scheduleGroupId }} />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TextField
            id="description"
            fullWidth
            disabled={loading}
            label={description.label}
            variant="outlined"
            multiline
            minRows={2}
            value={description.value}
            onChange={description.onChange}
            onBlur={description.onBlur}
            error={description.error}
            helperText={description.helperText}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TextField
            id="address"
            disabled={loading}
            variant="outlined"
            label={address.label}
            onBlur={address.onBlur}
            value={address.value}
            error={address.error}
            onChange={address.onChange}
            helperText={address.helperText}
            fullWidth
            required
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <MarkerPickerMap {...{ latitude, longitude, loading }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="latitude"
            fullWidth
            disabled={loading}
            variant="outlined"
            label={latitude.label}
            onBlur={latitude.onBlur}
            value={latitude.value}
            error={latitude.error}
            onChange={latitude.onChange}
            helperText={latitude.helperText}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="longitude"
            fullWidth
            disabled={loading}
            variant="outlined"
            label={longitude.label}
            value={longitude.value}
            onBlur={longitude.onBlur}
            error={longitude.error}
            onChange={longitude.onChange}
            helperText={longitude.helperText}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          {locationId ? (
            <Button type="submit" disabled={isDisabled} onClick={handleUpdateLocation} variant="contained">
              {t("Apply")}
            </Button>
          ) : (
            <Button type="submit" disabled={isDisabled} onClick={handleCreateLocation} variant="contained">
              {t("Create")}
            </Button>
          )}
        </Grid>
      </Grid>
    </ItemCardLayout>
  )
}
