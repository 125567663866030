import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Grid2 as Grid, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import ChangePassword from "../../components/ChangePassword"
import useUserCard from "./hooks/useUserCard"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import OrganizationsSelector from "./components/OrganizationsSelector"
import EditUserData from "./EditUserData"

export default function UserCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const props = useUserCard()
  const { data, loading, handleDeleteUser } = props
  const { userId } = data
  const [selectedTab, setSelectedTab] = useState("editUserData")

  return (
    <ItemCardLayout
      state={loading}
      actions={
        userId && (
          <Grid>
            <Button
              disabled={loading}
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(
                  showConfirmDialog({
                    title: t("You can't undo this operation"),
                    message: t("Are you sure to delete this user?"),
                    onConfirm: () => {
                      handleDeleteUser(userId)
                    },
                  })
                )
              }}
              startIcon={<DeleteIcon />}>
              {t("Delete")}
            </Button>
          </Grid>
        )
      }>
      {userId && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(event, value) => {
              setSelectedTab(value)
            }}>
            <Tab label={t("Info")} value="editUserData" />
            <Tab label={t("Change password")} value="changePassword" />
            {/* <Tab label={t("Organizations")} value="organizations" /> */}
          </Tabs>
        </Box>
      )}
      {selectedTab === "editUserData" && <EditUserData {...props} />}
      {selectedTab === "changePassword" && <ChangePassword {...props} />}
      {/* {selectedTab === "organizations" && <OrganizationsSelector />} */}
    </ItemCardLayout>
  )
}
