import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Grid2 as Grid, TextField, Typography } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// import ConnectionTypeSelector from "../../components/ConnectionTypeSelector"
import useDeviceTypeCard from "./hooks/useDeviceTypeCard"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import IntegrationSelector from "../../components/IntegrationSelector"
import MessageTypeSelector from "../../components/MessageTypeSelector"

export default function DeviceTypeCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    name,
    // wanType,
    integrationId,
    // relayOffCmd,
    // relayOnCmd,
    repeatInterval,
    sendNumOfTimes,
    description,
    deviceTypeId,
    offlineAfter,
    messageTypeId,
    createdAt,
    updatedAt,
    handleCreateDeviceType,
    handleUpdateDeviceType,
    handleDeleteDeviceType,
    loading,
  } = useDeviceTypeCard()

  const isValid = [
    name.isValid,
    // wanType.isValid,
    integrationId.isValid,
    messageTypeId.isValid,
    // relayOffCmd.isValid,
    // relayOnCmd.isValid,
    repeatInterval.isValid,
    sendNumOfTimes.isValid,
    offlineAfter.isValid,
  ].every(Boolean)

  const isChanged = [
    name.isChanged,
    description.isChanged,
    offlineAfter.isChanged,
    // wanType.isChanged
    integrationId.isChanged,
    messageTypeId.isChanged,
    // relayOffCmd.isChanged,
    // relayOnCmd.isChanged,
    repeatInterval.isChanged,
    sendNumOfTimes.isChanged,
  ].some(Boolean)

  const isDisabled = loading || !isValid || !isChanged

  return (
    <>
      <ItemCardLayout
        state={loading}
        actions={
          deviceTypeId && (
            <Grid>
              <Button
                disabled={loading}
                color="error"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    showConfirmDialog({
                      title: t("You can't undo this operation"),
                      message: t("Are you sure to delete this device type?"),
                      onConfirm: () => {
                        handleDeleteDeviceType(deviceTypeId)
                      },
                    })
                  )
                }}
                startIcon={<DeleteIcon />}
              >
                {t("Delete")}
              </Button>
            </Grid>
          )
        }
      >
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" py={1}>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="name"
              disabled={loading}
              fullWidth
              onBlur={name.onBlur}
              value={name.value}
              error={name.error}
              onChange={name.onChange}
              helperText={name.helperText}
              label={name.label}
              variant="outlined"
              required
            />
          </Grid>

          <Grid size={{ xs: 6, lg: 3 }}>
            <IntegrationSelector {...{ integrationId }} />
            {/* <ConnectionTypeSelector {...{ wanType }} /> */}
          </Grid>
          <Grid size={{ xs: 6, lg: 3 }}>
            <MessageTypeSelector {...{ messageTypeId, loading }} />
          </Grid>
          {/* <Grid size={{ xs: 12, lg: 6 }}>
            <TextField 
            disabled={loading} 
            fullWidth 
            value={relayOffCmd.value} 
            onChange={relayOffCmd.onChange} 
            label={relayOffCmd.label} 
            variant="outlined" />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField 
            disabled={loading} 
            fullWidth 
            value={relayOnCmd.value} 
            onChange={relayOnCmd.onChange} 
            label={relayOnCmd.label} 
            variant="outlined" />
          </Grid> */}
          <Grid size={{ xs: 6, lg: 3 }}>
            <TextField
              id="repeatInterval"
              disabled={loading}
              fullWidth
              value={repeatInterval.value}
              onChange={repeatInterval.onChange}
              label={repeatInterval.label}
              error={repeatInterval.error}
              helperText={repeatInterval.helperText}
              variant="outlined"
            />
          </Grid>
          <Grid size={{ xs: 6, lg: 3 }}>
            <TextField
              id="sendNumOfTimes"
              disabled={loading}
              fullWidth
              value={sendNumOfTimes.value}
              onChange={sendNumOfTimes.onChange}
              label={sendNumOfTimes.label}
              error={sendNumOfTimes.error}
              helperText={sendNumOfTimes.helperText}
              variant="outlined"
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 3 }}>
            <TextField
              id="offlineAfter"
              disabled={loading}
              fullWidth
              value={offlineAfter.value}
              onChange={offlineAfter.onChange}
              label={offlineAfter.label}
              error={offlineAfter.error}
              helperText={offlineAfter.helperText}
              variant="outlined"
            />
          </Grid>

          <Grid size={{ xs: 12 }}>
            <TextField
              id="description"
              disabled={loading}
              fullWidth
              multiline
              minRows={4}
              value={description.value}
              onChange={description.onChange}
              label={description.label}
              variant="outlined"
            />
          </Grid>
          {(createdAt || updatedAt) && (
            <Grid size={{ xs: 12 }}>
              <Typography color="text.secondary">
                {t("Created at")}: {moment(createdAt).format("lll")}
              </Typography>
              <Typography color="text.secondary">
                {t("Updated at")}: {moment(updatedAt).format("lll")}
              </Typography>
            </Grid>
          )}
          <Grid size={{ xs: 12 }}>
            {deviceTypeId ? (
              <Button disabled={isDisabled} onClick={handleUpdateDeviceType} variant="contained">
                {t("Apply")}
              </Button>
            ) : (
              <Button disabled={isDisabled} onClick={handleCreateDeviceType} variant="contained">
                {t("Create")}
              </Button>
            )}
          </Grid>
        </Grid>
      </ItemCardLayout>
    </>
  )
}
