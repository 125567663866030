import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createAPIKey, deleteAPIKey, getAPIKey, updateAPIKey } from "../../../services/duty"
import { showNotification } from "../../../slices/notificationSlice"
import useCheckBoxInput from "../../../hooks/useCheckboxInput"

export default function useAPIKeyCard() {
  const responseHandler = useResponseHandler()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { APIKeyId, organizationId } = useParams()
  const dispatch = useDispatch()

  const name = useInput({ label: t("API key name") }, baseValidation)
  const description = useInput({ label: t("API key description") })
  const isActive = useCheckBoxInput({ init: true, label: t("Is active") })
  const [isAdmin, setIsAdmin] = useState(!organizationId)
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")
  const [APIkey, setAPIKey] = useState("")
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (APIKeyId) {
      getData(APIKeyId, organizationId)
    }
  }, [])

  const getData = (APIKeyId, organizationId) => {
    setLoading(true)
    getAPIKey(APIKeyId, organizationId)
      .then((response) => {
        const { apiKey } = response.body
        name.set(apiKey.name)
        description.set(apiKey.description)
        isActive.set(apiKey.isActive)
        setIsAdmin(apiKey.isAdmin)
        setCreatedAt(apiKey.createdAt)
        setUpdatedAt(apiKey.updatedAt)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateAPIKey = () => {
    setLoading(true)
    createAPIKey({
      apiKey: {
        name: name.value,
        description: description.value,
        isActive: isActive.value,
        isAdmin,
        organizationId,
      },
      organizationId,
    })
      .then((response) => {
        const { jwtToken } = response.body
        setAPIKey(jwtToken)
        setOpen(true)
        dispatch(showNotification({ message: t("Created successfully"), type: "success" }))
      })
      .catch(responseHandler)
      .finally(() => {
        name.set("")
        description.set("")
        setLoading(false)
      })
  }

  const handleUpdateAPIKey = () => {
    updateAPIKey(
      {
        id: APIKeyId,
        name: name.value,
        description: description.value,
        isActive: isActive.value,
        isAdmin,
      },
      organizationId
    )
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteAPIKey = (id, organizationId) => {
    deleteAPIKey(id, organizationId)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return {
    open,
    setOpen,
    handleCreateAPIKey,
    handleUpdateAPIKey,
    handleDeleteAPIKey,
    APIkey,
    name,
    description,
    isActive,
    APIKeyId,
    loading,
    organizationId,
    createdAt,
    updatedAt,
  }
}
