import { useEffect, useState } from "react"
import useValidation from "./useValidation"

export default function useInput({ init = "", label, helperText } = {}, validations) {
  const { validate, error } = useValidation(validations)
  const [initValue, setInitValue] = useState(init)
  const [value, setValue] = useState(initValue)

  const set = (value) => {
    setInitValue(value)
    setValue(value)
  }

  useEffect(() => {
    if (value) {
      validate(value)
    }
  }, [value])

  const onChange = (e) => {
    if (!isNaN(e.target.value) && validations?.hasOwnProperty('isNumber')) {
      setValue(Number(e.target.value))
    } else {
      setValue(e.target.value)
    }
  }

  const onBlur = (e) => {
    validate(e.target.value)
  }

  const reset = () => {
    setValue(initValue)
  }

  return {
    initValue,
    set,
    value,
    setValue,
    onChange,
    label,
    onBlur,
    isUndo: initValue !== value && initValue != "",
    isChanged: initValue !== value,
    reset,
    error: error ? true : false,
    isValid: !error && value !== "",
    helperText: error || helperText,
  }
}
