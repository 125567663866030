import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import EditIcon from "@mui/icons-material/Edit"

export default function IntegrationSettingsEditor({ settings }) {
  const { t } = useTranslation()
  const settingsList = settings.value ? JSON.parse(settings.value) : {}
  const [newKey, setNewKey] = useState("")
  const [newValue, setNewValue] = useState("")

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>{t("Settings")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(settingsList).map((key) => (
            <TableRow key={settingsList[key]}>
              <TableCell component="th" scope="row">
                {key}
              </TableCell>
              <TableCell>{settingsList[key]}</TableCell>
              <TableCell align="left">
                <IconButton
                  size="small"
                  onClick={() => {
                    delete settingsList[key]
                    settings.setValue(JSON.stringify(settingsList))
                  }}>
                  <RemoveIcon />
                </IconButton>
                {/* <IconButton
                  size="small"
                  onClick={() => {
                    delete settingsList[key]
                    settings.setValue(JSON.stringify(settingsList))
                  }}>
                  <EditIcon />
                </IconButton> */}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <TextField id="key" label={t("Key")} size="small" value={newKey} onChange={(e) => setNewKey(e.target.value)} fullWidth />
            </TableCell>
            <TableCell>
              <TextField id="value" label={t("Value")} size="small" value={newValue} onChange={(e) => setNewValue(e.target.value)} fullWidth />
            </TableCell>
            <TableCell align="left">
              <IconButton
                size="small"
                disabled={!newKey || !newValue}
                onClick={() => {
                //   settingsList[newKey] = newValue
                  settings.setValue(JSON.stringify({...settingsList, [newKey]: newValue } ))
                  setNewKey("")
                  setNewValue("")
                }}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
