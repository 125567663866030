import AutorenewIcon from "@mui/icons-material/Autorenew"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { IconButton, InputAdornment, TextField } from "@mui/material/"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import passwordGenerator from "../helpers/passwordGenerator"
import { showNotification } from "../slices/notificationSlice"

export default function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false)
  const { value, setValue, generator, error, copy, ...restProps } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <TextField
      {...restProps}
      value={value}
      helperText={props.isValid ? t("Is valid") : props.helperText}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <>
            {copy && value && !error && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (navigator.clipboard) {
                      navigator.clipboard.writeText(value).then(dispatch(showNotification({ message: t("Сopied to clipboard"), type: "success" })))
                    } else {
                      dispatch(showNotification({ message: t("Not copied: The connection is not secure"), type: "error" }))
                    }
                  }}
                  edge="end"
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            )}
            {value && (
              <InputAdornment position="end">
                <IconButton
                  onMouseDown={() => {
                    setShowPassword(true)
                  }}
                  onMouseUp={() => {
                    setShowPassword(false)
                  }}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
            {generator && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    let pass = passwordGenerator()
                    if (navigator.clipboard) {
                      navigator.clipboard
                        .writeText(pass)
                        .then(dispatch(showNotification({ message: t("The new password has been generated and copied to the clipboard!"), type: "success" })))
                    }
                    setValue(pass)
                  }}
                  edge="end"
                >
                  <AutorenewIcon />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  )
}
