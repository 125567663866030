import TablePagination from "@mui/material/TablePagination"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import TablePaginationActions from "../components/TablePaginationActions"
import { changeRowsPerPages } from "../slices/rowsPerPageSlice"

export default function TableFooterComponent({ setOffset, page, setPage, totalCount }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer  ) 

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPages)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10)
    dispatch(changeRowsPerPages(rows))
    // setLimit(rows)
    setPage(0)
  }

  return (
    <TablePagination
      labelRowsPerPage={t("Rows per page:")}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}–${to} ${t("of")} ${count !== -1 ? count : `${t("more than")} ${to}`}`
      }}
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPages}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  )
}
