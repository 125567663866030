import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function PermissionsInOrganization({ loading, isAdmin }) {
  const { t } = useTranslation()
  return (
    <>
      <FormGroup>
      <Typography variant="h6">{t("Permissions in organization")}</Typography>
        <FormControlLabel
          control={<Checkbox disabled={loading} checked={isAdmin.value} />}
          label={isAdmin.label}
          onChange={isAdmin.onChange}
        />
      </FormGroup>
    </>
  )
}
