import KeyIcon from "@mui/icons-material/Key"
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid2 as Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import PasswordField from "../../../components/PasswordField"
import { passwordValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { updatePassword } from "../../../services/user"
import { showConfirmDialog } from "../../../slices/confirmDialogSlice"
import { showNotification } from "../../../slices/notificationSlice"

export default function ChangePassword({ userId, loading }) {
  const responseHandler = useResponseHandler()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const passwordReq = {
    minUpperChar: 2,
    minLowerChar: 2,
    minDigits: 2,
    minSpecChar: 1,
    minLength: 13,
  }

  const password = useInput(
    {
      label: t("Password"),
      helperText: t("Must have at least X uppercase characters, X lowercase characters, X numbers, X special, and X chars min length", {
        minUpperChar: 2,
        minLowerChar: 2,
        minDigits: 2,
        minSpecChar: 1,
        minLength: 13,
        specChars: "-_+=#@&^()*/><{}?!|`~,.",
      }),
    },
    passwordValidation
  )

  // const confirmPassword = useInput({ label: t("Confirm password") }, { confirmPassword: password.value })

  const handleChangePassword = () => {
    // if (password === confirmPassword) {
    {
      updatePassword(userId, password.value)
        .then(() => {
          dispatch(showNotification({ message: t("Password is updated!"), type: "success" }))
        })
        .catch(responseHandler)
    }
  }

  return (
    // <Accordion>
    //     <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t("Change password")}</AccordionSummary>
    //     <AccordionDetails>
    <>
      <Grid container item spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid size={{ xs: 12, lg: 6 }}>
          <PasswordField id="password" generator copy fullWidth disabled={loading} variant="outlined" type="password" {...password} required />
        </Grid>
        {/* <Grid size={{ xs: 12, lg: 6 }}>
            <PasswordField fullWidth disabled={loading} variant="outlined" type="password" {...confirmPassword} required />
          </Grid> */}
      </Grid>
      <Grid container item spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid size={{ xs: 12, lg: 6 }}>
          <Button
            disabled={loading || !password.isValid}
            onClick={() => {
              dispatch(
                showConfirmDialog({
                  title: t("You can't undo this operation"),
                  message: t("Are you sure to change password?"),
                  onConfirm: () => {
                    handleChangePassword()
                  },
                })
              )
            }}
            variant="contained"
            startIcon={<KeyIcon />}
          >
            {t("Change password")}
          </Button>
        </Grid>
      </Grid>
    </>
    //   </AccordionDetails>
    // </Accordion>
  )
}
