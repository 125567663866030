import { List } from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import CableIcon from '@mui/icons-material/Cable';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ConstructionIcon from "@mui/icons-material/Construction";
import DataUsageIcon from '@mui/icons-material/DataUsage';
import KeyIcon from "@mui/icons-material/Key";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";

import Private from "../Private";
import MenuItem from "./MenuItem";

export default function SideMainMenu() {

  const menuItems = [
    { text: "Organizations", icon: <BusinessIcon />, path: "/organizations", name: "organizations" },
    { text: "Dashboard", icon: <DataUsageIcon />, path: "/dashboard", name: "dashboard", roleRequired: "globalAdmin" },
    { text: "Integrations", icon: <CableIcon />, path: "/integrations", name: "integrations", roleRequired: "globalAdmin" },
    { text: "Device Types", icon: <SettingsRemoteIcon />, path: "/device-types", name: "device-types", roleRequired: "globalAdmin" },
    { text: "All Users", icon: <PeopleIcon />, path: "/users", name: "users", roleRequired: "globalAdmin" },
    { text: "Schedules", icon: <CalendarMonthIcon />, path: "/schedules", name: "schedules", roleRequired: "globalAdmin" },
    { text: "API Keys", icon: <KeyIcon />, path: "/api-keys", name: "api-keys", roleRequired: "globalAdmin" },
    { text: "Logs", icon: <MenuBookIcon />, path: "/logs", name: "logs", roleRequired: "globalAdmin" },
    { text: "Diagnostics", icon: <ConstructionIcon />, path: "/diagnostics", name: "diagnostics", roleRequired: "globalAdmin" },
  ]

  return (
    <List>
      {menuItems.map((item, index) => (
        <Private key={index} roleRequired={item.roleRequired}>
          <MenuItem {...item} />
        </Private>
      ))}
    </List>
  )
}
