import { useDispatch, useSelector } from "react-redux"
import { Alert, AlertTitle, Snackbar } from "@mui/material/"
import { hideNotification } from "../slices/notificationSlice"
import Slide from "@mui/material/Slide"

export default function SnackbarNotification() {
  const dispatcher = useDispatch()
  const { open, title, message, type, withSound } = useSelector((state) => state.notificationReducer)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    dispatcher(hideNotification())
  }

  if (withSound && open) {
    new Audio("/click.mp3").play()
  }

  return (
    <Snackbar TransitionComponent={Slide} open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert variant="filled" onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  )
}
