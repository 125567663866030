import { createSlice } from "@reduxjs/toolkit"

const getTheme = () => {
  const theme = localStorage?.getItem("themeMode")
  if (["light", "dark"].includes(theme)) return theme
  
  const defaultTheme = 'light'
    localStorage.setItem("themeMode", defaultTheme)
    return defaultTheme
  }

const initialState = getTheme()

export const toggleColorModeSlice = createSlice({
  name: "toggleColorMode",
  initialState,
  reducers: {
    toggleColorMode: (state, action) => {
      localStorage.setItem("themeMode", action.payload)
      return action.payload
    },
  },
})

export const { toggleColorMode } = toggleColorModeSlice.actions
export const toggleColorModeReducer = toggleColorModeSlice.reducer
