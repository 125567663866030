import LoginIcon from "@mui/icons-material/Login"
import LoadingButton from "@mui/lab/LoadingButton"
import { Box, Card, TextField, Typography } from "@mui/material/"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import Header from "../../components/Header"
import PasswordField from "../../components/PasswordField"
import useLogin from "./hooks/useLogin"

export default function Login() {
  const { t } = useTranslation()
  const [textError, setTextError] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { loading, handleLoginClick } = useLogin()
  const backgroundVideo = "/background.mp4"

  const handleKeyDown = (event) => {
    event.key === "Enter" && handleLoginClick({ email, password })
  }
  return (
    <>
      <div className="videoWrapper">
        <video type="video/mp4" src={backgroundVideo} autoPlay muted></video>
      </div>
      <Header time logo themeSwitcher languageSelector />
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Card sx={{ width: 400, padding: "20px" }}>
          <Typography align="center" color="primary" variant="h6" component="div">
            {t("Authorization")}
          </Typography>
          <TextField
            id="email"
            autoComplete="on"
            onKeyDown={handleKeyDown}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            error={Boolean(textError)}
            margin="normal"
            fullWidth
            required
            label={t("E-mail")}
            variant="outlined"
            value={email || ""}
          />
          <PasswordField
            id="password"
            onKeyDown={handleKeyDown}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
            margin="normal"
            fullWidth
            required
            label={t("Password")}
            variant="outlined"
            error={Boolean(textError)}
            value={password || ""}
          />
          <LoadingButton
            variant="contained"
            loadingPosition="end"
            loading={loading}
            onClick={() => {
              handleLoginClick({ email, password })
            }}
            endIcon={<LoginIcon />}
          >
            {t("Sign in")}
          </LoadingButton>
        </Card>
      </Box>
    </>
  )
}
