import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"

import useFetchDeviceTypes from "../../DeviceTypes/hooks/useFetchDeviceTypes"

export default function DeviceTypeSelector({ organizationId, deviceTypeId }) {
  const { loading, data } = useFetchDeviceTypes({ organizationId })

  return (
    <FormControl required fullWidth>
      <InputLabel>{deviceTypeId.label}</InputLabel>
      <Select
        id="deviceTypeId"
        disabled={loading}
        label={deviceTypeId.label}
        error={deviceTypeId.error}
        value={deviceTypeId.value}
        onChange={deviceTypeId.onChange}
        onBlur={deviceTypeId.onBlur}
      >
        {data.map((item, index) => (
          <MenuItem id={item.id} key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{deviceTypeId.helperText}</FormHelperText>
    </FormControl>
  )
}
