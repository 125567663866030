import { TableCell, TableRow, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

// const mock = {
//   data: {
//     cell: { sq: 23, snr: -20, rssi: -84, iccid: "8970199170383722111", celltype: 7 },
//     info: { dev: "ERL-01-sim", num: 2152, ver: "0.1" },
//     payload: { mode: 1, power: 184.7, state: 0, timeop: 34.4, current: 1.01, schedid: 512, voltage: 182.9, timesync: 1 },
//   },
//   time: 1724594220010,
//   msgId: "cd96281a-921c-479a-ae71-ddd0ac3a604e",
// }

export default function StateDefaultType({ state }) {
  const { t } = useTranslation()

  if (state?.Payload && state?.Info && state?.Cell) {
    const timeSync = (value) => {
      switch (value) {
        case 0: return t("Error")
        case 1: return t("Done (Service №1)")
        case 2: return t("Done (Service №2)")
      }
    }

    const info = [
      { name: "Load state", value: state.Payload.State ? "Turned on _she" : "Turned off _she" },
      { name: "Mode", value: state.Payload.Mode ? "Scheduled" : "Manual" },
      { name: "Time sync", value: state.Payload.Timesync, component: timeSync },
      { name: "Device version", value: `${state.Info.Dev} (${state.Info.Ver})` },
      { name: "Schedule ID", value: state.Payload.Schedid },
    ]

    const payload = [
      { name: "Voltage", value: state.Payload.Voltage, unit: "V" },
      { name: "Current", value: state.Payload.Current, unit: "A" },
      { name: "Power", value: state.Payload.Power, unit: "W" },
      { name: "Operating time", value: state.Payload.Timeop, unit: "h" },
    ]

    const cell = [
      { name: "Signal quality / Cell type", value: `${state.Cell.Sq} / ${state.Cell.Celltype}` },
      { name: "RSSI", value: state.Cell.Rssi, unit: "dBm" },
      { name: "SNR", value: state.Cell.Snr, unit: "dBm" },
      { name: "ICCID", value: state.Cell.Iccid },
    ]

    return (
      <>
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="h6">{t("State")}</Typography>
          </TableCell>
        </TableRow>
        {info.map((dataItem, dataIndex) => {
          const unit = dataItem.unit ? " " + t(`units.${dataItem.unit}`) : ""
          return (
            <TableRow key={dataIndex}>
              <TableCell>{`${t(dataItem.name)}:`}</TableCell>
              <TableCell>{dataItem.component ? dataItem.component(dataItem.value) : t(dataItem.value) + unit}</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="h6">{t("Measurement data")}</Typography>
          </TableCell>
        </TableRow>
        {payload.map((dataItem, dataIndex) => {
          const unit = dataItem.unit ? " " + t(`units.${dataItem.unit}`) : ""
          return (
            <TableRow key={dataIndex}>
              <TableCell>{`${t(dataItem.name)}:`}</TableCell>
              <TableCell>{dataItem.component ? dataItem.component(dataItem.value) : t(dataItem.value) + unit}</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="h6">{t("Connection info")}</Typography>
          </TableCell>
        </TableRow>
        {cell.map((dataItem, dataIndex) => {
          const unit = dataItem.unit ? " " + t(`units.${dataItem.unit}`) : ""
          return (
            <TableRow key={dataIndex}>
              <TableCell>{`${t(dataItem.name)}:`}</TableCell>
              <TableCell>{dataItem.component ? dataItem.component(dataItem.value) : t(dataItem.value) + unit}</TableCell>
            </TableRow>
          )
        })}
      </>
    )
  }
}
