import { LinearProgress, Link } from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import AlertSwitcher from "../../components/AlertSwitcher"
import DateTimeRangePicker from "../../components/DateTimeRangePicker"
import EventTypes from "../../components/EventTypes"
import FilterByDevice from "../../components/FilterByDevice"
import FilterByLocation from "../../components/FilterByLocation"
import NoData from "../../components/NoData"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import TableDataLayout from "../../layouts/TableDataLayout"
import StateSwitcher from "../Diagnostics/components/StateSwitcher"
import useFetchEvents from "./hooks/useFetchEvents"

export default function Events() {
  const navigate = useNavigate()
  const { organizationId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const userIdQuery = searchParams.get("userId")
  const deviceIdQuery = searchParams.get("deviceId")
  const locationIdQuery = searchParams.get("locationId")
  const isFailureOnlyQuery = searchParams.get("isFailureOnly")
  const startTimeQuery = searchParams.get("startTime") && moment(searchParams.get("startTime"))
  const endTimeQuery = searchParams.get("endTime") && moment(searchParams.get("endTime"))
  
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [locationId, setLocationId] = useState(locationIdQuery)
  const [isFailureOnly, setIsFailureOnly] = useState(isFailureOnlyQuery || false)
  const [search, setSearch] = useState("")
  const [deviceId, setDeviceId] = useState(deviceIdQuery)
  const [userId, setUserId] = useState(userIdQuery)
  const [startTime, setStartTime] = useState(startTimeQuery)
  const [endTime, setEndTime] = useState(endTimeQuery)
  const [page, setPage] = useState(0)
  const [isRunning, setIsRunning] = useState(true)

  const params = {
    deviceId,
    endTime,
    isFailureOnly,
    isRunning,
    locationId,
    offset,
    order,
    orderBy,
    organizationId,
    search,
    startTime,
  }

  const { loading, data, totalCount } = useFetchEvents(params)

  const toDeviceInfoLink = (value, item) => (
    <Link
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/organization/${organizationId}/devices/${item.deviceId}`)
      }}>
      {value}
    </Link>
  )

  const toLocationInfoLink = (value, item) => (
    <Link
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/organization/${organizationId}/locations/${item.locationId}`)
      }}>
      {value}
    </Link>
  )

  const cells = [
    { label: "#", name: "id", isUnorderable: true },
    { name: "type", component: (value, item) => <EventTypes unread={item.unread} type={value} />, width: 10, isUnorderable: true},
    { label: "Device", name: "deviceName", component: toDeviceInfoLink },
    { label: "IMEI \\ DevEUI", name: "deviceExternalId" },
    { label: "Location", name: "locationName", component: toLocationInfoLink },
    { label: "Local time", name: "createdAt", component: (value) => moment(value).format("lll") },
    { label: "UTC time", name: "createdAt", component: (value) => moment(moment.utc(value)).format("lll") },
    { label: "Category", name: "name" },
    { label: "Description", name: "description", isUnorderable: true },
    { label: "Initiator", name: "userEmail", isUnorderable: true },
  ]

  return (
    <ContentLayout
      title="Events"
      actions={[<SearchBar {...{ search, setSearch }} />, <StateSwitcher {...{ isRunning, setIsRunning }} />]}
      isFilterOpen={Boolean(deviceId || locationId || startTime || endTime || isFailureOnly)}
      advancedFilters={[
        <AlertSwitcher {...{ isFailureOnly, setIsFailureOnly }} />,
        // <FilterByUser url {...props} />,
        <FilterByDevice url {...{ organizationId, deviceId, setDeviceId }} />,
        <FilterByLocation url {...{ organizationId, locationId, setLocationId }} />,
        <DateTimeRangePicker url {...{ startTime, setStartTime, endTime, setEndTime }} />,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <TableDataLayout
          {...{ data, cells, orderBy, setOrderBy, order, setOrder, setOffset, page, setPage, totalCount }}
        />
      )}
      {loading && data.length === 0 && <LinearProgress />}
    </ContentLayout>
  )
}
