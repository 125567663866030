import { Button, Checkbox, FormControlLabel, FormGroup, Grid2 as Grid, TextField, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import PasswordField from "../../components/PasswordField"
import TextFieldWithDefault from "../../components/TextFieldWithDefault"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import useUserCard from "./hooks/useUserCard"

export default function Create() {
  const { t } = useTranslation()

  const { data, handleCreateUser, loading } = useUserCard()
  const { email, password, name, surname, phone, company, position, description, sessionTTL, isActive, isAdmin, createdAt, updatedAt } = data
  const isValid = [name.isValid, surname.isValid, email.isValid, company.isValid, password.isValid].every(Boolean)

  const isChanged = [
    name.isChanged,
    surname.isChanged,
    email.isChanged,
    company.isChanged,
    password.isChanged,
    phone.isChanged,
    position.isChanged,
    description.isChanged,
    sessionTTL.isChanged,
    isActive.isChanged,
    isAdmin.isChanged,
  ].some(Boolean)

  const isDisabled = loading || !isValid || !isChanged

  return (
    <>
      <ItemCardLayout state={loading}>
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" py={2}>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="email"
              disabled={loading}
              fullWidth
              value={email.value}
              onChange={email.onChange}
              onBlur={email.onBlur}
              label={email.label}
              helperText={email.helperText}
              error={email.error}
              variant="outlined"
              required
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="phone"
              disabled={loading}
              fullWidth
              value={phone.value}
              onChange={phone.onChange}
              onBlur={phone.onBlur}
              label={phone.label}
              helperText={phone.helperText}
              error={phone.error}
              variant="outlined"
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="name"
              disabled={loading}
              fullWidth
              value={name.value}
              onChange={name.onChange}
              onBlur={name.onBlur}
              label={name.label}
              helperText={name.helperText}
              error={name.error}
              variant="outlined"
              required
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="surname"
              disabled={loading}
              fullWidth
              value={surname.value}
              onChange={surname.onChange}
              onBlur={surname.onBlur}
              label={surname.label}
              helperText={surname.helperText}
              error={surname.error}
              variant="outlined"
              required
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="company"
              disabled={loading}
              fullWidth
              value={company.value}
              onChange={company.onChange}
              onBlur={company.onBlur}
              label={company.label}
              helperText={company.helperText}
              error={company.error}
              variant="outlined"
              required
              multiline
              minRows={2}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="position"
              value={position.value}
              onChange={position.onChange}
              onBlur={position.onBlur}
              label={position.label}
              helperText={position.helperText}
              error={position.error}
              fullWidth
              disabled={loading}
              variant="outlined"
              multiline
              minRows={2}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="description"
              value={description.value}
              onChange={description.onChange}
              onBlur={description.onBlur}
              label={description.label}
              helperText={description.helperText}
              error={description.error}
              fullWidth
              disabled={loading}
              variant="outlined"
              multiline
              minRows={3}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            {/* <TextField
              value={sessionTTL.value}
              onChange={sessionTTL.onChange}
              onBlur={sessionTTL.onBlur}
              label={sessionTTL.label}
              helperText={sessionTTL.helperText}
              error={sessionTTL.error}
              fullWidth
              disabled={loading}
              variant="outlined"
              required
            /> */}
            <TextFieldWithDefault id="sessionTTL" {...sessionTTL} defaultValue="0" />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <PasswordField
              id="password"
              disabled={loading}
              generator
              copy
              setValue={password.setValue}
              value={password.value}
              onChange={password.onChange}
              onBlur={password.onBlur}
              label={password.label}
              helperText={password.helperText}
              error={password.error}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h6">{t("Permissions")}</Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox disabled={loading} checked={isActive.value} />} label={isActive.label} onChange={isActive.onChange} />
              <FormControlLabel control={<Checkbox disabled={loading} checked={isAdmin.value} />} label={isAdmin.label} onChange={isAdmin.onChange} />
            </FormGroup>
          </Grid>
        </Grid>
        <Button disabled={isDisabled} type="submit" onClick={handleCreateUser} variant="contained">
          {t("Create")}
        </Button>
      </ItemCardLayout>
    </>
  )
}
