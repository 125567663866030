import { useState } from "react"
import { useDispatch } from "react-redux"
import { loginUser } from "../../../services/duty"
import { login } from "../../../slices/authSlice"
import useResponseHandler from "../../../hooks/useResponseHandler"

export default function useLogin() {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const responseHandler = useResponseHandler()

  const onChangeEmail = (value) => {
    setEmail(value)
  }

  const onChangePassword = (value) => {
    setPassword(value)
  }

  const handleLoginClick = ({ email, password }) => {
    setLoading(true)

    loginUser({ email, password })
      .then((response) => {
        dispatch(login(response.body.jwt))
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  return { email, password, loading, onChangeEmail, onChangePassword, handleLoginClick }
}
