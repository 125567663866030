import { Box, CssBaseline, Toolbar } from "@mui/material"
import "moment/locale/ru"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate, useParams } from "react-router-dom"

import "./App.css"
import ConfirmDialog from "./components/ConfirmDialog"
import FAButton from "./components/FAButton"
import Header from "./components/Header"
import SideMenu from "./components/SideMenu/SideMenu"
import useResponseHandler from "./hooks/useResponseHandler"
import { getOrganization } from "./services/organization"
import { showNotification } from "./slices/notificationSlice"
import { refuseOrganization, selectOrganization } from "./slices/organizationSlice"

function App() {
  const { organization } = useSelector((state) => state.organizationReducer)
  const { organizationId } = useParams()
  const responseHandler = useResponseHandler()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (organizationId && organizationId !== organization?.id) {
    dispatch(refuseOrganization())
    getOrganization(organizationId)
      .then((response) => {
        const { organization } = response.body
        dispatch(selectOrganization(organization))
        dispatch(showNotification({ message: t("Organization selected", { organization: organization.name }), type: "info" }))
      })
      .catch((response) => {
        navigate("/")
        responseHandler(response)
      })
  }

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <Header menuSwitcher logo searchBar themeSwitcher languageSelector accountMenu time alerts />
        <SideMenu />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      <ConfirmDialog />
      <FAButton />
    </>
  )
}

export default App
