import { Button, Grid2 as Grid, Link, Table, TableBody, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

import MarkersOnMap from "../../components/MarkersOnMap"
import Private from "../../components/Private"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import LocationInfoRow from "./components/LocationInfoRow"
import RelaySwitcher from "./components/LocationRelaySwitcher"
import useFetchLocationInfo from "./hooks/useFetchLocationInfo"
import { useDispatch } from "react-redux"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import { deleteLocation } from "../../services/location"
import { showNotification } from "../../slices/notificationSlice"
import useResponseHandler from "../../hooks/useResponseHandler"
import moment from "moment"

export default function LocationInfo() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const responseHandler = useResponseHandler()

  const { organizationId, locationId } = useParams()
  const { loading, locationInfo } = useFetchLocationInfo()
  const { name, latitude, longitude, description, address, createdAt, updatedAt, deviceCount, eventCount, cmdStates } = locationInfo
  const markers = [{ name, longitude, latitude }]

  const handleDeleteLocation = () => {
    dispatch(
      showConfirmDialog({
        title: t("You can't undo this operation"),
        message: t("Are you sure to delete this location?"),
        onConfirm: () => {
          deleteLocation(locationId)
            .then(() => {
              dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
              navigate("..")
            })
            .catch(responseHandler)
        },
      })
    )
  }

  const toDeviceListLink = (value) => (
    <Link
      sx={{ cursor: "pointer" }}
      onClick={() => navigate(`/organization/${organizationId}/devices`, { state: { selectedLocation: { id: locationId, name } } })}
    >
      {value}
    </Link>
  )

  const toEventsListLink = (value) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`/organization/${organizationId}/events?locationId=${locationId}`)}>
      {value}
    </Link>
  )

  function stateComponent(value) {
    try {
      const cmdStates = JSON.parse(value)
      switch (cmdStates.Name) {
        case "RELAY_ON":
          return t("On")
        case "RELAY_OFF":
          return t("Off")
        default:
          return null
      }
    } catch (e) {
      console.log("error", e)
    }
  }

  const summary = [
    { name: "Location Name", value: name },
    { name: "Address", value: address },
    { name: "Description", value: description },
    { name: "Schedule", value: "" },
    { name: "Devices", value: deviceCount, component: toDeviceListLink },
    { name: "Events", value: eventCount, component: toEventsListLink },
    { name: "Created at", value: createdAt && moment(createdAt).format("lll") },
    { name: "Updated at", value: updatedAt && moment(updatedAt).format("lll") },
    { name: "Current state", value: cmdStates, component: stateComponent },
  ]

  return (
    <>
      <ItemCardLayout
        title={name}
        state={loading}
        actions={
          <>
            {locationId && (
              <Private roleRequired="admin" organizationId={organizationId}>
                <Grid>
                  <Button
                    disabled={loading}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      navigate("edit")
                    }}
                    startIcon={<EditIcon fontSize="small" />}
                  >
                    {t("Edit")}
                  </Button>
                </Grid>
              </Private>
            )}
            {locationId && (
              <Private roleRequired="admin" organizationId={organizationId}>
                <Grid>
                  <Button disabled={loading} color="error" variant="outlined" onClick={handleDeleteLocation} startIcon={<DeleteIcon />}>
                    {t("Delete")}
                  </Button>
                </Grid>
              </Private>
            )}
          </>
        }
      >
        <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start" py={1}>
          <Grid size={{ xs: 12, lg: 5 }}>
            <Table size="small">
              <TableBody>
                <LocationInfoRow data={summary} />
                {/* {summary.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{t(item.title)}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
          </Grid>
          <Grid size={{ xs: 12, lg: 7 }}>
            <Grid container spacing={1} direction="column">
              <Grid size={{ xs: 12 }}>
                <MarkersOnMap data={markers} loading={loading} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Grid container spacing={1} direction="row" justifyContent="flex-end">
                  <Grid>
                    <RelaySwitcher {...{ locationId, organizationId }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ItemCardLayout>
    </>
  )
}
