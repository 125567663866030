import { Grid2 as Grid } from "@mui/material"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { ruRU } from "@mui/x-date-pickers/locales"
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers"
import moment from "moment"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

export default memo(function DateTimeRangePicker({ startTime, setStartTime, endTime, setEndTime, url }) {
  const localeText = ruRU.components.MuiLocalizationProvider.defaultProps.localeText
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Grid container spacing={1}>
      <Grid>
        <LocalizationProvider localeText={localeText} dateAdapter={AdapterMoment}>
          <DateTimePicker
            value={startTime}
            onChange={(value) => {
              setStartTime(value)
              value ? searchParams.set("startTime", value.toISOString()) : searchParams.delete("startTime")
              url && setSearchParams(searchParams)
            }}
            ampm={false}
            maxDateTime={endTime || moment()}
            label={t("Beginning of period")}
            slotProps={{ textField: { size: "small", error: !startTime && !!endTime }, field: { clearable: true } }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid>
        <LocalizationProvider localeText={localeText} dateAdapter={AdapterMoment}>
          <DateTimePicker
            value={endTime}
            onChange={(value) => {
              setEndTime(value)
              value ? searchParams.set("endTime", value.toISOString()) : searchParams.delete("endTime")
              url && setSearchParams(searchParams)
            }}
            ampm={false}
            minDateTime={startTime}
            maxDateTime={moment()}
            label={t("End of period")}
            slotProps={{ textField: { size: "small", error: !!startTime && !endTime }, field: { clearable: true } }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
})
