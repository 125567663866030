import { Paper, Table, TableBody } from "@mui/material"
import { useState } from "react"

import CollapsibleTableRow from "../components/CollapsibleTableRow"
import TableFooterComponent from "../components/TableFooterComponent"
import TableHeader from "../components/TableHeaderComponent"

export default function CollapsibleTableDataLayout(props) {
  const { data, Item, cells } = props
  const [isCollapsed, setIsCollapsed] = useState({ state: true })

  return (
    <Paper variant="outlined">
      <Table size="small" sx={{ minWidth: 650 }}>
        <TableHeader {...props} {...{ setIsCollapsed }} />
        <TableBody>
          {data.map((dataItem, dataIndex) => (
              <CollapsibleTableRow key={dataItem.id} {...{ dataItem, cells, Item, isCollapsed }} />
          ))}
        </TableBody>
      </Table>
      <TableFooterComponent {...props} />
    </Paper>
  )
}
