import { Divider, Toolbar } from "@mui/material"
import { useSelector } from "react-redux"

import Drawer from "./Drawer"
import SideMainMenu from "./SideMainMenu"
import SideSubMenu from "./SideSubMenu"

export default function SideMenu() {
  const { isOpen } = useSelector((state) => state.toggleMenuModeReducer)
  const { organization } = useSelector((state) => state.organizationReducer)

  return (
    <Drawer variant="permanent" open={isOpen}>
      <Toolbar />
      <SideMainMenu />
      <Divider />
      {organization && <SideSubMenu />}
    </Drawer>
  )
}
