import { TableCell, TableRow } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function LocationInfoRow({ data }) {
  const { t } = useTranslation()

  return (
    <>
      {data.map((dataItem, dataIndex) => {
        return (
          <TableRow key={dataIndex}>
            <TableCell>{`${t(dataItem.name)}:`}</TableCell>
            <TableCell>{dataItem.component ? dataItem.component(dataItem.value) : t(dataItem.value)}</TableCell>
          </TableRow>
        )
      })}
    </>
  )
}
