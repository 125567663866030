import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import useResponseHandler from "../hooks/useResponseHandler"
import { listMessageTypes } from "../services/messenger"

export default function MessageTypeSelector({ messageTypeId }) {
  const responseHandler = useResponseHandler()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { label, error, value, onChange, onBlur, helperText } = messageTypeId

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    listMessageTypes()
      .then((response) => {
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <FormControl required fullWidth disabled={loading}>
      <InputLabel>{label}</InputLabel>
      <Select id="messageType" label={label} error={error} value={value} onChange={onChange} onBlur={onBlur}>
        {data.map((item, index) => (
          <MenuItem id={item.id} key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
