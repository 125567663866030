import { List } from "@mui/material"
import { useSelector } from "react-redux"

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import DataUsageIcon from '@mui/icons-material/DataUsage'
import EventNoteIcon from "@mui/icons-material/EventNote"
import KeyIcon from "@mui/icons-material/Key"
import MapIcon from "@mui/icons-material/Map"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import PeopleIcon from "@mui/icons-material/People"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote"

import Private from "../Private"
import MenuItem from "./MenuItem"
import Subheader from "./Subheader"

export default function SideSubMenu() {
  const { organization } = useSelector((state) => state.organizationReducer)

  const subMenuItems = [
    { text: "Dashboard", icon: <DataUsageIcon />, path: `/organization/${organization.id}/dashboard`, name: "current-dashboard" },
    { text: "Locations", icon: <MapIcon />, path: `/organization/${organization.id}/locations`, name: "current-locations" },
    { text: "Devices", icon: <SettingsRemoteIcon />, path: `/organization/${organization.id}/devices`, name: "current-devices" },
    { text: "Users", icon: <PeopleIcon />, path: `/organization/${organization.id}/users`, name: "current-users", roleRequired: "admin" },
    { text: "Schedules", icon: <CalendarMonthIcon />, path: `/organization/${organization.id}/schedules`, name: "current-schedules", roleRequired: "admin" },
    { text: "API Keys", icon: <KeyIcon />, path: `/organization/${organization.id}/api-keys`, name: "current-api-keys", roleRequired: "admin" },
    { text: "Reports", icon: <ReceiptLongIcon />, path: `/organization/${organization.id}/reports`, name: "current-reports" },
    { text: "Events", icon: <EventNoteIcon />, path: `/organization/${organization.id}/events`, name: "current-events" },
    { text: "Logs", icon: <MenuBookIcon />, path: `/organization/${organization.id}/logs`, name: "current-logs", roleRequired: "admin" },
  ]

  return (
    <List subheader={<Subheader />}>
      {subMenuItems.map((item, index) => (
        <Private key={index} roleRequired={item.roleRequired} organizationId={organization.id}>
          <MenuItem {...item} {...{ organization }} />
        </Private>
      ))}
    </List>
  )
}
