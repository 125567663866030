import { Button, LinearProgress, Link } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"

import AddIcon from "@mui/icons-material/Add"

import NoData from "../../components/NoData"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchAPIKeys from "./hooks/useFetchAPIKeys"
import { useState } from "react"
import CheckedIcon from "../../components/CheckedIcon"

export default function ApiKeys() {
  const { t } = useTranslation()

  const { organizationId } = useParams()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")

  const params = { organizationId, search, offset, order, orderBy }
  const { totalCount, loading, data } = useFetchAPIKeys(params)

  const navigate = useNavigate()

  const toAPIKeyCardLink = (value, item) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`${item.id}/edit`)}>
      {value}
    </Link>
  )

  const cells = [
    { label: "API key name", name: "name", component: toAPIKeyCardLink },
    { label: "API key description", name: "description", isUnorderable: true },
    { label: "Is active", name: "isActive", component: (value) => <CheckedIcon state={value} /> },
    { label: "Created at", name: "createdAt", component: (value) => moment(value).format("lll") },
  ]

  return (
    <ContentLayout
      title="API Keys"
      actions={[
        <SearchBar {...{ search, setSearch }} />,
        <Private roleRequired="admin">
          <Button onClick={() => navigate("create")} variant="contained" startIcon={<AddIcon />}>
            {t("Create")}
          </Button>
        </Private>,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && <TableDataLayout   {...{
            loading,
            data,
            totalCount,
            order,
            setOrder,
            orderBy,
            setOrderBy,
            offset,
            setOffset,
            cells,
            page, 
            setPage
          }} />}
      {loading && <LinearProgress />}
    </ContentLayout>
  )
}
