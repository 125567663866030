import { Paper, Table, TableBody, TableCell, TableRow } from "@mui/material"
import { useNavigate } from "react-router-dom"

import TableFooterComponent from "../../../components/TableFooterComponent"
import TableHeader from "../../../components/TableHeaderComponent"

export default function SchedulesTable(props) {
  const navigate = useNavigate()
  const { data } = props

  return (
    <>
    <Paper variant="outlined"> 
      <Table size="small" sx={{ minWidth: 650 }}>
        <TableHeader cells={["Schedule Name"]} />
        <TableBody>
          {data.map((item, index) => (
            <TableRow hover onClick={() => navigate(`${item.id}/edit`)} sx={{ cursor: "pointer" }} key={index}>
              <TableCell>{item.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TableFooterComponent {...props} />
    </Paper>
    </>
  )
}
