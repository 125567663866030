import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DoneIcon from "@mui/icons-material/Done"
import { Button, Grid2 as Grid, IconButton, LinearProgress, Paper, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import useResponseHandler from "../../hooks/useResponseHandler"
import { getUser, updateUser } from "../../services/user"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import { showNotification } from "../../slices/notificationSlice"
import ChangePassword from "./components/ChangePassword"
import { getOrganizationUser, organizationAddUser } from "../../services/organization"
import ItemCardLayout from "../../layouts/ItemCardLayout"

export default function Settings() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authReducer.user)
  const userId = user.id

  const responseHandler = useResponseHandler()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [position, setPosition] = useState("")
  const [description, setDescription] = useState("")
  const [sessionTTL, setSessionTTL] = useState(3600)
  const [isActive, setIsActive] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)

  const props = { userId, loading }

  useEffect(() => {
    if (userId) {
      getData(userId)
    }
  }, [])

  const getData = (userId) => {
    setLoading(true)
    getUser(userId)
      .then((response) => {
        const { user } = response.body
        setName(user.name)
        setSurname(user.surname)
        setEmail(user.email)
        setPhone(user.phone)
        setCompany(user.company)
        setPosition(user.position)
        setDescription(user.description)
        setSessionTTL(user.sessionTTL)
        setIsActive(user.isActive)
        setIsAdmin(user.isAdmin)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateUser = () => {
    updateUser({
      id: userId,
      email,
      name,
      surname,
      phone,
      company,
      position,
      description,
      sessionTTL,
      isActive,
      isAdmin,
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return (
    <>
      <ItemCardLayout title={t("Edit Profile")} state={loading}>
        <Grid container item spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField id="email" fullWidth disabled={loading} label={t("E-mail")} variant="outlined" value={email} />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="phone"
              fullWidth
              disabled={loading}
              label={t("Phone number")}
              variant="outlined"
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="name"
              fullWidth
              disabled={loading}
              label={t("First Name")}
              variant="outlined"
              value={name}
              onChange={(event) => {
                setName(event.target.value)
              }}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="surname"
              fullWidth
              disabled={loading}
              label={t("Last Name")}
              variant="outlined"
              value={surname}
              onChange={(event) => {
                setSurname(event.target.value)
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="company"
              fullWidth
              disabled={loading}
              label={t("Company")}
              variant="outlined"
              multiline
              minRows={2}
              value={company}
              onChange={(event) => {
                setCompany(event.target.value)
              }}
              required
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="position"
              fullWidth
              disabled={loading}
              label={t("Position / Subdivision")}
              variant="outlined"
              multiline
              minRows={2}
              value={position}
              onChange={(event) => {
                setPosition(event.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="description"
              fullWidth
              disabled={loading}
              label={t("Notes")}
              variant="outlined"
              multiline
              minRows={3}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value)
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <TextField
              id="sessionTTL"
              fullWidth
              disabled={loading}
              label={t("User session TTL (in minutes)")}
              variant="outlined"
              value={sessionTTL}
              onChange={(e) => {
                if (/^(\s*|\d+)$/.test(e.target.value)) {
                  setSessionTTL(e.target.value)
                }
              }}
              onBlur={() => {
                if (sessionTTL < 60) {
                  setSessionTTL(60)
                }
              }}
              // onBlur={() => {
              //   setSessionTTL(parseInt(sessionTTL) || 0)
              // }}
              type="number"
              InputProps={{ inputProps: { pattern: "[0-9]", min: 0, max: 30 * 24 * 60 } }}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item direction="row" justifyContent="flex-start" alignItems="center">
          <Grid>
            <Button
              disabled={loading}
              type="submit"
              onClick={handleUpdateUser}
              // onClick={() => {
              //   dispatch(
              //     showConfirmDialog({
              //       title: t("You can't undo this operation"),
              //       message: t("Are you sure to update this user?"),
              //       onConfirm: () => {
              //         handleUpdateUser()
              //       },
              //     })
              //   )
              // }}
              variant="contained"
              startIcon={<DoneIcon />}
            >
              {t("Apply")}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} item direction="row" justifyContent="flex-start" alignItems="center">
          <Grid>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => {
                organizationAddUser(8, { organizationUser: { userId: 17, role: "globalAdmin", organizationId: 8 } })
                // getOrganizationUser(8,17)
              }}
              // onClick={() => {
              //   dispatch(
              //     showConfirmDialog({
              //       title: t("You can't undo this operation"),
              //       message: t("Are you sure to update this user?"),
              //       onConfirm: () => {
              //         handleUpdateUser()
              //       },
              //     })
              //   )
              // }}
              variant="contained"
            >
              TEST
            </Button>
          </Grid>
        </Grid>
        <ChangePassword {...props} />
      </ItemCardLayout>
    </>
  )
}
