import { FormControlLabel, Switch } from "@mui/material"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

export default memo(function AlertSwitcher({ isFailureOnly, setIsFailureOnly }) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <FormControlLabel
      label={t("Failure only")}
      labelPlacement="start"
      control={
        <Switch
          checked={isFailureOnly}
          color="error"
          onChange={() => {
            setIsFailureOnly((prev) => {
              !prev ? searchParams.set("isFailureOnly", !prev) : searchParams.delete("isFailureOnly")
              setSearchParams(searchParams)
              return !prev
            })
          }}
        />
      }
    />
  )
})
