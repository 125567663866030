import { Button, LinearProgress, Link } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"

import CheckedIcon from "../../components/CheckedIcon"
import NoData from "../../components/NoData"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchUsers from "./hooks/useFetchUsers"

export default function Users() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { organizationId } = useParams()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("email")

  const params = { organizationId, search, offset, order, orderBy }
  const { data, loading, totalCount } = useFetchUsers(params)
  

  const toUserCardLink = (value, item) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`${item.userId}/edit`)}>
      {value}
    </Link>
  )

  const cells = [
    { label: "E-mail", name: "email", component: toUserCardLink },
    { label: "Name", name: "name" },
    { label: "Surname", name: "surname" },
    { label: "Company", name: "company" },
    { label: "Position / Subdivision", name: "position", isUnorderable: true },
    { label: "Is active", name: "isActive", component: (value) => <CheckedIcon state={value} /> },
    { label: "Is admin", name: "role", component: (value) => <CheckedIcon state={value === "admin"} /> },
  ]

  return (
    <ContentLayout
      title="Users"
      actions={[
        <SearchBar {...{ search, setSearch }} />,
        <Private roleRequired="admin" organizationId={organizationId}>
          <Button onClick={() => navigate("add")} variant="contained" startIcon={<AddIcon />}>
            {t("Add")}
          </Button>
        </Private>,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <TableDataLayout
          {...{
            data,
            cells,
            loading,
            search,
            setSearch,
            offset,
            setOffset,
            page,
            setPage,
            totalCount,
            order,
            setOrder,
            orderBy,
            setOrderBy,
          }}
        />
      )}
      {loading && <LinearProgress />}
    </ContentLayout>
  )
}
