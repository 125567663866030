import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Grid2 as Grid, TextField, Typography } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import useIntegrationCard from "./hooks/useIntegrationCard"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import IntegrationSettingsEditor from "../../components/IntegrationSettingsEditor"

export default function IntegrationCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { name, kind, settings, loading, integrationId, createdAt, updatedAt, handleCreateIntegration, handleUpdateIntegration, handleDeleteIntegration } =
    useIntegrationCard()

  const isValid = [name.isValid].every(Boolean)

  const isChanged = [name.isChanged, settings.isChanged].some(Boolean)

  const isDisabled = loading || !isValid || !isChanged

  return (
    <ItemCardLayout
      state={loading}
      actions={
        integrationId && (
          <Grid>
            <Button
              disabled={loading}
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(
                  showConfirmDialog({
                    title: t("You can't undo this operation"),
                    message: t("Are you sure to delete this integration?"),
                    onConfirm: () => {
                      handleDeleteIntegration(integrationId)
                    },
                  })
                )
              }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </Grid>
        )
      }
    >
      <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" py={1}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="name"
            disabled={loading}
            fullWidth
            onBlur={name.onBlur}
            value={name.value}
            error={name.error}
            onChange={name.onChange}
            helperText={name.helperText}
            label={name.label}
            variant="outlined"
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="kind"
            disabled={loading || true}
            fullWidth
            onBlur={kind.onBlur}
            value={kind.value}
            error={kind.error}
            onChange={kind.onChange}
            helperText={kind.helperText}
            label={kind.label}
            variant="outlined"
            required
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <IntegrationSettingsEditor {...{ settings }} />
        </Grid>
        {(createdAt || updatedAt) && (
          <Grid size={{ xs: 12 }}>
            <Typography color="text.secondary">
              {t("Created at")}: {moment(createdAt).format("lll")}
            </Typography>
            <Typography color="text.secondary">
              {t("Updated at")}: {moment(updatedAt).format("lll")}
            </Typography>
          </Grid>
        )}
        <Grid size={{ xs: 12 }}>
          {integrationId ? (
            <Button disabled={isDisabled} onClick={handleUpdateIntegration} variant="contained">
              {t("Apply")}
            </Button>
          ) : (
            <Button disabled={isDisabled} onClick={handleCreateIntegration} variant="contained">
              {t("Create")}
            </Button>
          )}
        </Grid>
      </Grid>
    </ItemCardLayout>
  )
}
