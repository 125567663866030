import { Grid2 as Grid } from "@mui/material"
import StateDetails from "./StateDetails"

export default function CollapsibleCompareItem({dataItem}) {

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid size={{xs: 6}}>
        <StateDetails header={"Previous state"} data={dataItem.statePrev} />
      </Grid>
      <Grid size={{xs: 6}}>
        <StateDetails header={"Current state"} data={dataItem.stateCur} />
      </Grid>
    </Grid>
  )
}
