import { useEffect, useState } from "react"
import CollapsibleTableItemRow from "./CollapsibleTableItemRow"
import CollapsibleTableTitleRow from "./CollapsibleTableTitleRow"

export default function CollapsibleTableRow({ cells, dataItem, Item, isCollapsed }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!isCollapsed.state)
  }, [isCollapsed])


  return (
    <>
      <CollapsibleTableTitleRow  {...{ cells, dataItem, open, setOpen }} />
      <CollapsibleTableItemRow  {...{ cells, dataItem, open, Item }} />
    </>
  )
}
