import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { listAPIKeys } from "../../../services/duty"

export default function useFetchAPIKeys(props) {
  const { organizationId, search, offset, order, orderBy, limit } = props
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [totalCount, setTotalCount] = useState()

  const responseHandler = useResponseHandler()

  useEffect(() => {
    getData(search, limit || rowsPerPages, offset, order, orderBy)
  }, [organizationId, search, limit, rowsPerPages, offset, order, orderBy])

  const getData = (search, limit, offset, order, orderBy) => {
    setLoading(true)
    const isAdmin = organizationId ? false : true
    listAPIKeys({ organizationId, isAdmin, search, limit, offset, order, orderBy })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { totalCount, loading, data }
}
