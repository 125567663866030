import { IconButton } from "@mui/material"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { useTranslation } from "react-i18next"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export default function TableHeader({setIsCollapsed, cells, orderBy, setOrderBy, order, setOrder }) {
  const { t } = useTranslation()

  return (
    <TableHead>
      <TableRow sx={{ height: 80 }}>
        {setIsCollapsed && (
          <TableCell width={10}>
            <IconButton size="small" onClick={() => setIsCollapsed({ state: true })}>
              <ExpandLessIcon />
            </IconButton>
            <IconButton size="small" onClick={() => setIsCollapsed({ state: false })}>
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
        )}
        {cells.map((item, index) => (
          <TableCell key={index} width={item.width}>
            {item.isUnorderable ? t(item.label) : (
              <TableSortLabel
                active={item.name === orderBy}
                direction={order}
                onClick={() => {
                  setOrderBy(item.name)
                  order === "asc" ? setOrder("desc") : setOrder("asc")
                }}>
                {t(item.label)}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
