import CloseIcon from "@mui/icons-material/Close"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function SearchBar({ search, setSearch, title = "Search" }) {
  const { t } = useTranslation()
  const [text, setText] = useState(search)
  const timelag = (f) => setTimeout(f, 500)

  return (
    <TextField
      id="search"
      variant="outlined"
      size="small"
      onKeyDown={(event) => {
        event.key === "Enter" && setSearch(event.target.value)
      }}
      // sx={{ ml: 1, flex: 1 }}
      // sx={{ width: 300, "& fieldset": { border: "none" } }}
      sx={{ width: 250 }}
      label={t(title)}
      value={text}
      onChange={(event) => {
        setText(event.target.value)
        timelag(() => text !== search && setSearch(event.target.value))
      }}
      InputProps={{
        endAdornment: text && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setSearch("")
                setText("")
              }}
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
