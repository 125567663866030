import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { useTranslation } from "react-i18next"
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Grid2 as Grid, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import invertBitMask from "../../../helpers/invertBitMask"

export default function Week({ daysOfWeek, setDaysOfWeek }) {
  const { t } = useTranslation()
  const handleChangeDay = (event, dayId) => {
    let newValue = daysOfWeek.split("")
    newValue[dayId] = Number(event.target.checked)
    setDaysOfWeek(newValue.join(""))
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" m={2}>
            {t("Days of Week")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start" m={2}>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[0]))} onChange={(event) => handleChangeDay(event, 0)} />}
                label={t("Monday")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[1]))} onChange={(event) => handleChangeDay(event, 1)} />}
                label={t("Tuesday")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[2]))} onChange={(event) => handleChangeDay(event, 2)} />}
                label={t("Wednesday")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[3]))} onChange={(event) => handleChangeDay(event, 3)} />}
                label={t("Thursday")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[4]))} onChange={(event) => handleChangeDay(event, 4)} />}
                label={t("Friday")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[5]))} onChange={(event) => handleChangeDay(event, 5)} />}
                label={t("Saturday")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={Boolean(parseInt(daysOfWeek[6]))} onChange={(event) => handleChangeDay(event, 6)} />}
                label={t("Sunday")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button
            onClick={() => {
              setDaysOfWeek(invertBitMask(daysOfWeek))
            }}
          >
            {t("Invert")}
          </Button>
          <Button
            onClick={() => {
              setDaysOfWeek("1111111")
            }}
          >
            {t("Select All")}
          </Button>
          <Button
            onClick={() => {
              setDaysOfWeek("0000000")
            }}
          >
            {t("Clear All")}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  )
}
