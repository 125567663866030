import { LinearProgress, Link, Typography } from "@mui/material"
import { useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import NoData from "../../components/NoData"
import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchLocations from "../../hooks/useFetchLocations"
import { useTranslation } from "react-i18next"

export default function LocationsTable({ search }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const { state } = useLocation()
  const params = { search, offset, order, orderBy, organizationId }
  const { data, loading, totalCount } = useFetchLocations(params)

  const toLocationInfoLink = (value, item) => (
    <Link onClick={() => navigate(`/organization/${organizationId}/locations/${item.id}`)} style={{ cursor: "pointer" }}>
      {value}
    </Link>
  )

  const toDeviceInfoLink = (value, item) => (
    <Link onClick={() => navigate(`/organization/${organizationId}/devices/`, { state: { ...state, selectedLocation: item } })} style={{ cursor: "pointer" }}>
      {value}
    </Link>
  )

  const toEventInfoLink = (value, item) => (
    <Link onClick={() => navigate(`/organization/${organizationId}/events/`)} style={{ cursor: "pointer" }}>
      {value}
    </Link>
  )

  function stateComponent(value) {
    try {
      const cmdStates = JSON.parse(value)
      switch (cmdStates.Name) {
        case "RELAY_ON":
          return <b>{t("On")}</b>
        case "RELAY_OFF":
          return t("Off")
        default:
          return null
      }
    } catch (e) {console.log('error', e)}
  }

  const cells = [
    { label: "#", name: "id", width: 10, isUnorderable: true },
    { label: "Location Name", name: "name", component: toLocationInfoLink },
    { label: "Address", name: "address", isUnorderable: true },
    { label: "State", name: "cmdStates", component: stateComponent, isUnorderable: true },
    { label: "Devices qty", name: "deviceCount", component: toDeviceInfoLink },
    { label: "Events qty", name: "eventCount", component: toEventInfoLink, isUnorderable: true },
  ]

  return (
    <>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <TableDataLayout
          {...{
            loading,
            data,
            totalCount,
            order,
            setOrder,
            orderBy,
            setOrderBy,
            offset,
            setOffset,
            cells,
            page,
            setPage,
          }}
        />
      )}
      {loading && <LinearProgress />}
    </>
  )
}
