import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { showNotification } from "../../../slices/notificationSlice"

export default function NewAPIKeyDialog({ open, setOpen, APIkey }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("New API key created")}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>{t("Attention!")}</AlertTitle>
          <ul>
            <li>{t("The received key must be copied now; after closing the window, viewing and copying the key will not be available")}</li>
            <li>{t("The generated token does not expire")}</li>
          </ul>
        </Alert>
        <DialogContentText sx={{ m: 2, wordBreak: "break-all" }}>{APIkey}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<ContentCopyIcon />}
          autoFocus
          onClick={() => {
            navigator.clipboard.writeText(APIkey).then(dispatch(showNotification({ message: t("Copied to clipboard"), type: "success" })))
          }}>
          {t("Copy to clipboard")}
        </Button>

        <Button
         variant="contained"
          autoFocus
          onClick={() => {
            setOpen(false)
            navigate("..")
          }}>
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
