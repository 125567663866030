import ErrorIcon from "@mui/icons-material/Error"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import InfoIcon from "@mui/icons-material/Info"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import WarningIcon from "@mui/icons-material/Warning"
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"

import { Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function EventTypes({ type, unread }) {
  const { t } = useTranslation()
  let Icon
  switch (type) {
    case 1:
      Icon = unread ? InfoIcon : InfoOutlinedIcon
      return <Tooltip title={t("info")}><Icon color="info" /></Tooltip>
    case 2:
      Icon = unread ? WarningIcon : WarningAmberRoundedIcon 
      return <Tooltip title={t("error")}><Icon color="warning" /></Tooltip>
    case 3:
      Icon = unread ? ErrorIcon  : ErrorOutlineOutlinedIcon 
      return <Tooltip title={t("failure")}><Icon color="error" /></Tooltip>      
  }
}
