import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation, latitudeValidation, longitudeValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createLocation, deleteLocation, getLocation, updateLocation } from "../../../services/location"
import { showNotification } from "../../../slices/notificationSlice"
import useLocations from "./useLocations"

export default function useLocationCard() {
  const { organizationId, locationId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const responseHandler = useResponseHandler()
  const [loading, setLoading] = useState(false)
  const { setSearch, data } = useLocations()

  const name = useInput({ label: t("Location Name"), helperText: t("Unique name") }, { ...baseValidation, unique: { setSearch, data } })
  const address = useInput({ label: t("Address"), helperText: t("Physical address") }, baseValidation)
  const latitude = useInput({ init: 55.753298, label: t("Latitude") }, latitudeValidation)
  const longitude = useInput({ init: 37.621938, label: t("Longitude") }, longitudeValidation)
  const scheduleGroupId = useInput({ label: t("Schedule") }, { isEmptyCheck: true })
  const description = useInput({ label: t("Description") })

  useEffect(() => {
    if (locationId) {
      getData(locationId, organizationId)
    }
  }, [])

  const getData = (locationId, organizationId) => {
    setLoading(true)
    getLocation(locationId, organizationId)
      .then((response) => {
        const { location } = response.body
        name.set(location.name)
        address.set(location.address)
        latitude.set(location.latitude)
        longitude.set(location.longitude)
        scheduleGroupId.set(location.scheduleGroupId)
        description.set(location.description)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateLocation = () => {
    setLoading(true)
    createLocation({
      location: {
        organizationId,
        name: name.value,
        address: address.value,
        scheduleGroupId: scheduleGroupId.value,
        latitude: latitude.value,
        longitude: longitude.value,
        description: description.value,
      },
      organizationId,
    })
      .then(() => {
        dispatch(showNotification({ message: t("New location is created!"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateLocation = () => {
    updateLocation({
      organizationId,
      id: locationId,
      name: name.value,
      address: address.value,
      scheduleGroupId: scheduleGroupId.value,
      latitude: latitude.value,
      longitude: longitude.value,
      description: description.value,
    }, organizationId)
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteLocation = (id) => {
    deleteLocation(id, organizationId)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }
  return { name, description, scheduleGroupId, latitude, longitude, address, loading, handleCreateLocation, handleUpdateLocation, handleDeleteLocation }
}
