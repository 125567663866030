import {  Paper } from "@mui/material"
import { useState } from "react"

import ContentLayout from "../../layouts/ContentLayout"
import NoData from "../../components/NoData"
import SearchBar from "../../components/SearchBar"

export default function Reports() {
const [search, setSearch] = useState("")
  return (
    <>
      {/* <Paper sx={{ padding: "20px" }}> */}
        <ContentLayout
          title="Reports"
          actions={[<SearchBar {...{ search, setSearch}}  />]}
        >
         <NoData />
         </ContentLayout>
      {/* </Paper> */}
    </>
  )
}
