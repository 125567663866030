import { TableCell, TableRow, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

// const mock = {
//   dr: 5,
//   adr: true,
//   mic: "d5ed9a81",
//   data: "01",
//   dpID: "2a753a4c-bc9b-4121-8a1e-84566b78cf16",
//   fCnt: 263913,
//   late: false,
//   nsID: 1,
//   spID: "b2a1d0fc-1782-4d41-837f-a60c29d51516",
//   tags: {},
//   time: "2024-08-25T16:19:42.451603063Z",
//   class: "C",
//   fPort: 10,
//   orgID: 101,
//   devEUI: "fedcba9876543210",
//   rxInfo: [
//     {
//       rssi: -25,
//       channel: 0,
//       context: "00000000000000000063000082cb0083",
//       loRaSNR: 8.25,
//       location: { source: "UNKNOWN", accuracy: 0, altitude: 0, latitude: 55, longitude: 38 },
//       uplinkID: "1d5c0effaa2e46a5bd69b5794717e623",
//       gatewayID: "46584254c0001614",
//       fineTimestampType: "NONE",
//     },
//   ],
//   batTime: "2024-02-29T16:34:10.413614Z",
//   devAddr: "69a5fb72",
//   batLevel: 100,
//   extPower: false,
//   noBatLvl: false,
//   confirmed: false,
//   deviceName: "STM32_debugger#1",
//   objectJSON: "",
// }

export default function StateFirstType({ state }) {
  const { t } = useTranslation()

  if (state?.relay_on === true || state?.relay_on === false)
  {
    const info = [{ name: "Current state", value: state.relay_on ? "On" : "Off" }]

    return (
      <>
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="h6">{t("State")}</Typography>
          </TableCell>
        </TableRow>
        {info.map((dataItem, dataIndex) => {
          const unit = dataItem.unit ? " " + t(`units.${dataItem.unit}`) : ""
          return (
            <TableRow key={dataIndex}>
              <TableCell>{`${t(dataItem.name)}:`}</TableCell>
              <TableCell>{dataItem.component ? dataItem.component(dataItem.value) : t(dataItem.value) + unit}</TableCell>
            </TableRow>
          )
        })}
      </>
    )
  }

}
