import { useTheme } from "@emotion/react"
import { Box, LinearProgress, Link, Paper, Typography } from "@mui/material"
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import useResponseHandler from "../../hooks/useResponseHandler"
import { getDashboardCnt } from "../../services/duty"

export default function DevicesCard({ width }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const { organizationId } = useParams()
  const responseHandler = useResponseHandler()
  const navigate = useNavigate()

  useEffect(() => {
    getData(organizationId || "0")
  }, [organizationId])

  const getData = (organizationId) => {
    setLoading(true)
    getDashboardCnt(organizationId)
      .then((response) => {
        setData(response.body)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  function Title() {
    if (!isNaN(data?.onlineCnt) && !isNaN(data?.offlineCnt)) {
      const devices = `${t("Devices")}: ${data.onlineCnt + data.offlineCnt}`
      if (organizationId > 0) {
        return (
          <Link variant="h6" sx={{ cursor: "pointer" }} onClick={() => navigate(`/organization/${organizationId}/devices`)}>
            {devices}
          </Link>
        )
      } else {
        return <Typography variant="h6">{devices}</Typography>
      }
    } else {
      return <Typography variant="h6">{t("Devices")}</Typography>
    }
  }

  const devices = [
    {
      value: data.onlineCnt,
      label: (location) => (location === "legend" ? `${t("online")}: ${data.onlineCnt}` : t("online")),
      color: theme.palette.info.main,
    },
    {
      value: data.offlineCnt,
      label: (location) => (location === "legend" ? `${t("offline")}: ${data.offlineCnt}` : t("offline")),
      color: theme.palette.error.main,
    },
  ]

  return (
    <Paper variant="outlined" sx={{ padding: 2, width }}>
      <Title />
      {loading ? (
        <LinearProgress />
      ) : (
        (data?.onlineCnt > 1 || data?.offlineCnt > 1) && (
          <PieChart
            width={400}
            height={200}
            skipAnimation
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 45,
                data: devices,
                innerRadius: 50,
                outerRadius: 100,
                paddingAngle: 1,
                startAngle: 0,
                endAngle: 360,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
              },
            }}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "middle", horizontal: "right" },
                padding: -10,
              },
            }}
          />
        )
      )}
    </Paper>
  )
}
