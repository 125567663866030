import { Alert } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function NoData() {

    const {t} = useTranslation()
    return <Alert 
    // variant="filled" 
    // variant="outlined" 
    severity="info">{t("Not Found")}</Alert>
};
