import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

export default function Private(props) {
  const params = useParams()
  const { roleRequired, organizationId = params.organizationId, children } = props
  const { user } = useSelector((state) => state.authReducer)
  const userRole = user.roles[organizationId]

  const ROLES = {
    globalAdmin: ["globalAdmin"],
    admin: ["globalAdmin", "admin"],
    user: ["globalAdmin", "admin", "user"],
  }

  switch (true) {
    case user.is_admin:
      return children
    case !roleRequired:
      return children
    case organizationId && ROLES[roleRequired].includes(userRole):
      return children
    default:
      return null
  }
}
