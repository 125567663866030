import { Box, Button, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import PlaceIcon from "@mui/icons-material/Place"

import FilterByLocation from "../../components/FilterByLocation"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import DeviceMap from "./DeviceMap"
import DeviceTable from "./DeviceTable"

export default function Devices() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()

  const { organizationId } = useParams()
  const [locationId, setLocationId] = useState(state?.selectedLocation?.id)
  const [search, setSearch] = useState("")
  const [selectedTab, setSelectedTab] = useState(state?.tab || "list")

  const handleChangeTab = (event, tab) => {
    setSelectedTab(tab)
    navigate(".", { state: { ...state, tab } })
  }

  return (
    <ContentLayout
      title="Devices"
      actions={[
        <FilterByLocation {...{ organizationId, locationId, setLocationId }} />,
        <SearchBar {...{ search, setSearch }} />,
        <Private roleRequired="admin">
          <Button onClick={() => navigate("create")} variant="contained" startIcon={<AddIcon />}>
            {t("Create")}
          </Button>
        </Private>,
      ]}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tab icon={<FormatListNumberedIcon />} label={t("List")} value="list" />
          <Tab icon={<PlaceIcon />} label={t("Map")} value="map" />
        </Tabs>
      </Box>
      {selectedTab === "list" && <DeviceTable {...{ locationId, search }} />}
      {selectedTab === "map" && <DeviceMap {...{ locationId, search }} />}
    </ContentLayout>
  )
}
