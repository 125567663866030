import KeyIcon from "@mui/icons-material/Key"
import { Button, Grid2 as Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useParams } from "react-router-dom"
import { showConfirmDialog } from "../slices/confirmDialogSlice"
import PasswordField from "./PasswordField"

export default function ChangePassword({ loading, data, handleChangePassword }) {
  const { password } = data
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { t } = useTranslation()

  const isValid = password.isValid
  const isChanged = password.isChanged
  const isDisabled = loading || !isValid || !isChanged

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" py={2}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <PasswordField
          id="password"
          fullWidth
          generator
          copy
          variant="outlined"
          setValue={password.setValue}
          value={password.value}
          onChange={password.onChange}
          onBlur={password.onBlur}
          label={password.label}
          helperText={password.helperText}
          error={password.error}
          required
        />
      </Grid>
      {userId && (
        <Grid size={{ xs: 12 }}>
          <Button
            disabled={isDisabled}
            onClick={() => {
              dispatch(
                showConfirmDialog({
                  title: t("You can't undo this operation"),
                  message: t("Are you sure to change password?"),
                  onConfirm: () => {
                    handleChangePassword()
                    password.setValue("")
                  },
                })
              )
            }}
            variant="contained"
            startIcon={<KeyIcon />}
          >
            {t("Change password")}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
