import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function getSchedules(filters) {
  return client.apis.ScheduleService.ScheduleService_List(filters)
}

export async function createSchedule(data) {
  return client.apis.ScheduleService.ScheduleService_Create({ body: data })
}

export async function getSchedule(id) {
  return client.apis.ScheduleService.ScheduleService_Get({ id })
}

export async function updateSchedule(schedule) {
  return client.apis.ScheduleService.ScheduleService_Update({ body: { schedule } })
}

export async function deleteSchedule(id) {
  return client.apis.ScheduleService.ScheduleService_Delete({ id })
}

export async function getScheduleGroups(filters) {
  return client.apis.ScheduleService.ScheduleService_ListScheduleGroup(filters)
}

export async function createScheduleGroup(data) {
  return client.apis.ScheduleService.ScheduleService_CreateScheduleGroup({ body: data })
}

export async function getScheduleGroup(id) {
  return client.apis.ScheduleService.ScheduleService_GetScheduleGroup({ id })
}

export async function updateScheduleGroup(schedule) {
  return client.apis.ScheduleService.ScheduleService_Update({ body: { schedule } })
}

export async function deleteScheduleGroup(id) {
  return client.apis.ScheduleService.ScheduleService_DeleteScheduleGroup({ id })
}
