import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  organization: JSON.parse(localStorage.getItem("organization")),
}

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    selectOrganization: (state, action) => {
      state.organization = action.payload
      localStorage.setItem("organization", JSON.stringify(action.payload))
    },
    refuseOrganization: (state) => {
      delete state.organization
      localStorage.removeItem("organization")
    },
  },
})

export const { selectOrganization, refuseOrganization } = organizationSlice.actions
export const organizationReducer = organizationSlice.reducer
