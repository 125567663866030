
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


import moment from 'moment'

export default function TimePickerComponent({value, setValue, label}) {

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
        slotProps={{ textField: { fullWidth: true } }}
        value={moment.parseZone(value).local()}
        ampm={false}
        onChange={(newValue) => setValue(moment(newValue).utc().format())}
          label={label}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
    </LocalizationProvider>
   
    </>
  );
}