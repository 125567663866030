import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Grid2 as Grid, IconButton, LinearProgress, Paper, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import PathBreadcrumbs from "../components/PathBreadcrumbs"
import { useTranslation } from "react-i18next"

export default function ItemCardLayout({ children, actions, state }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <>
      <Paper sx={{ padding: "20px" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap" py={1} spacing={1}>
          <Grid>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <PathBreadcrumbs />
          </Grid>
            {actions}
        </Grid>
        {children}
      </Paper>
      {state && <LinearProgress />}
    </>
  )
}
