
import { createSlice } from "@reduxjs/toolkit"

const initialState = { isOpen: false, title: "Are you sure?", message: "" }

export const confirmDialogSlice = createSlice({
  name: "confirmDialog",
  initialState,
  reducers: {
    showConfirmDialog: (state, action) => {
      state.title = action.payload.title
      state.message = action.payload.message
      state.onConfirm = action.payload.onConfirm
      state.isOpen = true
    },
    hideConfirmDialog: (state) => {
      state.isOpen = false
    },
  },
})

export const { showConfirmDialog, hideConfirmDialog } = confirmDialogSlice.actions
export const confirmDialogReducer = confirmDialogSlice.reducer
