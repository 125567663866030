import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function createLocation(data) {
  return client.apis.LocationService.LocationService_Create({ body: data })
}

export async function deleteLocation(id, organizationId) {
  return client.apis.LocationService.LocationService_Delete({ id, organizationId })
}

export async function getLocation(id, organizationId) {
  return client.apis.LocationService.LocationService_Get({ id, organizationId })
}

export async function listLocations(filters) {
  return client.apis.LocationService.LocationService_List(filters)
}

export async function updateLocation(location, organizationId) {
  return client.apis.LocationService.LocationService_Update({ body: { location, organizationId } })
}
