import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createDeviceType, deleteDeviceType, getDeviceType, updateDeviceType } from "../../../services/device"
import { showNotification } from "../../../slices/notificationSlice"

export default function useDeviceTypeCard() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { deviceTypeId } = useParams()
  const dispatch = useDispatch()
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")
  const responseHandler = useResponseHandler()

  const name = useInput({ label: t("Device type name") }, baseValidation)
  // const wanType = useInput({ label: t("Connection type") }, { isEmptyCheck: true })
  const integrationId = useInput({ label: t("Integration") }, { isEmptyCheck: true })
  const messageTypeId = useInput({ label: t("Message type") }, { isEmptyCheck: true })
  // const relayOffCmd = useInput({ label: t("Relay off command") }, { isEmptyCheck: true })
  // const relayOnCmd = useInput({ label: t("Relay on command") }, { isEmptyCheck: true })
  const repeatInterval = useInput({ label: t("Repeat interval") }, { isNumber: true, })
  const sendNumOfTimes = useInput({ label: t("Send number of times") }, { isNumber: true, })
  const description = useInput({ label: t("Description") })
  const offlineAfter = useInput({ label: t("Unavailability timeout (sec.)") }, { isNumber: true })

  useEffect(() => {
    if (deviceTypeId) {
      getData(deviceTypeId)
    }
  }, [])

  const getData = (deviceTypeId) => {
    setLoading(true)
    getDeviceType(deviceTypeId)
      .then((response) => {
        const { deviceType, createdAt, updatedAt } = response.body
        name.set(deviceType.name)
        // wanType.set(deviceType.wanType)
        integrationId.set(deviceType.integrationId)
        messageTypeId.set(deviceType.messageTypeId)
        // relayOffCmd.set(deviceType.relayOffCmd)
        // relayOnCmd.set(deviceType.relayOnCmd)
        repeatInterval.set(deviceType.repeatInterval)
        sendNumOfTimes.set(deviceType.sendNumOfTimes)
        description.set(deviceType.description)
        offlineAfter.set(deviceType.offlineAfter)
        setCreatedAt(createdAt)
        setUpdatedAt(updatedAt)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateDeviceType = () => {
    setLoading(true)
    createDeviceType({
      deviceType: {
        name: name.value,
        // wanType: wanType.value,
        integrationId: integrationId.value,
        messageTypeId: messageTypeId.value,
        // relayOffCmd: relayOffCmd.value,
        // relayOnCmd: relayOnCmd.value,
        repeatInterval: repeatInterval.value,
        sendNumOfTimes: sendNumOfTimes.value,
        description: description.value,
        offlineAfter: offlineAfter.value,
      },
    })
      .then(() => {
        dispatch(showNotification({ message: t("Created successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateDeviceType = () => {
    updateDeviceType({
      id: deviceTypeId,
      name: name.value,
      description: description.value,
      // wanType: wanType.value,
      integrationId: integrationId.value,
      messageTypeId: messageTypeId.value,
      // relayOffCmd: relayOffCmd.value,
      // relayOnCmd: relayOnCmd.value,
      repeatInterval: repeatInterval.value,
      sendNumOfTimes: sendNumOfTimes.value,
      offlineAfter: offlineAfter.value,
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteDeviceType = (id) => {
    deleteDeviceType(id)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }
  return {
    name,
    // wanType,
    integrationId,
    // relayOffCmd,
    // relayOnCmd,
    repeatInterval,
    sendNumOfTimes,
    description,
    deviceTypeId,
    offlineAfter,
    messageTypeId,
    createdAt,
    updatedAt,
    handleCreateDeviceType,
    handleUpdateDeviceType,
    handleDeleteDeviceType,
    loading,
  }
}
