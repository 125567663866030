import InputBase from "@mui/material/InputBase"
import { styled } from "@mui/material/styles"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}))

export default function SearchBarInput() {
  const [query, setQuery] = useState("")
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <StyledInputBase
    id="globalSearch"
      value={query}
      onKeyDown={(event) => {
        event.key === "Enter" && navigate(`/search?query=${query}`)
      }}
      // onBlur={()=> navigate(`/search?query=${query}`)}
      // sx={{ ml: 1, flex: 1 }}
      // endAdornment={
      //   query && (
      //     <InputAdornment position="end">
      //       <IconButton
      //         edge="start"
      //         onClick={() => {
      //           setQuery("")
      //         }}>
      //         <CloseIcon fontSize="small" />
      //       </IconButton>
      //     </InputAdornment>
      //   )
      // }
      onChange={(event) => {
        setQuery(event.target.value)
      }}
      placeholder={t("Search…")}
    />
  )
}
