import { Paper, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { JSONTree } from "react-json-tree"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "react-redux"

export default function StateDetails({ header, data }) {
  const { t } = useTranslation()
  const muiTheme = useTheme()
  const theme = {
    scheme: "mui",
    base00: muiTheme.palette.background.paper,
    // base01: muiTheme.palette.background.paper,
    // base02: '#49483e',
    // base03: '#75715e',
    // base04: '#a59f85',
    // base05: '#f8f8f2',
    // base06: '#f5f4f1',
    // base07: '#f9f8f5',
    // base08: '#f92672',
    // base09: '#fd971f',
    // base0A: '#f4bf75',
    // base0B: '#000000',
    // base0C: '#a1efe4',
    // base0D: '#333333',
    // base0E: '#ae81ff',
    // base0F: '#cc6633',
  }

  return data && <JSONTree shouldExpandNodeInitially={() => true} keyPath={[t(header)]} data={JSON.parse(data)} theme={theme} />
}
