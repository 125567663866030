import DevicesCard from "../../components/Charts/DevicesCard"
import ContentLayout from "../../layouts/ContentLayout"

export default function Dashboard() {
  return (
    <ContentLayout title="Dashboard">
          <DevicesCard width={450}/>  
    </ContentLayout>
  )
}
