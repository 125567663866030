import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { getLocation } from "../../../services/location"

export default function useFetchLocationInfo() {
  const { organizationId, locationId } = useParams()
  const responseHandler = useResponseHandler()
  const [isRunning, setIsRunning] = useState(true)
  const autoUpdateInterval = 10

  const [loading, setLoading] = useState(true)
  const [locationInfo, setLocationInfo] = useState({})

  useEffect(() => {
    if (locationId && organizationId) {
      getData(locationId, organizationId)
      if (isRunning) {
        const interval = setInterval(() => {
          if (document.visibilityState === "visible") {
            getData(locationId, organizationId)
          }
        }, autoUpdateInterval * 1e3)
        return () => clearInterval(interval)
      }
    }
  }, [])

  const getData = (locationId, organizationId) => {
    getLocation(locationId, organizationId)
      .then((response) => {
        const { location, createdAt, updatedAt, deviceCount, eventCount, cmdStates } = response.body
        setLocationInfo({
          name: location.name,
          description: location.description,
          scheduleGroupId: location.scheduleGroupId,
          address: location.address,
          latitude: location.latitude,
          longitude: location.longitude,
          deviceCount, eventCount, createdAt, updatedAt, cmdStates
        })
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  return { loading, locationInfo }
}
