import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import { IconButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { showNotification } from "../../slices/notificationSlice"
import { toggleColorMode } from "../../slices/toggleColorModeSlice"
import { useTranslation } from "react-i18next"

export default function ThemeSwitcher() {
  const { t } = useTranslation()
  const theme = useSelector((state) => state.toggleColorModeReducer)
  const dispatch = useDispatch()

  const handleChange = () => {
    dispatch(toggleColorMode(theme === "dark" ? "light" : "dark"))
    dispatch(showNotification({ message: t("Theme changed"), type: "success" }))
  }

  return (
    <IconButton sx={{ ml: 1 }} onClick={handleChange} color="inherit">
      {theme === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  )
}
