import { Autocomplete, TextField } from "@mui/material"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import useFetchDevices from "../hooks/useFetchDevices"

export default memo(function FilterByDevice({ organizationId, deviceId, setDeviceId, url }) {
  const { t } = useTranslation()
  const { data, loading } = useFetchDevices({limit: 9999, organizationId})
  const { state } = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Autocomplete
      disabled={loading}
      onChange={(event, selectedDevice) => {
        setDeviceId(selectedDevice?.id)
        if (url) {
          selectedDevice?.id ? searchParams.set("deviceId", selectedDevice.id) : searchParams.delete("deviceId")
          setSearchParams(searchParams)
        } else {
          navigate(".", { state: { ...state, selectedDevice } })
        }
      }}
      clearText={t("Clear")}
      openText={t("Open")}
      closeText={t("Close")}
      loadingText={t("Loading…")}
      noOptionsText={t("No options")}
      size="small"
      disablePortal
      getOptionLabel={(option) => option.name}
      options={data}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={(url ? data.find((item) => item.id === deviceId) : state?.selectedDevice) || null}
      sx={{ width: 250 }}
      renderInput={(params) => <TextField {...params} label={t("Filter by Device")} />}
    />
  )
})
