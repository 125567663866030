import { Button, LinearProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"

import NoData from "../../components/NoData"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import useSchedules from "../../hooks/useSchedules"
import ContentLayout from "../../layouts/ContentLayout"
import SchedulesTable from "./components/SchedulesTable"

export default function Schedules() {
  const props = useSchedules()
  const { data, loading, search, setSearch } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <ContentLayout
      title="Schedules"
      actions={[
        <SearchBar {...{ search, setSearch }} />,
          <Private roleRequired="admin">
          <Button onClick={() => navigate("create")} variant="contained" startIcon={<AddIcon />}>
            {t("Create")}
          </Button></Private>
        ,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && <SchedulesTable {...props} />}
      {loading && <LinearProgress />}
    </ContentLayout>
  )
}
