import { Button, Grid2 as Grid, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import DeleteIcon from "@mui/icons-material/Delete"

// import DeviceThresholds from "./components/DeviceThresholds"
import LocationSelector from "../../components/LocationSelector"
import MarkerPickerMap from "../../components/MarkerPickerMap"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import DeviceTypeSelector from "./components/DeviceTypeSelector"
import useDeviceCard from "./hooks/useDeviceCard"

export default function DeviceCard() {
  const { organizationId } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    name,
    deviceId,
    deviceTypeId,
    locationId,
    externalId,
    // scheduleGroupId,
    description,
    latitude,
    longitude,
    loading,
    // limits,
    handleDeleteDevice,
    handleUpdateDevice,
    handleCreateDevice,
  } = useDeviceCard()

  // const { voltageMin, voltageMax, currentMin, currentMax, powerMin, powerMax } = limits

  const isValid = [
    name.isValid,
    deviceTypeId.isValid,
    // scheduleGroupId.isValid,
    locationId.isValid,
    latitude.isValid,
    longitude.isValid,
    externalId.isValid,
    // voltageMin.isValid,
    // voltageMax.isValid,
    // currentMin.isValid,
    // currentMax.isValid,
    // powerMin.isValid,
    // powerMax.isValid,
  ].every(Boolean)

  const isChanged = [
    name.isChanged,
    deviceTypeId.isChanged,
    locationId.isChanged,
    externalId.isChanged,
    // scheduleGroupId.isChanged,
    description.isChanged,
    latitude.isChanged,
    longitude.isChanged,
    // voltageMin.isChanged,
    // voltageMax.isChanged,
    // currentMin.isChanged,
    // currentMax.isChanged,
    // powerMin.isChanged,
    // powerMax.isChanged,
  ].some(Boolean)

  const isDisabled = loading || !isValid || !isChanged

  return (
    <ItemCardLayout
      state={loading}
      actions={
        deviceId && (
          <Grid>
            <Button
              disabled={loading}
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(
                  showConfirmDialog({
                    title: t("You can't undo this operation"),
                    message: t("Are you sure to delete this device?"),
                    onConfirm: () => {
                      handleDeleteDevice(deviceId)
                    },
                  })
                )
              }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </Grid>
        )
      }
    >
      <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" py={1}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="name"
            fullWidth
            disabled={loading}
            variant="outlined"
            label={name.label}
            onBlur={name.onBlur}
            value={name.value}
            error={name.error}
            onChange={name.onChange}
            helperText={name.helperText}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <DeviceTypeSelector {...{ organizationId, deviceTypeId }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="externalId"
            fullWidth
            disabled={loading}
            variant="outlined"
            value={externalId.value}
            onChange={externalId.onChange}
            onBlur={externalId.onBlur}
            label={externalId.label}
            helperText={externalId.helperText}
            error={externalId.error}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField id="timeout" label={t("Unavailability timeout (sec.)")} fullWidth disabled={loading} variant="outlined" />
        </Grid>
        {/* <Grid size={{ xs: 12, lg: 6 }}>
          <DeviceThresholds {...{ loading, limits }} />
        </Grid> */}
        <Grid size={{ xs: 12, lg: 6 }}>
          <LocationSelector {...{ locationId }} />
        </Grid>
        {/* <Grid size={{ xs: 12, lg: 6 }}>
          <ScheduleSelector {...{ scheduleGroupId }} />
        </Grid> */}
        <Grid size={{ xs: 12 }}>
          <TextField
            id="description"
            fullWidth
            disabled={loading}
            label={description.label}
            variant="outlined"
            multiline
            minRows={2}
            value={description.value}
            onChange={description.onChange}
            onBlur={description.onBlur}
            error={description.error}
            helperText={description.helperText}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <MarkerPickerMap {...{ latitude, longitude }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="latitude"
            fullWidth
            disabled={loading}
            variant="outlined"
            label={latitude.label}
            onBlur={latitude.onBlur}
            value={latitude.value}
            error={latitude.error}
            onChange={latitude.onChange}
            helperText={latitude.helperText}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="longitude"
            fullWidth
            disabled={loading}
            variant="outlined"
            label={longitude.label}
            onBlur={longitude.onBlur}
            value={longitude.value}
            error={longitude.error}
            onChange={longitude.onChange}
            helperText={longitude.helperText}
            required
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          {deviceId ? (
            <Button type="submit" disabled={isDisabled} onClick={handleUpdateDevice} variant="contained">
              {t("Apply")}
            </Button>
          ) : (
            <Button type="submit" disabled={isDisabled} onClick={handleCreateDevice} variant="contained">
              {t("Create")}
            </Button>
          )}
        </Grid>
      </Grid>
    </ItemCardLayout>
  )
}
