import { AppBar, Box, IconButton, Toolbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import MenuOpenIcon from "@mui/icons-material/MenuOpen"

import Logo from "../../assets/Logo.png"
import { toggleMenuMode } from "../../slices/toggleMenuModeSlice"
import AccountMenu from "./AccountMenu"
import Alerts from "./Alerts"
import LanguageSelector from "./LanguageSelector"
import SearchAppBar from "./SearchAppBar"
import ThemeSwitcher from "./ThemeSwitcher"
import Time from "./Time"

export default function Header({ menuSwitcher, logo, searchBar, themeSwitcher, languageSelector, accountMenu, alerts, time }) {
  const dispatch = useDispatch()
  const organization = useSelector((state) => state.organizationReducer?.organization)
  const isOpen = useSelector((state) => state.toggleMenuModeReducer?.isOpen)

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {menuSwitcher && (
          <IconButton
            color="inherit"
            onClick={() => {
              dispatch(toggleMenuMode())
            }}>
            <MenuOpenIcon sx={{ transform: isOpen ? "scale(1)" : "scale(-1)" }} />
          </IconButton>
        )}
        {logo && (
          <Box mx={5} display="flex">
            <img style={{ height: 32 }} src={Logo} alt="ERLight" />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />

        {time && <Time />}
        {searchBar && <SearchAppBar />}
        {themeSwitcher && <ThemeSwitcher />}
        {languageSelector && <LanguageSelector />}
        {alerts && organization && <Alerts />}
        {accountMenu && <AccountMenu />}
      </Toolbar>
    </AppBar>
  )
}
