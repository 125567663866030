import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { organizationListUsers } from "../../../services/organization"

export default function useFetchUsers(props) {
  const { organizationId, limit, search, offset, order, orderBy } = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()

  useEffect(() => {
    getData(organizationId, search, limit || rowsPerPages, offset, order, orderBy)
  }, [organizationId, search, limit, rowsPerPages, offset, order, orderBy])

  const getData = (organizationId, search, limit, offset, order, orderBy) => {
    setLoading(true)
    organizationListUsers({ organizationId, search, limit, offset, order, orderBy })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data,  loading, totalCount }
}
