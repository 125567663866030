import { useEffect, useState } from "react"
import useValidation from "./useValidation"

export default function useCheckBoxInput({ init = false, label } = {}) {
  const [initValue, setInitValue] = useState(init)
  const [value, setValue] = useState(initValue)

  const set = (value) => {
    setInitValue(value)
    setValue(value)
  }

  const onChange = (e) => {
      setValue(e.target.checked)
  }

  const reset = () => {
    setValue(initValue)
  }

  return {
    initValue,
    set,
    value,
    setValue,
    onChange,
    label,
    isUndo: initValue !== value,
    isChanged: initValue !== value,
    reset,
  }
}
