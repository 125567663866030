import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function IsOnline({ state }) {
  const { t } = useTranslation()

  return state ? (
    <Typography variant="caption" color="success.main">
        {t("online")}
    </Typography>
  ) : (
    <Typography variant="caption" color="error.main">
        {t("offline")}
    </Typography>
  )
}