import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function sendDeviceCommand(data) {
  return client.apis.MessengerService.MessengerService_DeviceCommand({ body: data })
}

export async function sendLocationCommand(data) {
  return client.apis.MessengerService.MessengerService_LocationCommand({ body: data })
}

export async function SendDownlink(data) {
  return client.apis.MessengerService.MessengerService_SendDownlink({ body: data })
}

export async function listMessageTypes(filters) {
  return client.apis.MessengerService.MessengerService_ListMessageTypes(filters)
}
