import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import useLocations from "../pages/Locations/hooks/useLocations"

export default function LocationSelector({ locationId }) {
  const props = useLocations()
  const { loading, data } = props

  return (
    <FormControl required fullWidth>
      <InputLabel>{locationId.label}</InputLabel>
      <Select
        id="locationId"
        disabled={loading}
        label={locationId.label}
        error={locationId.error}
        value={locationId.value}
        onChange={locationId.onChange}
        onBlur={locationId.onBlur}
      >
        {data.map((item, index) => (
          <MenuItem id={item.id} key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{locationId.helperText}</FormHelperText>
    </FormControl>
  )
}
