import { configureStore } from "@reduxjs/toolkit"
import { authReducer } from "./slices/authSlice"
import { organizationReducer } from "./slices/organizationSlice"
import { notificationReducer } from "./slices/notificationSlice"
import { toggleColorModeReducer } from "./slices/toggleColorModeSlice"
import { toggleMenuModeReducer } from "./slices/toggleMenuModeSlice"
import { confirmDialogReducer } from "./slices/confirmDialogSlice"
import { loadingReducer } from "./slices/loadingSlice"
import { alertReducer } from "./slices/alertSlice"
import { toggleSilentModeReducer } from "./slices/toggleSilentModeSlice"
import { rowsPerPageReducer } from "./slices/rowsPerPageSlice"
import { timeoutReducer } from "./slices/relayTimeout"

const store = configureStore({
  reducer: {
    toggleSilentModeReducer,
    notificationReducer,
    confirmDialogReducer,
    organizationReducer,
    authReducer,
    toggleColorModeReducer,
    toggleMenuModeReducer,
    loadingReducer,
    alertReducer,
    rowsPerPageReducer,
    timeoutReducer
  },
})

export default store
