import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function getIntegration(id) {
  return client.apis.IntegrationService.IntegrationService_Get({ id })
}

export async function createIntegration(data) {
  return client.apis.IntegrationService.IntegrationService_Create({ body: data })
}

export async function updateIntegration(integration) {
  return client.apis.IntegrationService.IntegrationService_Update({ body: { integration } })
}

export async function listIntegrations(filters) {
  return client.apis.IntegrationService.IntegrationService_List(filters)
}

export async function deleteIntegration(id) {
  return client.apis.IntegrationService.IntegrationService_Delete({ id })
}
