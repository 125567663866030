export default function passwordGenerator(uppercase = 2, lowercase = 2, numbers = 2, chars = 2, length = 13) {
  const abc = {
    uppercase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    lowercase: "abcdefghijklmnopqrstuvwxyz",
    numbers: "0123456789",
    // chars: ".:,;?!@#$%^&*_-+=",
    chars: "-_+=#@&^()*/><{}?!|`~,.",
  }

  let password = ""

  while (password.length < length) {
    if (uppercase) {
      for (let i = 0; i < uppercase; i++) {
        password += abc.uppercase[Math.floor(Math.random() * abc.uppercase.length)]
      }
    }
    if (lowercase) {
      for (let i = 0; i < lowercase; i++) {
        password += abc.lowercase[Math.floor(Math.random() * abc.lowercase.length)]
      }
    }
    if (numbers) {
      for (let i = 0; i < numbers; i++) {
        password += abc.numbers[Math.floor(Math.random() * abc.numbers.length)]
      }
    }
    if (chars) {
      for (let i = 0; i < chars; i++) {
        password += abc.chars[Math.floor(Math.random() * abc.chars.length)]
      }
    }
  }

  return [...password].sort(() => Math.random() - 0.5).join("")
}
