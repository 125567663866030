import { Breadcrumbs } from "@mui/material"
import { useMatches } from "react-router-dom"
import Crumb from "./Crumb"

export default function PathBreadcrumbs() {
  const crumbs = useMatches().filter((match) => Boolean(match?.handle?.crumb))

  return (
      <Breadcrumbs separator="›" sx={{ paddingX: "10px" }}>
        {crumbs.map((crumb, index) => (
          <Crumb key={index} crumb={crumb} isLast={index + 1 === crumbs.length}  />
        ))}
      </Breadcrumbs>
  )
}
