import { LinearProgress } from "@mui/material"
import NoData from "../../components/NoData"
import MarkersOnMap from "./components/MarkersOnMap"
import { useParams } from "react-router-dom"
import useFetchDevices from "../../hooks/useFetchDevices"

export default function DeviceMap({ locationId, search }) {
  const { organizationId } = useParams()
  const { data, loading } = useFetchDevices({ locationId, search, organizationId, limit: 9999 })

  return (
    <>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && <MarkersOnMap withPopup {...{ loading, data }} />}
      {loading && <LinearProgress />}
    </>
  )
}
