import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Grid2 as Grid, TextField, Typography } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import TimeZoneSelector from "../../components/TimeZoneSelector"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import useOrganizationCard from "./hooks/useOrganizationCard"

export default function OrganizationCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    organizationId,
    name,
    displayName,
    devicesMaxAllowed,
    timezone,
    createdAt,
    updatedAt,
    handleCreateOrganization,
    handleUpdateOrganization,
    handleDeleteOrganization,
    loading,
  } = useOrganizationCard()

  const isValid = [name.isValid, devicesMaxAllowed.isValid, timezone.isValid].every(Boolean)
  const isChanged = [name.isChanged, displayName.isChanged, timezone.isChanged, devicesMaxAllowed.isChanged].some(Boolean)
  const isDisabled = loading || !isValid || !isChanged

  return (
    <ItemCardLayout
      state={loading}
      actions={
        organizationId && (
          <Grid>
            <Button
              disabled={loading}
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(
                  showConfirmDialog({
                    title: t("You can't undo this operation"),
                    message: t("Are you sure to delete this organization?"),
                    onConfirm: () => {
                      handleDeleteOrganization(organizationId)
                    },
                  })
                )
              }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </Grid>
        )
      }
    >
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" py={1}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextField
            id="name"
            value={name.value}
            onChange={name.onChange}
            onBlur={name.onBlur}
            label={name.label}
            helperText={name.helperText}
            error={name.error}
            disabled={loading}
            fullWidth
            required
            variant="outlined"
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <TextField
            id="devicesMaxAllowed"
            fullWidth
            disabled={loading}
            value={devicesMaxAllowed.value}
            onChange={devicesMaxAllowed.onChange}
            label={devicesMaxAllowed.label}
            helperText={devicesMaxAllowed.value === 0 ? t("Unlimited") : devicesMaxAllowed.helperText}
            variant="outlined"
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <TimeZoneSelector {...{ timezone, loading }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 12 }}>
          <TextField
            id="displayName"
            value={displayName.value}
            onChange={displayName.onChange}
            onBlur={displayName.onBlur}
            label={displayName.label}
            helperText={displayName.helperText}
            error={displayName.error}
            disabled={loading}
            fullWidth
            required
            variant="outlined"
          />
        </Grid>
        {(createdAt || updatedAt) && (
          <Grid size={{ xs: 12 }}>
            <Typography color="text.secondary">
              {t("Created at")}: {moment(createdAt).format("lll")}
            </Typography>
            <Typography color="text.secondary">
              {t("Updated at")}: {moment(updatedAt).format("lll")}
            </Typography>
          </Grid>
        )}
        <Grid size={{ xs: 12 }}>
          {organizationId ? (
            <Button disabled={isDisabled} onClick={handleUpdateOrganization} variant="contained">
              {t("Apply")}
            </Button>
          ) : (
            <Button disabled={isDisabled} onClick={handleCreateOrganization} variant="contained">
              {t("Create")}
            </Button>
          )}
        </Grid>
      </Grid>
    </ItemCardLayout>
  )
}
