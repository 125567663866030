import { Button, ButtonGroup } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { showConfirmDialog } from "../../../slices/confirmDialogSlice"
import useDeviceCmdSender from "../hooks/useDeviceCmdSender"
import { addRelay, removeRelay } from "../../../slices/relayTimeout"

export default function DeviceRelaySwitcher({ deviceId, locationId, organizationId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sendCmd, pending } = useDeviceCmdSender(deviceId, organizationId)
  const { relayTimeouts, locationsTimeouts } = useSelector((state) => state.timeoutReducer)
  
  function relayOn() {
    dispatch(
      showConfirmDialog({
        title: t("Attention!"),
        message: t("Are you sure you want to turn relay ON on this device?"),
        onConfirm: () => {
          sendCmd("RELAY_ON")
          dispatch(addRelay(deviceId))
          setTimeout(() => dispatch(removeRelay(deviceId)), 20e3)
        },
      })
    )
  }

  function relayOff() {
    dispatch(
      showConfirmDialog({
        title: t("Attention!"),
        message: t("Are you sure you want to turn relay OFF on this device?"),
        onConfirm: () => {
          sendCmd("RELAY_OFF")
          dispatch(addRelay(deviceId))
          setTimeout(() => dispatch(removeRelay(deviceId)), 20e3)
        },
      })
    )
  }

  return (
    <ButtonGroup variant="contained">
      <Button
        sx={{ width: 128 }}
        disabled={pending || relayTimeouts.includes(deviceId) || locationsTimeouts.includes(locationId)}
        color="success"
        onClick={relayOn}
      >
        {t("Turn on")}
      </Button>
      <Button
        sx={{ width: 128 }}
        disabled={pending || relayTimeouts.includes(deviceId) || locationsTimeouts.includes(locationId)}
        color="error"
        onClick={relayOff}
      >
        {t("Turn off")}
      </Button>
    </ButtonGroup>
  )
}
