import { Link, TableCell, TableRow, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import IsOnline from "../../../components/IsOnline"

export default function DeviceInfoRow({ caption, data, isOnline, status }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {status ? (
        <TableRow>
          <TableCell>
            <Typography variant="h6">{t(caption)}</Typography>
          </TableCell>
          <TableCell align="right">
            <IsOnline state={isOnline} />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="h6">{t(caption)}</Typography>
          </TableCell>
        </TableRow>
      )}

      {data.map((dataItem, dataIndex) => {
        const unit = dataItem.unit ? " " + t(`units.${dataItem.unit}`) : ""
        return (
          <TableRow key={dataIndex}>
            <TableCell>{`${t(dataItem.name)}:`}</TableCell>
            <TableCell>{dataItem.component ? dataItem.component(dataItem.value) : t(dataItem.value) + unit}</TableCell>
          </TableRow>
        )
      })}
    </>
  )
}
