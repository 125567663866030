import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { sendDeviceCommand } from "../../../services/messenger"
import { showNotification } from "../../../slices/notificationSlice"

export default function useDeviceCmdSender(id, organizationId) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)
  const responseHandler = useResponseHandler()

  function sendCmd(command) {
    setPending(true)
    sendDeviceCommand({ id, command, organizationId })
      .then(() => dispatch(showNotification({ message: t("Command sent"), type: "success" })))
      .catch(responseHandler)
      .finally(() => {
        setPending(false)
      })
  }

  return { sendCmd, pending }
}
