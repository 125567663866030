import L from "leaflet"
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet"
import RecenterAutomatically from "./RecenterAutomatically"
import { Skeleton } from "@mui/material"

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
const defaultPosition = [55.753298, 37.621938]
let coordinates = defaultPosition

export default function MarkerPickerMap({ latitude, longitude, loading }) {
  const size = { width: "100wh", height: "600px" }

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        const lat = Math.trunc(e.latlng.lat * 1000000) / 1000000
        const lon = Math.trunc(e.latlng.lng * 1000000) / 1000000
        latitude.setValue(lat)
        longitude.setValue(lon)
      },
    })
    return false
  }

  if (!isNaN(latitude.value) && !isNaN(longitude.value)) {
    coordinates = [latitude.value, longitude.value]
  }

  try {
    return loading ? (
      <Skeleton variant="rounded" width={size.width} height={size.height} animation="wave" />
    ) : (
      <MapContainer attributionControl={false} style={size} center={coordinates} zoom={13} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={coordinates}></Marker>
        <RecenterAutomatically position={coordinates} />
        <MapEvents />
      </MapContainer>
    )
  } catch {}
}
