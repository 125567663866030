import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import useFetchIntegrations from "../pages/Integrations/hooks/useFetchIntegrations"

export default function IntegrationSelector({ integrationId }) {
  const { data, loading } = useFetchIntegrations({limit: 100})
  const { label, onChange, value, helperText } = integrationId

  return (
    <FormControl required fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select id="integration" disabled={loading} value={value} onChange={onChange} label={label}>
        {data.map((item, index) => (
          <MenuItem id={item.id} key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
