import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const PublicRoute = ({ children }) => {
  const user = useSelector((state) => state.authReducer?.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true })
    }
  }, [user, navigate])
  return !user ? children : null 
}

export default PublicRoute
