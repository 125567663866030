import { Navigate, createHashRouter } from "react-router-dom"
import App from "./App"
import APIKeys from "./pages/APIKeys"
import APIKeyCard from "./pages/APIKeys/APIKeyCard"
import AllUsers from "./pages/AllUsers"
import Create from "./pages/AllUsers/Create"
import AllUserCard from "./pages/AllUsers/UserCard"
import Dashboard from "./pages/Dashboard"
import DeviceTypes from "./pages/DeviceTypes"
import DeviceTypeCard from "./pages/DeviceTypes/DeviceTypeCard"
import Devices from "./pages/Devices"
import DeviceCard from "./pages/Devices/DeviceCard"
import DeviceInfo from "./pages/Devices/DeviceInfo"
import Diagnostics from "./pages/Diagnostics"
import ErrorPage from "./pages/ErrorPage"
import Events from "./pages/Events"
import Info from "./pages/Info"
import Integrations from "./pages/Integrations"
import IntegrationCard from "./pages/Integrations/IntegrationCard"
import Locations from "./pages/Locations"
import LocationCard from "./pages/Locations/LocationCard"
import LocationInfo from "./pages/Locations/LocationInfo"
import Login from "./pages/Login"
import Logs from "./pages/Logs"
import OrganizationUsers from "./pages/OrganizationUsers"
import AddUser from "./pages/OrganizationUsers/AddUser"
import UserCard from "./pages/OrganizationUsers/UserCard"
import Organizations from "./pages/Organizations"
import OrganizationCard from "./pages/Organizations/OrganizationCard"
import Password from "./pages/Password"
import PrivateRoute from "./pages/PrivateRoute"
import Profile from "./pages/Profile"
import PublicRoute from "./pages/PublicRoute"
import Reports from "./pages/Reports"
import Schedules from "./pages/Schedules"
import ScheduleCard from "./pages/Schedules/ScheduleCard"
import Search from "./pages/Search"

import ArticleIcon from "@mui/icons-material/Article"
import BusinessIcon from "@mui/icons-material/Business"
import CableIcon from '@mui/icons-material/Cable'
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import ConstructionIcon from "@mui/icons-material/Construction"
import DataUsageIcon from '@mui/icons-material/DataUsage'
import EditIcon from "@mui/icons-material/Edit"
import EventNoteIcon from "@mui/icons-material/EventNote"
import InfoIcon from "@mui/icons-material/Info"
import KeyIcon from "@mui/icons-material/Key"
import LockIcon from "@mui/icons-material/Lock"
import MapIcon from "@mui/icons-material/Map"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import NoteAddIcon from "@mui/icons-material/NoteAdd"
import PeopleIcon from "@mui/icons-material/People"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import SearchIcon from "@mui/icons-material/Search"
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote"

import { getDevice, getDeviceType } from "./services/device"
import { getAPIKey } from "./services/duty"
import { getIntegration } from "./services/integration"
import { getLocation } from "./services/location"
import { getOrganization, getOrganizationUser } from "./services/organization"
import { getUser } from "./services/user"

async function getOrganizationName({ params }) {
  const { organizationId } = params
  const { body } = await getOrganization(organizationId)
  return body.organization.name
}

async function getDeviceName({ params }) {
  const { organizationId, deviceId } = params
  const { body } = await getDevice(deviceId, organizationId)
  return body.device.name
}

async function getIntegrationName({ params }) {
  const { integrationId } = params
  const { body } = await getIntegration(integrationId)
  return body.integration.name
}

async function getDeviceTypeName({ params }) {
  const { deviceTypeId } = params
  const { body } = await getDeviceType(deviceTypeId)
  return body.deviceType.name
}

async function getLocationName({ params }) {
  const { locationId, organizationId } = params
  const { body } = await getLocation(locationId, organizationId)
  return body.location.name
}

async function getAllUserEmail({ params }) {
  const { userId, organizationId } = params
  const { body } = await getUser(userId, organizationId)
  return body.user.email
}

async function getUserEmail({ params }) {
  const { userId, organizationId } = params
  const { body } = await getOrganizationUser(organizationId, userId)
  return body.organizationUser.email
}


async function getAPIKeyName({ params }) {
  const { APIKeyId, organizationId } = params
  const { body } = await getAPIKey(APIKeyId, organizationId)
  return body.apiKey.name
}

const routerPaths = {
  homeRedirect: { index: true, element: <Navigate to="organizations" replace /> },
  organizationHomeRedirect: { index: true, element: <Navigate to="dashboard" replace /> },
  dashboard: { path: "dashboard", element: <Dashboard />, handle: { crumb: { text: "Dashboard", Icon: DataUsageIcon } } },
  organizations: {
    path: "organizations",
    handle: { crumb: { text: "Organizations", Icon: BusinessIcon } },
    children: [
      { index: true, element: <Organizations /> },
      {
        path: ":organizationId/edit",
        element: <OrganizationCard />,
        loader: getOrganizationName,
        handle: { crumb: { text: "Editing", Icon: EditIcon } },
      },
      { path: "create", element: <OrganizationCard />, handle: { crumb: { text: "Creating a new organization", Icon: NoteAddIcon } } },
    ],
  },
  allUsers: {
    path: "users",
    handle: { crumb: { text: "All Users", Icon: PeopleIcon } },
    children: [
      { index: true, element: <AllUsers /> },
      { path: "create", element: <Create />, handle: { crumb: { text: "Creating a new user", Icon: NoteAddIcon } } },
      { path: ":userId/edit", element: <AllUserCard />, loader: getAllUserEmail, handle: { crumb: { text: "Editing", Icon: EditIcon } } },
    ],
  },
  users: {
    path: "users",
    handle: { crumb: { text: "Users", Icon: PeopleIcon } },
    children: [
      { index: true, element: <OrganizationUsers /> },
      { path: "add", element: <AddUser />, handle: { crumb: { text: "Adding an user to organization", Icon: NoteAddIcon } } },
      { path: ":userId/edit", element: <UserCard />, loader: getUserEmail, handle: { crumb: { text: "Editing", Icon: EditIcon } } },
    ],
  },
  apiKeys: {
    path: "api-keys",
    handle: { crumb: { text: "API Keys", Icon: KeyIcon } },
    children: [
      { index: true, element: <APIKeys /> },
      { path: "create", element: <APIKeyCard />, handle: { crumb: { text: "Creating a new API key", Icon: NoteAddIcon } } },
      { path: ":APIKeyId/edit", element: <APIKeyCard />, loader: getAPIKeyName, handle: { crumb: { text: "Editing", Icon: EditIcon } } },
    ],
  },
  devices: {
    path: "devices",
    handle: { crumb: { text: "Devices", Icon: SettingsRemoteIcon } },
    children: [
      { index: true, element: <Devices /> },
      { path: "create", element: <DeviceCard />, handle: { crumb: { text: "Creating a new device", Icon: NoteAddIcon } } },
      {
        path: ":deviceId",
        loader: getDeviceName,
        handle: { crumb: { Icon: ArticleIcon } },
        children: [
          { index: true, element: <DeviceInfo /> },
          { path: "edit", element: <DeviceCard />, handle: { crumb: { text: "Editing", Icon: EditIcon } } },
        ],
      },
    ],
  },
  integrations: {
    path: "integrations",
    handle: { crumb: { text: "Integrations", Icon: CableIcon } },
    children: [
      { index: true, element: <Integrations /> },
      { path: "create", element: <IntegrationCard />, handle: { crumb: { text: "Creating a new integration", Icon: NoteAddIcon } } },
      {
        path: ":integrationId/edit",
        element: <IntegrationCard />,
        loader: getIntegrationName,
        handle: { crumb: { Icon: EditIcon } },
      },
    ],
  },
  deviceTypes: {
    path: "device-types",
    handle: { crumb: { text: "Device Types", Icon: SettingsRemoteIcon } },
    children: [
      { index: true, element: <DeviceTypes /> },
      { path: "create", element: <DeviceTypeCard />, handle: { crumb: { text: "Creating a device type", Icon: NoteAddIcon } } },
      {
        path: ":deviceTypeId/edit",
        element: <DeviceTypeCard />,
        loader: getDeviceTypeName,
        handle: { crumb: { Icon: EditIcon } },
      },
    ],
  },
  locations: {
    path: "locations",
    handle: { crumb: { text: "Locations", Icon: MapIcon } },
    children: [
      { index: true, element: <Locations /> },
      { path: "create", element: <LocationCard />, handle: { crumb: { text: "Creating a new location", Icon: NoteAddIcon } } },
      {
        path: ":locationId",
        loader: getLocationName,
        handle: { crumb: { Icon: ArticleIcon } },
        children: [
          { index: true, element: <LocationInfo /> },
          { path: "edit", element: <LocationCard />, handle: { crumb: { text: "Editing", Icon: EditIcon } } },
        ],
      },
    ],
  },
  schedules: {
    path: "schedules",
    handle: { crumb: { text: "Schedules", Icon: CalendarMonthIcon } },
    children: [
      { index: true, element: <Schedules /> },
      { path: "create", element: <ScheduleCard />, handle: { crumb: { text: "Creating a new schedule", Icon: NoteAddIcon } } },
      { path: ":scheduleId/edit", element: <ScheduleCard />, handle: { crumb: { text: "Editing", Icon: EditIcon } } },
    ],
  },
  search: { path: "search", element: <Search />, handle: { crumb: { text: "Search", Icon: SearchIcon } } },
  events: { path: "events", element: <Events />, handle: { crumb: { text: "Events", Icon: EventNoteIcon } } },
  reports: { path: "reports", element: <Reports />, handle: { crumb: { text: "Reports", Icon: ReceiptLongIcon } } },
  logs: { path: "logs", element: <Logs />, handle: { crumb: { text: "Logs", Icon: MenuBookIcon } } },
  password: { path: "passwd", element: <Password />, handle: { crumb: { text: "Change password", Icon: LockIcon } } },
  profile: { path: "profile", element: <Profile /> },
  info: { path: "info", element: <Info />, handle: { crumb: { text: "Info", Icon: InfoIcon } } },
  diagnostics: {
    path: "diagnostics",
    element: <Diagnostics />,
    handle: { crumb: { text: "Diagnostics", Icon: ConstructionIcon } },
    children: [{ path: ":devEUI", element: <Diagnostics />, handle: { crumb: { text: "Device", Icon: SettingsRemoteIcon } } }],
  },
}

const router = createHashRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      routerPaths.homeRedirect,
      routerPaths.dashboard,
      routerPaths.organizations,
      routerPaths.allUsers,
      routerPaths.apiKeys,
      routerPaths.integrations,
      routerPaths.deviceTypes,
      routerPaths.schedules,
      routerPaths.password,
      routerPaths.profile,
      routerPaths.logs,
      routerPaths.diagnostics,
      routerPaths.search,
      routerPaths.info,
    ],
  },
  {
    path: "/organization/:organizationId",
    element: (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    loader: getOrganizationName,
    handle: { crumb: { Icon: BusinessIcon } },
    errorElement: <ErrorPage />,
    children: [
      routerPaths.organizationHomeRedirect,
      routerPaths.dashboard,
      routerPaths.users,
      routerPaths.apiKeys,
      routerPaths.devices,
      routerPaths.locations,
      routerPaths.schedules,
      routerPaths.events,
      routerPaths.reports,
      routerPaths.logs,
    ],
  },
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
])

export default router
