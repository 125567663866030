import { Tooltip, Typography } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"

export default function Time() {
  const [currentTime, setCurrentTime] = useState("")
  const [currentDate, setCurrentDate] = useState("")

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("LTS"))
      setCurrentDate(moment().format("dddd, D MMMM YYYY"))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Tooltip title={currentDate}>
      <Typography mx={2} sx={{ cursor: "default" }}>{currentTime}</Typography>
    </Tooltip>
  )
}
