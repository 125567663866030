export const baseValidation = { minLength: 2, isEmptyCheck: true }
// export const emailValidation = { regexp: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/, ...baseValidation }
export const emailValidation = { regexp: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/, isEmptyCheck: true }
export const latitudeValidation = { regexp: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/ }
export const longitudeValidation = { regexp: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/ }
export const nameValidation = { noSpaces: true, ...baseValidation }
export const passwordValidation = {
  minLength: 13,
  minSpecialChar: 2,
  minDigits: 2,
  minUpperCase: 2,
  minLowerCase: 2,
  isEmptyCheck: true,
}
