import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { hideConfirmDialog } from "../slices/confirmDialogSlice"

export default function ConfirmDialog() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, title, message, onConfirm } = useSelector((state) => state.confirmDialogReducer)

  return (
    <Dialog open={isOpen} onClose={() => dispatch(hideConfirmDialog())}>
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button variant="outlined"
        onClick={() => dispatch(hideConfirmDialog())}>{t("Cancel")}</Button>
        <Button
        variant="contained"
          autoFocus
          onClick={() => {
            onConfirm()
            dispatch(hideConfirmDialog())
          }}>
          {t("OK")}
        </Button>
      
      </DialogActions>
    </Dialog>
  )
}
