import { useState } from "react"
import { Box, IconButton, TextField } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"

export default function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme()
  const [manualPage, setManualPage] = useState(Number(page + 1))
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage))
  const timelag = (f) => setTimeout(f, 1000)

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
    setManualPage(1)
  }

  const handleBackButtonClick = (event) => {
    const prevPage = page - 1
    onPageChange(event, prevPage)
    setManualPage(prevPage + 1)
  }

  const handleNextButtonClick = (event) => {
    const nextPage = page + 1
    onPageChange(event, nextPage)
    setManualPage(nextPage + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, lastPage - 1)
    setManualPage(lastPage)
  }

  const handleChangePage = (event) => {
    const value = event.target.value
    if (0 < value && value <= lastPage) {
      onPageChange(event, value - 1)
    } else {
      setManualPage(page + 1)
    }
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <TextField
        id="manualPage"
        sx={{ width: 80, input: { textAlign: "center" } }}
        size="small"
        value={manualPage}
        onBlur={handleChangePage}
        onChange={(event) => {
          setManualPage(event.target.value)
          timelag(() => handleChangePage(event))
        }}
      />
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}
