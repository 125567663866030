import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { listIntegrations } from "../../../services/integration"

export default function useFetchIntegrations(props) {
  const {limit, search, offset, order, orderBy} = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer  ) 
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()

  useEffect(() => {
    getData(search, limit || rowsPerPages, offset, order, orderBy)
  }, [search, rowsPerPages, offset, order, orderBy])

  const getData = (search, limit, offset, order, orderBy) => {
    setLoading(true)
    listIntegrations({ search, limit, offset, order, orderBy })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { loading, data, totalCount }
}
