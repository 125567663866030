import { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { getListLogs } from "../../../services/user"

export default function useFetchLogs(props) {
  const { organizationId, isRunning, search, order, orderBy, offset } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()



  useEffect(() => {
    getData(search, rowsPerPages, offset, order, orderBy, organizationId)
    if (isRunning) {
      const interval = setInterval(() => {
        getData(search, rowsPerPages, offset, order, orderBy, organizationId)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [search, rowsPerPages, offset, order, orderBy, organizationId, isRunning])

  const getData = (search, limit, offset, order, orderBy, organizationId) => {
    setLoading(true)
    getListLogs({ search, limit, offset, order, orderBy, organizationId })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, totalCount }
}
