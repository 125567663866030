import { Box, Grid2 as Grid, Tab, Tabs } from "@mui/material"
import { useTranslation } from "react-i18next"

import ItemCardLayout from "../../layouts/ItemCardLayout"
import ExistUser from "./components/ExistUser"
import NewUser from "./components/NewUser"
import useOrganizationUser from "./hooks/useOrganizationUser"

export default function AddUser() {
  const props = useOrganizationUser()
  const { loading, selectedTab, setSelectedTab } = props

  const { t } = useTranslation()
 

  return (
    <>
      <ItemCardLayout title={t("Add user")} state={loading}>
        <Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid size={{ xs: 12}}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={selectedTab}
                  onChange={(event, newValue) => {
                    setSelectedTab(newValue)
                  }}>
                  <Tab label={t("Exist")} value="Exist" />
                  <Tab label={t("New")} value="New" />
                </Tabs>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {selectedTab === "Exist" && <ExistUser {...props} />}
        {selectedTab === "New" && <NewUser {...props} />}
      </ItemCardLayout>
    </>
  )
}
