import { Checkbox, FormControlLabel, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import { useTranslation } from "react-i18next"

export default function TextFieldWithDefault(props) {
  const { t } = useTranslation()
  const { defaultValue, setValue, ...otherProps } = props
  const isDefault = String(props.value) === defaultValue
  const adornmentPosition = isDefault ? "startAdornment" : "endAdornment"
  const inputAdornmentPosition = isDefault ? "end" : "start"

  return (
    <TextField
      {...otherProps}
      disabled={isDefault}
      value={isDefault ? "" : props.value}
      variant="outlined"
      required
      fullWidth
      InputProps={{
        [adornmentPosition]: (
          <InputAdornment position={inputAdornmentPosition}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDefault}
                  onChange={(event) => {
                    setValue(event.target.checked ? defaultValue : "")
                  }}
                />
              }
              label={t("Default")}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}
