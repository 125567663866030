import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  Button,
  Paper,
  TextField,
  Grid2 as Grid,
  Typography,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { showNotification } from "../../slices/notificationSlice"
import { useEffect, useState } from "react"
import { createSchedule, deleteSchedule, getSchedule, updateSchedule } from "../../services/schedule"
import useResponseHandler from "../../hooks/useResponseHandler"
import { useNavigate, useParams } from "react-router-dom"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import Week from "./components/Week"
import Year from "./components/Year"
import TimePickerComponent from "./components/TimePickerComponent"
import Switch from "@mui/material/Switch"
import ItemCardLayout from "../../layouts/ItemCardLayout"

export default function ScheduleCard() {
  const { t } = useTranslation()
  const { scheduleId } = useParams()
  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [daysOfWeek, setDaysOfWeek] = useState("0000000")
  const [months, setMonths] = useState("000000000000")
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [loading, setLoading] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const responseHandler = useResponseHandler()
  const navigate = useNavigate()

  useEffect(() => {
    if (scheduleId) {
      getData(scheduleId)
    }
  }, [])

  const getData = (scheduleId) => {
    setLoading(true)
    getSchedule(scheduleId)
      .then((response) => {
        const { schedule } = response.body
        setName(schedule.name)
        setDaysOfWeek(schedule.daysOfWeek)
        setMonths(schedule.months)
        setStartTime(schedule.startTime)
        setEndTime(schedule.endTime)
        setIsActive(schedule.isActive)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateSchedule = () => {
    setLoading(true)
    createSchedule({ schedule: { name, daysOfWeek, months, startTime, endTime } })
      .then((response) => {
        dispatch(showNotification({ message: t("Created successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateSchedule = () => {
    updateSchedule({ schedule: { id: scheduleId, name, daysOfWeek, months, startTime, endTime } })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteSchedule = (id) => {
    deleteSchedule(id)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return (
    <ItemCardLayout
      title={scheduleId ? t("Edit schedule") : t("Create schedule")}
      state={loading}
      actions={
        scheduleId && (
          <Grid>
            <Button
              disabled={loading}
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(
                  showConfirmDialog({
                    title: t("You can't undo this operation"),
                    message: t("Are you sure to delete this schedule?"),
                    onConfirm: () => {
                      handleDeleteSchedule(scheduleId)
                    },
                  })
                )
              }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </Grid>
        )
      }
    >
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" py={2}>
        <Grid size={{ xs: 12 }}>
          <TextField
            id="name"
            disabled={loading}
            fullWidth
            value={name}
            onChange={(event) => {
              setName(event.target.value)
            }}
            label={t("Schedule Name")}
            variant="outlined"
            helperText={t("The name may only contain words, numbers and dashes.")}
          />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <TimePickerComponent value={startTime} setValue={setStartTime} label={t("Start")} />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <TimePickerComponent value={endTime} setValue={setEndTime} label={t("End")} />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <FormControl fullWidth>
            <InputLabel>{t("Schedule")}</InputLabel>
            <Select
            id="schedule"
              value="1"
              // value={connectionType}
              label={t("Schedule")}
              // onChange={(event) => {
              //   setConnectionType(event.target.value)
              // }}
            >
              <MenuItem id="1" value="1">1</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Year {...{ months, setMonths }} />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Week {...{ daysOfWeek, setDaysOfWeek }} />
        </Grid>
        <Grid>
          {scheduleId ? (
            <Button disabled={loading} onClick={handleUpdateSchedule} variant="contained">
              {t("Apply")}
            </Button>
          ) : (
            <Button disabled={loading} onClick={handleCreateSchedule} variant="contained">
              {t("Create")}
            </Button>
          )}
        </Grid>
      </Grid>
    </ItemCardLayout>
  )
}
