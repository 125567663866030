import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { listEvents } from "../../../services/event"

export default function useFetchEvents(props) {
  const {
    deviceId,
    endTime,
    isFailureOnly,
    isRunning,
    limit,
    locationId,
    offset,
    order,
    orderBy,
    organizationId,
    search,
    startTime,
    userId,
  } = props

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()

  useEffect(() => {
    const filters = {
      organizationId,
      userId,
      locationId,
      type: isFailureOnly ? 3 : null,
      deviceId,
      search,
      limit: rowsPerPages || limit,
      offset,
      order,
      orderBy,
      startTime: startTime && startTime.toISOString(),
      endTime: endTime && endTime.toISOString(),
    }

    getData(filters)
    if (isRunning) {
      const interval = setInterval(() => {
        getData(filters)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [
    organizationId,
    userId,
    locationId,
    isFailureOnly,
    deviceId,
    search,
    rowsPerPages,
    offset,
    order,
    orderBy,
    startTime,
    endTime,
    isRunning,
  ])

  const getData = (filters) => {
    setLoading(true)
    listEvents(filters)
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
        setTotalCount()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, totalCount }
}
