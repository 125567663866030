import { Box, Button, Card, Typography } from "@mui/material/"
import { useTranslation } from "react-i18next"
import { useNavigate, useRouteError } from "react-router-dom"

import { useDispatch } from "react-redux"
import backgroundVideo from "../assets/background.mp4"
import Header from "../components/Header"
import { logout } from "../slices/authSlice"
import { refuseOrganization } from "../slices/organizationSlice"

export default function ErrorPage() {
  const navigate = useNavigate()
  const { status, data } = useRouteError()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  function SubmitButton({ status }) {
    switch (status) {
      case 401:
        return (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(refuseOrganization())
              dispatch(logout())
              navigate("/login")
            }}>
            {t("Go to Login Page")}
          </Button>
        )
      default:
        return (
          <Button variant="contained" onClick={() => navigate("..")}>
            {t("Come back")}
          </Button>
        )
    }
  }

  return (
    <>
      <div className="videoWrapper">
        <video src={backgroundVideo} autoPlay muted></video>
      </div>
      <Header logo themeSwitcher languageSelector />
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Card sx={{ width: 500, padding: "20px" }}>
          <Typography align="center" color="primary" variant="h6" component="div">
            {t("Error") + " " + status}
          </Typography>
          <Box m={4}>{data}</Box>
          <SubmitButton {...{status}} />
        </Card>
      </Box>
    </>
  )
}
