import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { logout } from "../slices/authSlice"
import { showNotification } from "../slices/notificationSlice"
import { refuseOrganization } from "../slices/organizationSlice"

export default function useResponseHandler() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  function responseHandler({ status, response }) {
    if (response) {
      switch (status) {
        case 401:
          dispatch(showNotification({ message: t(response.body.message), type: "error" }))
          dispatch(refuseOrganization())
          dispatch(logout())
          break
        case 403:
          dispatch(showNotification({ message: t("Forbidden. Not enough permissions"), type: "error" }))
          break
        case 404:
          break
        case 409:
          dispatch(showNotification({ message: t("Conflict: Dependencies found, remove all children first"), type: "error" }))
          break
        default:
          dispatch(showNotification({ message: t(response.body.message), type: "error" }))
          break
      }
    }
  }

  return responseHandler
}
