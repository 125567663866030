import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation, nameValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createOrganization, deleteOrganization, getOrganization, updateOrganization } from "../../../services/organization"
import { startLoading, stopLoading } from "../../../slices/loadingSlice"
import { showNotification } from "../../../slices/notificationSlice"
import { refuseOrganization } from "../../../slices/organizationSlice"

export default function useOrganizationCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { organizationId } = useParams()
  const responseHandler = useResponseHandler()
  const navigate = useNavigate()
  const [loading, setLoading] = useState()

  const name = useInput({ label: t("Organization Name"), helperText: t("Unique name without spaces") }, nameValidation)
  const displayName = useInput({ label: t("Description") }, baseValidation)
  const currentTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezone = useInput({ init: currentTZ, label: t("Time Zone") }, { isEmptyCheck: true })
  const devicesMaxAllowed = useInput({ init: 0, label: t("Device Limit")}, { isNumber: true })
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")


  const getData = (organizationId) => {
    dispatch(startLoading())
    setLoading(true)
    getOrganization(organizationId)
      .then((response) => {
        const { organization, createdAt, updatedAt } = response.body
        name.set(organization.name)
        displayName.set(organization.displayName)
        devicesMaxAllowed.set(organization.devicesMaxAllowed)
        timezone.set(organization.timezone)
        setCreatedAt(createdAt)
        setUpdatedAt(updatedAt)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
        dispatch(stopLoading())
      })
  }

  const handleCreateOrganization = () => {
    setLoading(true)
    createOrganization({
      organization: {
        name: name.value,
        timezone: timezone.value,
        devicesMaxAllowed: devicesMaxAllowed.value || 0,
        displayName: displayName.value,
      },
    })
      .then((response) => {
        dispatch(showNotification({ message: t("Created successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateOrganization = () => {
    updateOrganization(organizationId, {
      id: organizationId,
      name: name.value,
      timezone: timezone.value,
      devicesMaxAllowed: devicesMaxAllowed.value || 0,
      displayName: displayName.value,
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteOrganization = (id) => {
    deleteOrganization(id)
      .then(() => {
        dispatch(refuseOrganization())
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  useEffect(() => {
    if (organizationId) {
      getData(organizationId)
    }
  }, [])

  return {
    organizationId,
    name,
    devicesMaxAllowed,
    timezone,
    displayName,
    createdAt,
    updatedAt,
    loading,
    handleCreateOrganization,
    handleUpdateOrganization,
    handleDeleteOrganization,
    // handleLimitChange,
  }
}
