import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function getOrganizations(filters) {
  return client.apis.OrganizationService.OrganizationService_List(filters)
}

export async function createOrganization(data) {
  return client.apis.OrganizationService.OrganizationService_Create({ body: data })
}

export async function getOrganization(organizationId) {
  return client.apis.OrganizationService.OrganizationService_Get({ organizationId })
}

export async function updateOrganization(organizationId, organization) {
  return client.apis.OrganizationService.OrganizationService_Update({ body: { organization, organizationId } })
}

export async function deleteOrganization(organizationId) {
  return client.apis.OrganizationService.OrganizationService_Delete({ organizationId })
}

export async function organizationListUsers(data) {
  return client.apis.OrganizationService.OrganizationService_ListUsers(data)
}

export async function organizationAddUser(organizationId, data) {
  return client.apis.OrganizationService.OrganizationService_AddUser({ organizationId, body: data })
}

export async function getOrganizationUser(organizationId, userId) {
  return client.apis.OrganizationService.OrganizationService_GetUser({ organizationId, userId })
}

export async function deleteOrganizationUser(organizationId, userId) {
  return client.apis.OrganizationService.OrganizationService_DeleteUser({ organizationId, userId })
}

export async function updateOrganizationUser(organizationId, organizationUser) {
  return client.apis.OrganizationService.OrganizationService_UpdateUser({ organizationId, body: { organizationUser } })
}
