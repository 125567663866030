import { Link } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import useResponseHandler from "../../../hooks/useResponseHandler"
import { globalSearch } from "../../../services/duty"

export default function useSearch() {
  const [searchParams] = useSearchParams()
  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer)
  const [offset, setOffset] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()
  const search = searchParams.get("query") || ""
  const navigate = useNavigate()

  const handleGoToItem = (item) => {
    const { itemId, organizationId, kind } = item
    if (organizationId !== 0 || organizationId !== "0") {
      switch (kind) {
        case "user":
          navigate(`/organization/${organizationId}/users/${itemId}/edit`)
          break
        case "organization":
          navigate(`/organization/${organizationId}/dashboard`)
          break
        case "location":
          navigate(`/organization/${organizationId}/locations/${itemId}`)
          break
        case "device":
          navigate(`/organization/${organizationId}/devices/${itemId}`)
          break
      }
    } else {
      switch (kind) {
        case "user":
          navigate(`/users/${itemId}/edit`)
          break
      }
    }
  }

  const cells = [
    {
      label: "Matches",
      name: "itemMatched",
      isUnorderable: true,
      component: (value, item) => (
        <Link sx={{ cursor: "pointer" }} onClick={() => handleGoToItem(item)}>
          {value}
        </Link>
      ),
    },
    { label: "Type", name: "kind", isUnorderable: true },
  ]

  useEffect(() => {
    getData(search, rowsPerPages, offset)
  }, [search, rowsPerPages, offset])

  function getData(search, limit, offset = 0) {
    if (search) {
      setLoading(true)
      globalSearch({ search, limit, offset })
        .then((response) => {
          setTotalCount(response.body.totalCount)
          setData(response.body.result)
          setLoading(false)
        })
        .catch(responseHandler)
    }
  }

  return { data, cells, loading, setLoading, offset, setOffset, page, setPage, totalCount }
}
