import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"
// import { login } from "../slices/loginSlice"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

export async function loginUser(credentials) {
  return client.apis.DutyService.DutyService_Login({ body: credentials })
}

export async function createAPIKey(data) {
  return client.apis.DutyService.DutyService_CreateAPIKey({ body: data })
}

export async function listAPIKeys(filters) {
  return client.apis.DutyService.DutyService_ListAPIKeys(filters)
}

export async function getAPIKey(id, organizationId) {
  return client.apis.DutyService.DutyService_GetAPIKey({ id, organizationId })
}

export async function deleteAPIKey(id, organizationId) {
  return client.apis.DutyService.DutyService_DeleteAPIKey({ id, organizationId })
}

export async function updateAPIKey(apiKey, organizationId) {
  return client.apis.DutyService.DutyService_UpdateAPIKey({ body: { apiKey, organizationId } })
}

export async function globalSearch({ search, limit, offset }) {
  return client.apis.DutyService.DutyService_GlobalSearch({ search, limit, offset })
}

export async function getDashboardCnt(organizationId) {
  return client.apis.DutyService.DutyService_MainDashBoard({organizationId})
}

export async function getInfo() {
  return client.apis.DutyService.DutyService_Info()
}