import { Button, ButtonGroup } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { showConfirmDialog } from "../../../slices/confirmDialogSlice"
import useLocationCmdSender from "../hooks/useLocationCmdSender"
import { addLocation, removeLocation } from "../../../slices/relayTimeout"

export default function LocationRelaySwitcher({ locationId, organizationId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sendCmd, pending } = useLocationCmdSender(locationId, organizationId)
  const { locationsTimeouts } = useSelector((state) => state.timeoutReducer)

  function relayOn() {
    dispatch(
      showConfirmDialog({
        title: t("Attention!"),
        message: t("Are you sure you want to turn relay ON on all devices in this location?"),
        onConfirm: () => {
          sendCmd("RELAY_ON")
          dispatch(addLocation(locationId))
          setTimeout(() => dispatch(removeLocation(locationId)), 20e3)
        },
      })
    )
  }

  function relayOff() {
    dispatch(
      showConfirmDialog({
        title: t("Attention!"),
        message: t("Are you sure you want to turn relay OFF on all devices in this location?"),
        onConfirm: () => {
          sendCmd("RELAY_OFF")
          dispatch(addLocation(locationId))
          setTimeout(() => dispatch(removeLocation(locationId)), 20e3)
        },
      })
    )
  }

  return (
    <ButtonGroup variant="contained">
      <Button sx={{ width: 128 }} disabled={pending || locationsTimeouts.includes(locationId)} color="success" onClick={relayOn}>
        {t("Turn on")}
      </Button>
      <Button sx={{ width: 128 }} disabled={pending || locationsTimeouts.includes(locationId)} color="error" onClick={relayOff}>
        {t("Turn off")}
      </Button>
    </ButtonGroup>
  )
}
