import { Autocomplete, TextField } from "@mui/material"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import useFetchLocations from "../hooks/useFetchLocations"


export default memo(function FilterByLocation({ organizationId, locationId, setLocationId, url }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { data, loading } = useFetchLocations({limit: 9999, organizationId})
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Autocomplete
      disabled={loading}
      onChange={(event, selectedLocation) => {
        setLocationId(selectedLocation?.id)
        if (url) {
          selectedLocation?.id ? searchParams.set("locationId", selectedLocation.id) : searchParams.delete("locationId")
          setSearchParams(searchParams)
        } else {
          navigate(".", { state: { ...state, selectedLocation } })
        }
      }}
      clearText={t("Clear")}
      openText={t("Open")}
      closeText={t("Close")}
      loadingText={t("Loading…")}
      noOptionsText={t("No options")}
      size="small"
      disablePortal
      getOptionLabel={(option) => option.name}
      options={data}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={(url ? data.find((item) => item.id === locationId) : state?.selectedLocation) || null}
      sx={{ width: 250 }}
      renderInput={(params) => <TextField {...params} label={t("Filter by Location")} />}
    />
  )
})
