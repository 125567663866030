import { Button, IconButton, LinearProgress, Link } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import NoData from "../../components/NoData"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchOrganizations from "./hooks/useFetchOrganizations"

import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"

export default function Organizations() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const { data, loading, totalCount } = useFetchOrganizations({ search, offset, order, orderBy})

  const toOrganizationLink = (value, item) => (
    <Link
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/organization/${item.id}`)
      }}>
      {value}
    </Link>
  )

  const toDevicesLink = (value, item) => (
    <Link
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/organization/${item.id}/devices`)
      }}>
      {value}
    </Link>
  )

  const toOrganizationEditButton = (value) => (
    <Private roleRequired="admin" organizationId={value}>
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          navigate(`${value}/edit`)
        }}>
        <EditIcon fontSize="small" />
      </IconButton>
    </Private>
  )

  const cells = [
    { label: "Organization Name", name: "name", component: toOrganizationLink },
    { label: "Description", name: "displayName", isUnorderable: true },
    { label: "Devices qty", name: "deviceCount", component: toDevicesLink },
    { name: "id", component: toOrganizationEditButton },
  ]

  return (
    <ContentLayout
      title="Organizations"
      actions={[
        <SearchBar {...{ search, setSearch }} />,
        <Private roleRequired="globalAdmin">
          <Button onClick={() => navigate("create")} variant="contained" startIcon={<AddIcon />}>
            {t("Create")}
          </Button>
        </Private>,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && <TableDataLayout {...{ data, cells, orderBy, setOrderBy, order, setOrder, setOffset, page, setPage, totalCount }} />}
      {loading && <LinearProgress />}
    </ContentLayout>
  )
}
