import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"

export default function TimeZoneSelector({ timezone, loading }) {
  const { t } = useTranslation()
  const timezones = moment.tz.zonesForCountry("RU", true)
  timezones.sort((a, b) => b.offset - a.offset)

  return (
    <FormControl required fullWidth>
      <InputLabel>{timezone.label}</InputLabel>
      <Select
        id="timezone"
        disabled={loading}
        label={timezone.label}
        error={timezone.error}
        value={timezone.value}
        onChange={timezone.onChange}
        onBlur={timezone.onBlur}
      >
        {timezones.map((item, index) => {
          let gmt = -item.offset / 60
          return (
            <MenuItem id={item.id} key={index} value={item.name}>
              {`(GMT${gmt >= 0 && "+"}${gmt}) ${t(item.name)}`}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{timezone.helperText}</FormHelperText>
    </FormControl>
  )
}
