import { LinearProgress } from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import CollapsibleCompareItem from "../../components/CollapsibleCompareItem"
import NoData from "../../components/NoData"
import SearchBar from "../../components/SearchBar"
import CollapsibleTableDataLayout from "../../layouts/CollapsibleTableDataLayout"
import ContentLayout from "../../layouts/ContentLayout"
import StateSwitcher from "../Diagnostics/components/StateSwitcher"
import useFetchLogs from "./hooks/useFetchLogs"

export default function Logs() {
  const { organizationId } = useParams()
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [isRunning, setIsRunning] = useState(true)

  const params = { organizationId, isRunning, search, order, orderBy, offset }
  const { data, loading, totalCount } = useFetchLogs(params)

  const cells = [
    { label: "#", name: "id", width: 10, isUnorderable: true },
    { label: "Event", name: "event", component: (value) => t(value) },
    { label: "User", name: "performerName" },
    { label: "Created at", name: "createdAt", component: (value) => moment(value).format("lll") },
  ]

  return (
    <ContentLayout
      actions={[<SearchBar {...{ search, setSearch }} />, <StateSwitcher {...{ isRunning, setIsRunning }} />]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <CollapsibleTableDataLayout
          {...{
            loading,
            data,
            totalCount,
            order,
            setOrder,
            orderBy,
            setOrderBy,
            offset,
            setOffset,
            cells,
            page,
            setPage,
          }}
          Item={CollapsibleCompareItem}
        />
      )}
      {loading && data.length === 0 && <LinearProgress />}
    </ContentLayout>
  )
}
