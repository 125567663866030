import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createIntegration, deleteIntegration, getIntegration, updateIntegration } from "../../../services/integration"
import { showNotification } from "../../../slices/notificationSlice"

export default function useIntegrationCard() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { integrationId } = useParams()
  const dispatch = useDispatch()
  const responseHandler = useResponseHandler()
  
  const name = useInput({ label: t("Integration name") }, baseValidation)
  const kind = useInput({ label: t("Kind"), init: "MQTT" }, baseValidation)
  const settings = useInput({ label: t("Settings"), init:"{}" }, {isEmptyCheck:true} )
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")

  useEffect(() => {
    if (integrationId) {
      getData(integrationId)
    }
  }, [])

  const getData = (integrationId) => {
    setLoading(true)
    getIntegration(integrationId)
      .then((response) => {
        const { integration, createdAt, updatedAt } = response.body
        name.set(integration.name)
        kind.set(integration.kind)
        settings.set(integration.settings)
        setCreatedAt(createdAt)
        setUpdatedAt(updatedAt)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateIntegration = () => {
    setLoading(true)
    createIntegration({
      integration: {
        name: name.value,
        kind: kind.value,
        settings: settings.value,

      },
    })
      .then(() => {
        dispatch(showNotification({ message: t("Created successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateIntegration = () => {
    updateIntegration({
      id: integrationId,
      name: name.value,
      kind: kind.value,
      settings: settings.value,

    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteIntegration = (id) => {
    deleteIntegration(id)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return {
    name,
    kind,
    settings,
    loading,
    integrationId,
    createdAt,
    updatedAt,
    handleCreateIntegration,
    handleUpdateIntegration,
    handleDeleteIntegration,
  }
}
