import { Paper, Table, TableBody, TableCell, TableRow } from "@mui/material"
import TableHeader from "../components/TableHeaderComponent"
import TableFooterComponent from "../components/TableFooterComponent"

export default function TableDataLayout({ data, cells, orderBy, setOrderBy, order, setOrder, setOffset, page, setPage, totalCount }) {
  return (
    <Paper variant="outlined">
      <Table size="small" sx={{ minWidth: 650 }}>
        <TableHeader {...{ cells, orderBy, setOrderBy, order, setOrder }} />
        <TableBody>
          {data.map((dataItem, dataIndex) => (
            <TableRow id={dataItem.id} hover key={dataIndex}>
              {cells.map((cellItem, cellIndex) => (
                <TableCell key={cellIndex} width={dataItem.width}>
                  {cellItem.component ? cellItem.component(dataItem[cellItem.name], dataItem) : dataItem[cellItem.name]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TableFooterComponent {...{ setOffset, page, setPage, totalCount }} />
    </Paper>
  )
}
