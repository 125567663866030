import AddIcon from "@mui/icons-material/Add"
import { Button, LinearProgress, Link } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import NoData from "../../components/NoData"
import Private from "../../components/Private"
import SearchBar from "../../components/SearchBar"
import ContentLayout from "../../layouts/ContentLayout"
import TableDataLayout from "../../layouts/TableDataLayout"
import useFetchIntegrations from "./hooks/useFetchIntegrations"
import { useState } from "react"

export default function Integrations() {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const { loading, data, totalCount } = useFetchIntegrations({search, offset, order, orderBy})
  const navigate = useNavigate()

  const toIntegrationCard = (value, item) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`${item.id}/edit`)}>
      {value}
    </Link>
  )

  const cells = [
    { label: "#", name: "id", width: 10 },
    { label: "Integration name", name: "name", component: toIntegrationCard },
    { label: "Kind", name: "kind" },
  ]

  return (
    <ContentLayout
      title="Integrations"
      actions={[
        <SearchBar {...{ search, setSearch }} />,
        <Private roleRequired="globalAdmin">
          <Button onClick={() => navigate("create")} variant="contained" startIcon={<AddIcon />}>
            {t("Create")}
          </Button>
        </Private>,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <TableDataLayout
          {...{ cells, loading, data, totalCount, page, setPage, offset, setOffset, order, setOrder, orderBy, setOrderBy }}
        />
      )}
      {loading && <LinearProgress />}
    </ContentLayout>
  )
}
