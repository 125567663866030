import { useEffect, useState } from "react"
import { getScheduleGroups } from "../services/schedule"
import useResponseHandler from "./useResponseHandler"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

export default function useScheduleGroups() {
  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const { rowsPerPages } = useSelector((state) => state.rowsPerPageReducer  ) 
  const [offset, setOffset] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const responseHandler = useResponseHandler()
  const { organizationId } = useParams()

  useEffect(() => {
    getData(search, rowsPerPages, offset)
  }, [search, rowsPerPages, offset])

  const getData = (search, limit, offset = 0) => {
    setLoading(true)
    setData([])
    getScheduleGroups({ search, limit, offset, organizationId })
      .then((response) => {
        setTotalCount(response.body.totalCount)
        setData(response.body.result)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, setSearch, offset, setOffset, page, setPage, totalCount }
}
