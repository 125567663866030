import { jwtDecode } from "jwt-decode"
import { createSlice } from "@reduxjs/toolkit"

const jwt = localStorage.getItem("jwt")
const initialState = {
  user: jwt ? jwtDecode(jwt) : null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const jwt = action.payload
      localStorage.setItem("jwt", jwt)
      state.user = jwt ? jwtDecode(jwt) : null
    },
    logout: (state) => {
      state.user = null
      localStorage.removeItem("jwt")
    },
  },
})

export const { login, initLogin, logout } = authSlice.actions
export const authReducer = authSlice.reducer
