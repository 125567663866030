import { LinearProgress } from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useParams } from "react-router-dom"

import CollapsibleItem from "../../components/CollapsibleItem"
import NoData from "../../components/NoData"
import SearchBar from "../../components/SearchBar"
import CollapsibleTableDataLayout from "../../layouts/CollapsibleTableDataLayout"
import ContentLayout from "../../layouts/ContentLayout"
import StateSwitcher from "./components/StateSwitcher"
import useFetchDiagnostics from "./hooks/useFetchDiagnostics"

export default function Diagnostics() {
  const { devEUI } = useParams()
  const [page, setPage] = useState(0)
  const [externalId, setExternalId] = useState(devEUI || "")
  const [offset, setOffset] = useState(0)
  const [isRunning, setIsRunning] = useState(true)

  const params = { isRunning, offset, externalId }
  const { data, totalCount, loading } = useFetchDiagnostics(params)

  const cells = [
    { label: "Device", name: "externalId", isUnorderable: true },
    {
      label: "Received at",
      name: "receivedAt",
      component: (value) => moment(value).format("lll"),
      isUnorderable: true,
    },
  ]

  return (
    <ContentLayout
      actions={[
        <SearchBar title="IMEI \ DevEUI" search={externalId} setSearch={setExternalId} />,
        <StateSwitcher {...{ isRunning, setIsRunning }} />,
      ]}>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && (
        <CollapsibleTableDataLayout
          {...{
            loading,
            data,
            totalCount,
            offset,
            setOffset,
            cells,
            page,
            setPage,
          }}
          Item={CollapsibleItem}
        />
      )}
      {loading && data.length === 0 && <LinearProgress />}
    </ContentLayout>
  )
}
