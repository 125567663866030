import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

export default function MenuItem({ text, icon, path, name, organization }) {
  const isOpen = useSelector((state) => state.toggleMenuModeReducer?.isOpen)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  // const TooltipTitle = ({text}) => {
  //   return organization ? <>{organization.name}:<br/> {t(text)}</> : <>{t(text)}</>
  // }
  const tooltipPrefix = organization ? `${organization.name}: ` : ""

  return (
    // <Tooltip title={!isOpen && <TooltipTitle text={text}/>} arrow placement="right">
    <Tooltip title={!isOpen && tooltipPrefix + t(text)} arrow placement="right">
      <ListItem dense key={text} disablePadding>
        <ListItemButton
          selected={pathname.startsWith(path)}
          sx={{ justifyContent: isOpen ? "initial" : "center", px: 2.5 }}
          onClick={() => {
            navigate(path)
          }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isOpen ? 3 : "auto",
              justifyContent: "center",
            }}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={t(text)} sx={{ opacity: isOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}
