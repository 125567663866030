import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import useResponseHandler from "../../hooks/useResponseHandler"
import ContentLayout from "../../layouts/ContentLayout"
import { getInfo } from "../../services/duty"

export default function Info() {
  const { t } = useTranslation()
  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const responseHandler = useResponseHandler()

  useEffect(() => {
    getInfo()
      .then((response) => setInfo(response.body))
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }, [])


  return (
    <ContentLayout>
      <Paper sx={{ width: 400 }} variant="outlined">
        <Table >
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {t("Version")}
              </TableCell>
              <TableCell align="right">{info.version}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </Paper>
    </ContentLayout>
  )
}
