import { useTranslation } from "react-i18next"
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Grid2 as Grid, Typography, FormControlLabel, Checkbox } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import invertBitMask from "../../../helpers/invertBitMask"

export default function Year({ months, setMonths }) {
  const { t } = useTranslation()

  const handleChangeMonth = (event, monthId) => {
    let newValue = months.split("")
    newValue[monthId] = Number(event.target.checked)
    setMonths(newValue.join(""))
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" m={2}>
            {t("Months")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start" m={2}>
            <Grid container item direction="row" justifyContent="flex-start" alignItems="center">
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[0]))} onChange={(event) => handleChangeMonth(event, 0)} />}
                  label={t("January")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[1]))} onChange={(event) => handleChangeMonth(event, 1)} />}
                  label={t("February")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[2]))} onChange={(event) => handleChangeMonth(event, 2)} />}
                  label={t("March")}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="flex-start" alignItems="center">
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[3]))} onChange={(event) => handleChangeMonth(event, 3)} />}
                  label={t("April")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[4]))} onChange={(event) => handleChangeMonth(event, 4)} />}
                  label={t("May")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[5]))} onChange={(event) => handleChangeMonth(event, 5)} />}
                  label={t("June")}
                />
              </Grid>
            </Grid>

            <Grid container item direction="row" justifyContent="flex-start" alignItems="center">
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[6]))} onChange={(event) => handleChangeMonth(event, 6)} />}
                  label={t("July")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[7]))} onChange={(event) => handleChangeMonth(event, 7)} />}
                  label={t("August")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[8]))} onChange={(event) => handleChangeMonth(event, 8)} />}
                  label={t("September")}
                />
              </Grid>
            </Grid>

            <Grid container item direction="row" justifyContent="flex-start" alignItems="center">
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[9]))} onChange={(event) => handleChangeMonth(event, 9)} />}
                  label={t("October")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[10]))} onChange={(event) => handleChangeMonth(event, 10)} />}
                  label={t("November")}
                />
              </Grid>
              <Grid size="grow">
                <FormControlLabel
                  control={<Checkbox checked={Boolean(parseInt(months[11]))} onChange={(event) => handleChangeMonth(event, 11)} />}
                  label={t("December")}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button
            onClick={() => {
              setMonths(invertBitMask(months))
            }}>
            {t("Invert")}
          </Button>
          <Button
            onClick={() => {
              setMonths("111111111111")
            }}>
            {t("Select All")}
          </Button>
          <Button
            onClick={() => {
              setMonths("000000000000")
            }}>
            {t("Clear All")}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  )
}
