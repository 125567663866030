import { createSlice } from "@reduxjs/toolkit"

const defaultMode = false
if (localStorage.getItem("isSilentModeActive") === null) {
  localStorage.setItem("isSilentModeActive", defaultMode)
}

const initialState = {
  isSilentModeActive: localStorage.getItem("isSilentModeActive") === "true" ? true : false,
}

export const toggleSilentModeSlice = createSlice({
  name: "toggleSilentMode",
  initialState,
  reducers: {
    toggleSilentMode: (state) => {
      const mode = !state.isSilentModeActive
      localStorage.setItem("isSilentModeActive", mode)
      state.isSilentModeActive = mode
    },
  },
})

export const { toggleSilentMode } = toggleSilentModeSlice.actions
export const toggleSilentModeReducer = toggleSilentModeSlice.reducer
