import Swagger from "swagger-client"
import getClientOpts from "./clientOpts"

const client = await Swagger("/swagger/api.swagger.json", getClientOpts())

// Devices
export async function getDevice(id, organizationId) {
  return client.apis.DeviceService.DeviceService_Get({ id, organizationId })
}

export async function createDevice(data) {
  return client.apis.DeviceService.DeviceService_Create({ body: data })
}

export async function updateDevice(device) {
  return client.apis.DeviceService.DeviceService_Update({ body: { device } })
}

export async function listDevices(filters) {
  return client.apis.DeviceService.DeviceService_List(filters)
}

export async function deleteDevice(id, organizationId) {
  return client.apis.DeviceService.DeviceService_Delete({ id, organizationId })
}

// DeviceTypes
export async function getDeviceType(id) {
  return client.apis.DeviceService.DeviceService_GetType({ id })
}

export async function createDeviceType(data) {
  return client.apis.DeviceService.DeviceService_CreateType({ body: data })
}

export async function updateDeviceType(deviceType) {
  return client.apis.DeviceService.DeviceService_UpdateType({ body: { deviceType } })
}

export async function listDeviceTypes(filters) {
  return client.apis.DeviceService.DeviceService_ListTypes(filters)
}

export async function deleteDeviceType(id) {
  return client.apis.DeviceService.DeviceService_DeleteType({ id })
}

export async function getListMessagesLog(externalId) {
  return client.apis.DeviceService.DeviceService_ListMessagesLog({ externalId })
}
