import { useState } from "react"
import { useTranslation } from "react-i18next"
import { IconButton, ListItemIcon } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import TranslateIcon from "@mui/icons-material/Translate"
import ruFlag from "../../assets/ru.svg"
import enFlag from "../../assets/en.svg"
import { useDispatch } from "react-redux"
import { showNotification } from "../../slices/notificationSlice"

export default function LanguageSelector() {
  const dispatch = useDispatch()
  const { i18n, t} = useTranslation()
  const flagSize = 24

  const languagesList = [
    { lang: "ru", name: "Русский", flag: ruFlag },
    { lang: "en", name: "English", flag: enFlag },
  ]

  const [language, setLanguage] = useState(languagesList[0])
  const [anchorEl, setAnchorEl] = useState(null)
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <TranslateIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {languagesList.map((item, index) => {
          return (
            <MenuItem
              selected={language.lang === item.lang}
              key={index}
              onClick={() => {
                setLanguage(languagesList[index])
                i18n.changeLanguage(item.lang)
                dispatch(showNotification({message: t("Language changed") + `: ${item.name} `, type: "success"}))
              }}>
              <ListItemIcon>
                <img alt={item.lang} src={item.flag} width={flagSize} height={flagSize} />
              </ListItemIcon>
              {item.name}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
