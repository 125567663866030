import { Paper, Skeleton } from "@mui/material"
import L from "leaflet"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import { Link } from "react-router-dom"
import "leaflet.awesome-markers"

const locationMarker = L.AwesomeMarkers.icon({ prefix: "bi", icon: "bi-circle", markerColor: "blue" }) 

export default function MarkersOnMap({ loading, data, withPopup }) {
  // const defaultCenter = [55.75168, 37.618775]
  // const center = defaultCenter ||

  const size = { width: "100wh", height: "600px" }
  const bounds = data.map((item) => [item.latitude, item.longitude])

  return (
    <Paper>
      {loading ? (
        <Skeleton variant="rounded" {...size} animation="wave" />
      ) : (
        <MapContainer
          bounds={bounds}
          attributionControl={false}
          style={{ minHeight: "600px", width: "100wh" }}
          zoom={15}
          scrollWheelZoom={true}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {data.map((item, index) => (
            <Marker key={index} position={[item.latitude, item.longitude]} icon={locationMarker}>
              {withPopup && (
                <Popup>
                  <Link to={item.id}>{item.name}</Link>
                </Popup>
              )}
            </Marker>
          ))}
        </MapContainer>
      )}
    </Paper>
  )
}
