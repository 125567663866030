import { Collapse, Grid2 as Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import FilterAltIcon from "@mui/icons-material/FilterAlt"
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff"
import PathBreadcrumbs from "../components/PathBreadcrumbs"

export default function ContentLayout({ children, title, actions, advancedFilters, isFilterOpen }) {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(isFilterOpen)

  return (
    <Paper sx={{ padding: "20px" }}>
      <Grid container direction="column" py={1}>
        <Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap" spacing={1}>
            <Grid sx={{ flexGrow: 1 }}>
              <PathBreadcrumbs />
            </Grid>
            {advancedFilters && (
              <Grid>
                <Tooltip title={t("Filters")}>
                  <IconButton
                    onClick={() => {
                      setFilter((prev) => !prev)
                    }}>
                    {!filter ? <FilterAltIcon /> : <FilterAltOffIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {actions &&
              actions.map((action, index) => (
                <Grid key={index}>
                  {action}
                </Grid>
              ))}
          </Grid>
        </Grid>
        {advancedFilters && (
          <Grid >
            <Collapse in={filter}>
              <Grid container spacing={1} direction="row" justifyContent="flex-end" wrap="nowrap" py={1}>
                {advancedFilters &&
                  advancedFilters.map((action, index) => (
                    <Grid key={index}>
                      {action}
                    </Grid>
                  ))}
              </Grid>
            </Collapse>
          </Grid>
        )}
      </Grid>
      {children}
    </Paper>
  )
}
