import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import useScheduleGroups from "../hooks/useScheduleGroups"

export default function ScheduleSelector({ scheduleGroupId }) {
  const props = useScheduleGroups()
  const { loading, data } = props

  return (
    <FormControl required fullWidth>
      <InputLabel>{scheduleGroupId.label}</InputLabel>
      <Select
        id="scheduleGroupId"
        disabled={loading}
        label={scheduleGroupId.label}
        error={scheduleGroupId.error}
        value={scheduleGroupId.value}
        onChange={scheduleGroupId.onChange}
        onBlur={scheduleGroupId.onBlur}
      >
        {data.map((item, index) => (
          <MenuItem id={item.id} key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{scheduleGroupId.helperText}</FormHelperText>
    </FormControl>
  )
}
