import { createSlice } from "@reduxjs/toolkit"

const defaultMode = true
if (localStorage.getItem("isMenuOpen") === null) {
  localStorage.setItem("isMenuOpen", defaultMode)
}

const initialState = {
  isOpen: localStorage.getItem("isMenuOpen") === "true" ? true : false,
}

export const toggleMenuModeSlice = createSlice({
  name: "toggleMenuMode",
  initialState,
  reducers: {
    toggleMenuMode: (state) => {
      const mode = !state.isOpen
      localStorage.setItem("isMenuOpen", mode)
      state.isOpen = mode
    },
  },
})

export const { toggleMenuMode } = toggleMenuModeSlice.actions
export const toggleMenuModeReducer = toggleMenuModeSlice.reducer
