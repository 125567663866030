import { IconButton, TableCell, TableRow } from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export default function CollapsibleTableTitleRow({ cells, dataItem, open, setOpen }) {

  return (
    <TableRow hover>
      <TableCell>
        <IconButton
          size="small"
          onClick={() => {
            setOpen((prev) => !prev)
          }}>
          {
          open 
          ? <ExpandLessIcon /> 
          : <ExpandMoreIcon />
          }
        </IconButton>
      </TableCell>
      {cells.map((cellItem, cellIndex) => (
        <TableCell key={cellIndex} width={cellItem.width}>
          {
          cellItem.component 
          ? cellItem.component(dataItem[cellItem.name], dataItem) 
          : dataItem[cellItem.name]
          }
        </TableCell>
      ))}
    </TableRow>
  )
}
