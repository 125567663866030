import { LinearProgress } from "@mui/material"

import TableDataLayout from "../../layouts/TableDataLayout"
import NoData from "../../components/NoData"
import useSearch from "./hooks/useSearch"
import ContentLayout from "../../layouts/ContentLayout"

export default function Search() {
  const props = useSearch()
  const { loading, data } = props
  return (
      <ContentLayout title="Search">
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && <TableDataLayout {...props} />}
      {loading && <LinearProgress />}
      </ContentLayout>
  )
}
