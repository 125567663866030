import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation, latitudeValidation, longitudeValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createDevice, deleteDevice, getDevice, updateDevice } from "../../../services/device"
import { startLoading, stopLoading } from "../../../slices/loadingSlice"
import { showNotification } from "../../../slices/notificationSlice"

export default function useDeviceCard() {
  const { deviceId, organizationId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const responseHandler = useResponseHandler()
  const [loading, setLoading] = useState(false)

  const name = useInput({ label: t("Device name") }, baseValidation)
  const description = useInput({ label: t("Description") })
  // const scheduleGroupId = useInput({ label: t("Schedule") }, { isEmptyCheck: true })
  const locationId = useInput({ label: t("Location") }, { isEmptyCheck: true })
  const deviceTypeId = useInput({ label: t("Device type") }, { isEmptyCheck: true })
  const externalId = useInput({ label: t("External ID (IMEI \\ DevEUI)") }, { isEmptyCheck: true })
  const latitude = useInput({ init: 55.753298, label: t("Latitude") }, latitudeValidation)
  const longitude = useInput({ init: 37.621938, label: t("Longitude") }, longitudeValidation)
  // const voltageMin = useInput({ label: `${t("Min.")} ${t("voltage")}` }, { isNumber: true })
  // const voltageMax = useInput({ label: `${t("Max.")} ${t("voltage")}` }, { isNumber: true })
  // const currentMin = useInput({ label: `${t("Min.")} ${t("current")}` }, { isNumber: true })
  // const currentMax = useInput({ label: `${t("Max.")} ${t("current")}` }, { isNumber: true })
  // const powerMin = useInput({ label: `${t("Min.")} ${t("power")}` }, { isNumber: true })
  // const powerMax = useInput({ label: `${t("Max.")} ${t("power")}` }, { isNumber: true })
  // const limits = { voltageMin, voltageMax, currentMin, currentMax, powerMin, powerMax }

  useEffect(() => {
    if (deviceId && organizationId) {
      getData(deviceId, organizationId)
    }
  }, [])

  const getData = (deviceId, organizationId) => {
    dispatch(startLoading())
    setLoading(true)
    getDevice(deviceId, organizationId)
      .then((response) => {
        const { device } = response.body
        name.set(device.name)
        description.set(device.description)
        // scheduleGroupId.set(device.scheduleGroupId)
        locationId.set(device.locationId)
        deviceTypeId.set(device.deviceTypeId)
        externalId.set(device.externalId)
        latitude.set(device.latitude)
        longitude.set(device.longitude)
        // voltageMin.set(device.voltageMin)
        // voltageMax.set(device.voltageMax)
        // currentMin.set(device.currentMin)
        // currentMax.set(device.currentMax)
        // powerMin.set(device.powerMin)
        // powerMax.set(device.powerMax)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
        dispatch(stopLoading())
      })
  }

  const handleCreateDevice = () => {
    setLoading(true)
    createDevice({
      device: {
        name: name.value,
        deviceTypeId: deviceTypeId.value,
        externalId: externalId.value,
        latitude: latitude.value,
        longitude: longitude.value,
        // scheduleGroupId: scheduleGroupId.value,
        locationId: locationId.value,
        description: description.value,
        // voltageMin: voltageMin.value,
        // voltageMax: voltageMax.value,
        // currentMin: currentMin.value,
        // currentMax: currentMax.value,
        // powerMin: powerMin.value,
        // powerMax: powerMax.value,
      },
      organizationId,
    })
      .then(() => {
        dispatch(showNotification({ message: t("New device is created!"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateDevice = () => {
    updateDevice({
      id: deviceId,
      name: name.value,
      deviceTypeId: deviceTypeId.value,
      externalId: externalId.value,
      latitude: latitude.value,
      longitude: longitude.value,
      // scheduleGroupId: scheduleGroupId.value,
      locationId: locationId.value,
      description: description.value,
      // voltageMin: voltageMin.value,
      // voltageMax: voltageMax.value,
      // currentMin: currentMin.value,
      // currentMax: currentMax.value,
      // powerMin: powerMin.value,
      // powerMax: powerMax.value,
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteDevice = (id) => {
    deleteDevice(id, organizationId)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return {
    name,
    deviceId,
    deviceTypeId,
    locationId,
    externalId,
    // scheduleGroupId,
    description,
    latitude,
    longitude,
    loading,
    handleDeleteDevice,
    handleUpdateDevice,
    handleCreateDevice,
    // limits,
  }
}
