import { LinearProgress } from "@mui/material"
import { useParams } from "react-router-dom"

import NoData from "../../components/NoData"
import MarkersOnMap from "./components/MarkersOnMap"
import useFetchLocations from "../../hooks/useFetchLocations"


export default function LocationsMap({ search}) {
  const { organizationId } = useParams()
  const params = { search, organizationId, initLimit: 9999}
  const { data, loading } = useFetchLocations(params)


  return (
    <>
      {!loading && data.length === 0 && <NoData />}
      {data.length > 0 && <MarkersOnMap withPopup {...{ loading, data }} />}
      {loading && <LinearProgress />}
    </>
  )
}
