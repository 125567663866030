import { Button, Checkbox, FormControlLabel, FormGroup, Grid2 as Grid, TextField, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function ExistUser({ data, loading, addUserToOrganization }) {
  const { t } = useTranslation()
  const { email, isAdmin, isActive } = data

  const isChanged = email.isChanged
  const isValid = email.isValid
  const isDisabled = loading || !isValid || !isChanged

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" py={2}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <TextField
          id="email"
          fullWidth
          disabled={loading}
          value={email.value}
          onChange={email.onChange}
          onBlur={email.onBlur}
          label={email.label}
          helperText={email.helperText}
          error={email.error}
          variant="outlined"
          required
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <FormGroup>
          <Typography variant="h6">{t("Permissions in organization")}</Typography>
          {/* <FormControlLabel
          control={<Checkbox disabled={loading} checked={isActive.value} />}
          label={isActive.label}
          onChange={isActive.onChange}
        /> */}
          <FormControlLabel control={<Checkbox disabled={loading} checked={isAdmin.value} />} label={isAdmin.label} onChange={isAdmin.onChange} />
        </FormGroup>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button disabled={isDisabled} type="submit" onClick={addUserToOrganization} variant="contained">
          {t("Add")}
        </Button>
      </Grid>
    </Grid>
  )
}
