import { Button, Checkbox, FormControlLabel, FormGroup, Grid2 as Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function ChangePermissions({ data, loading, handleUpdateOrganizationUser }) {
  const { isAdmin } = data
  const { t } = useTranslation()

  const isChanged = [isAdmin.isChanged].some(Boolean)
  const isDisabled = loading || !isChanged

  return (
    <Grid container spacing={3} item direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid size={{ xs: 12}}>
        <Typography variant="h6">{t("Permissions in organization")}</Typography>
      </Grid>
      <Grid size={{ xs: 12}}>
        <FormGroup>
          <FormControlLabel control={<Checkbox disabled={loading} checked={isAdmin.value} />} label={isAdmin.label} onChange={isAdmin.onChange} />
        </FormGroup>
      </Grid>
      <Grid size={{ xs: 12}}>
        <Button disabled={isDisabled} type="submit" onClick={handleUpdateOrganizationUser} variant="contained">
          {t("Apply")}
        </Button>
      </Grid>
    </Grid>
  )
}
