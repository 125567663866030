import { Button, Grid2 as Grid, IconButton, Link, Table, TableBody, Tooltip, Typography } from "@mui/material"
import moment from "moment"
import "moment/locale/ru"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { createSearchParams, useNavigate, useParams } from "react-router-dom"

import ConstructionIcon from "@mui/icons-material/Construction"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import EventNoteIcon from "@mui/icons-material/EventNote"

import MarkersOnMap from "../../components/MarkersOnMap"
import Private from "../../components/Private"
import useResponseHandler from "../../hooks/useResponseHandler"
import ItemCardLayout from "../../layouts/ItemCardLayout"
import { deleteDevice } from "../../services/device"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import { showNotification } from "../../slices/notificationSlice"
import AdvancedState from "./components/AdvancedState"
import DeviceInfoRow from "./components/DeviceInfoRow"
import RelaySwitcher from "./components/DeviceRelaySwitcher"
import StateDefaultType from "./components/StateDefaultType"
import StateFirstType from "./components/StateFirstType"
import useFetchDeviceInfo from "./hooks/useFetchDeviceInfo"

export default function DeviceInfo() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const responseHandler = useResponseHandler()
  const { organizationId, deviceId } = useParams()
  const { loading, deviceInfo } = useFetchDeviceInfo()

  const { name, externalId, deviceType, description, lastSeenAt, createdAt, updatedAt, latitude, longitude, location, locationId, isOnline, stateKind, state } =
    deviceInfo

  const points = [{ name, longitude, latitude }]

  const locationComponent = (value) => (
    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(`/organization/${organizationId}/locations/${locationId}`)}>
      {value}
    </Link>
  )

  const idComponent = (value) => (
    <>
      <Typography display="inline" paddingRight={1}>
        {value}
      </Typography>
      {value && (
        <Private roleRequired="globalAdmin">
          <Tooltip title={t("Diagnostics")}>
            <IconButton size="small" onClick={() => navigate(`/diagnostics/${value}`)}>
              <ConstructionIcon />
            </IconButton>
          </Tooltip>
        </Private>
      )}
      {value && (
        <Tooltip title={t("Events")}>
          <IconButton
            size="small"
            onClick={() => navigate({ pathname: `/organization/${organizationId}/events`, search: createSearchParams({ deviceId }).toString() })}
          >
            <EventNoteIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  )

  const mainInfo = [
    { name: "Device name", value: name },
    { name: "Location", value: location, component: locationComponent },
    { name: "ID", value: externalId, component: idComponent },
    { name: "Device type", value: deviceType },
    { name: "Description", value: description },
    { name: "Last seen at", value: loading ? "" : lastSeenAt ? moment(lastSeenAt).format("lll") : "—" },
    { name: "Created at", value: createdAt && moment(createdAt).format("lll") },
    { name: "Updated at", value: updatedAt && moment(updatedAt).format("lll") },
  ]

  const onDeleteDeviceConfirm = () => {
    dispatch(
      showConfirmDialog({
        title: t("You can't undo this operation"),
        message: t("Are you sure to delete this device?"),
        onConfirm: () => {
          handleDeleteDevice(deviceId)
        },
      })
    )
  }

  const handleDeleteDevice = (id) => {
    deleteDevice(id, organizationId)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return (
    <ItemCardLayout
      title={name}
      state={loading}
      actions={
        <>
          {deviceId && (
            <Private roleRequired="admin" organizationId={organizationId}>
              <Grid>
                <Button
                  disabled={loading}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigate("edit")
                  }}
                  startIcon={<EditIcon fontSize="small" />}
                >
                  {t("Edit")}
                </Button>
              </Grid>
            </Private>
          )}
          {deviceId && (
            <Private roleRequired="admin" organizationId={organizationId}>
              <Grid>
                <Button disabled={loading} color="error" variant="outlined" startIcon={<DeleteIcon />} onClick={onDeleteDeviceConfirm}>
                  {t("Delete")}
                </Button>
              </Grid>
            </Private>
          )}
        </>
      }
    >
      <Grid container columnSpacing={2} direction="row" justifyContent="space-between" alignItems="flex-start" py={1}>
        <Grid size={{ xs: 12, lg: 5 }}>
          <Table size="small">
            <TableBody>
              {mainInfo.length > 0 && <DeviceInfoRow caption="Main Info" data={mainInfo} isOnline={isOnline} status />}
              {state && Object.keys(state).length !== 0 && (
                <>
                  {stateKind === 0 && <StateDefaultType state={state} />}
                  {stateKind === 1 && <StateFirstType state={state} />}
                  <Private roleRequired="globalAdmin">
                    <AdvancedState state={state} />
                  </Private>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid size={{ xs: 12, lg: 7 }}>
          <Grid container spacing={1} direction="column">
            <Grid size={{ xs: 12 }}>
              <MarkersOnMap data={points} loading={loading} />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Grid container spacing={1} direction="row" justifyContent="flex-end">
                {stateKind === 1 && (
                  <Grid>
                    <RelaySwitcher {...{ deviceId, locationId, organizationId }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ItemCardLayout>
  )
}
