import { Paper } from "@mui/material"
import { useSelector } from "react-redux"
import ContentLayout from "../../layouts/ContentLayout"
import ChangePassword from "./components/ChangePassword"

export default function Password() {
  const user = useSelector((state) => state.authReducer.user)
  const userId = user.id

  return (
    // <Paper sx={{ padding: "20px" }}>
      <ContentLayout title="Change password" >
      <ChangePassword {...{ userId }} />
      </ContentLayout>
    // </Paper>
  )
}
