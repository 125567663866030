import { createSlice } from "@reduxjs/toolkit"

const defaultValue = 25
const initialState = {
  rowsPerPages: Number(localStorage.getItem("rowsPerPage")) || defaultValue,
}

export const rowsPerPageSlice = createSlice({
  name: "rowsPerPage",
  initialState,
  reducers: {
    changeRowsPerPages: (state, action) => {
      state.rowsPerPages = action.payload
      localStorage.setItem("rowsPerPage", action.payload)
    },
  },
})

export const { changeRowsPerPages } = rowsPerPageSlice.actions
export const rowsPerPageReducer = rowsPerPageSlice.reducer
