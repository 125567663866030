import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { baseValidation, emailValidation, nameValidation, passwordValidation } from "../../../helpers/validations"
import useInput from "../../../hooks/useInput"
import useCheckBoxInput from "../../../hooks/useCheckboxInput"
import useResponseHandler from "../../../hooks/useResponseHandler"
import { createUser, deleteUser, getUser, updatePassword, updateUser } from "../../../services/user"
import { showNotification } from "../../../slices/notificationSlice"
import { deleteOrganizationUser, updateOrganizationUser } from "../../../services/organization"

export default function useUserCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const responseHandler = useResponseHandler()

  const { userId } = useParams()
  const [loading, setLoading] = useState(false)
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")

  const name = useInput({ label: t("Name") /*helperText: t("Name")*/ }, nameValidation)
  const surname = useInput({ label: t("Surname") /*helperText: t("Surname")*/ }, nameValidation)
  const phone = useInput({ label: t("Phone") /*helperText: t("Phone")*/ }, {})
  const email = useInput({ label: t("E-mail") /*helperText: t("Email")*/ }, emailValidation)
  const company = useInput({ label: t("Company") /*helperText: t("Company")*/ }, baseValidation)
  const description = useInput({ label: t("Notes") })
  const position = useInput({ label: t("Position / Subdivision") })
  const sessionTTL = useInput({ init: "0", label: t("User session TTL (in minutes)") })
  const isActive = useCheckBoxInput({ init: true, label: t("Active") })
  const isAdmin = useCheckBoxInput({ init: false, label: t("Global admin") })

  const passwordHelperLocaleParams = {
    minUpperChar: 2,
    minLowerChar: 2,
    minDigits: 2,
    minSpecChar: 1,
    minLength: 13,
    specChars: "-_+=#@&^()*/><{}?!|`~,.",
  }

  const password = useInput(
    {
      label: t("Password"),
      helperText: t(
        "Must have at least X uppercase characters, X lowercase characters, X numbers, X special, and X chars min length",
        passwordHelperLocaleParams
      ),
    },
    passwordValidation
  )



  const data = {
    userId,
    name,
    surname,
    email,
    phone,
    company,
    position,
    description,
    sessionTTL,
    createdAt,
    updatedAt,
    isActive,
    isAdmin,
    password,
  }

  useEffect(() => {
    if (userId) {
      getData(userId)
    }
  }, [])

  const getData = (userId) => {
    setLoading(true)
    getUser(userId)
      .then((response) => {
        const { user, createdAt, updatedAt } = response.body
        name.set(user.name)
        surname.set(user.surname)
        email.set(user.email)
        company.set(user.company)
        phone.set(user.phone)
        position.set(user.position)
        description.set(user.description)
        sessionTTL.set(user.sessionTTL / 60)
        position.set(user.position)
        isAdmin.set(user.isAdmin)
        isActive.set(user.isActive)
        setCreatedAt(createdAt)
        setUpdatedAt(updatedAt)
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleChangePassword = () => {
    updatePassword(userId, password.value)
      .then(() => {
        dispatch(showNotification({ message: t("Password is updated!"), type: "success" }))
      })
      .catch(responseHandler)
  }
  
  const handleDeleteUser = (id) => {
    deleteUser(id)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleDeleteOrganizationUser = (organizationId, userId) => {
    deleteOrganizationUser(organizationId, userId)
      .then(() => {
        dispatch(showNotification({ message: t("Deleted successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleUpdateOrganizationUser = () => {
    updateOrganizationUser({
      userId,
      email: email.value,
      name: name.value,
      surname: surname.value,
      phone: phone.value,
      company: company.value,
      position: position.value,
      description: description.value,
      sessionTTL: sessionTTL.value * 60,
      isActive: isActive.value,
      role: isAdmin.value ? "admin" : "user",
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  const handleCreateUser = () => {
    setLoading(true)
    createUser({
      password: password.value,
      user: {
        name: name.value,
        surname: surname.value,
        email: email.value,
        phone: phone.value,
        company: company.value,
        position: position.value,
        description: description.value,
        sessionTTL: sessionTTL.value * 60,
        isActive: isActive.value,
        isAdmin: isAdmin.value,
      },
    })
      .then(() => {
        dispatch(showNotification({ message: t("New user is created!"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateUser = () => {
    updateUser({
      id: userId,
      email: email.value,
      name: name.value,
      surname: surname.value,
      phone: phone.value,
      company: company.value,
      position: position.value,
      description: description.value,
      sessionTTL: sessionTTL.value * 60,
      isActive: isActive.value,
      isAdmin: isAdmin.value,
    })
      .then(() => {
        dispatch(showNotification({ message: t("Updated successfully"), type: "success" }))
        navigate("..")
      })
      .catch(responseHandler)
  }

  return {
    data,
    loading,
    setLoading,
    handleDeleteUser,
    handleCreateUser,
    handleUpdateUser,
    handleDeleteOrganizationUser,
    handleUpdateOrganizationUser,
    handleChangePassword,
  }
}
