import { Avatar, Chip, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import InfoIcon from "@mui/icons-material/Info"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import KeyIcon from "@mui/icons-material/Key"
import LogoutIcon from "@mui/icons-material/Logout"

import { logout } from "../../slices/authSlice"
import { refuseOrganization } from "../../slices/organizationSlice"
import Private from "../Private"

export default function AccountMenu() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector((state) => state.authReducer?.user)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogoutClick = () => {
    dispatch(refuseOrganization())
    dispatch(logout())
    navigate("/")
  }

  return (
    <>
      {user ? (
        <>
          <Chip
            color="primary"
            avatar={
              <Avatar size="large">
                <AccountCircleIcon />
              </Avatar>
            }
            label={user.name}
            onClick={handleClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => navigate("/passwd")}>
              <ListItemIcon><KeyIcon fontSize="small" /></ListItemIcon>
              {t("Change password")}
            </MenuItem>
            <Private roleRequired="globalAdmin">
              <MenuItem onClick={() => navigate("/info")}>
                <ListItemIcon><InfoIcon fontSize="small" /></ListItemIcon>
                {t("Info")}
              </MenuItem>
            </Private>
            <MenuItem onClick={onLogoutClick}>
              <ListItemIcon><LogoutIcon fontSize="small" /></ListItemIcon>
              {t("Log Out")}
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Skeleton variant="rounded" width={200} height={30} />
      )}
    </>
  )
}
