import { IconButton, List, ListItem, ListItemText, ListSubheader } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"

import { refuseOrganization } from "../../slices/organizationSlice"

export default function Subheader() {
  const { organization } = useSelector((state) => state.organizationReducer)
  const { isOpen } = useSelector((state) => state.toggleMenuModeReducer)
  const dispatch = useDispatch()
  const { organizationId } = useParams()
  const navigate = useNavigate()

  const primaryTypographyProps = {
    style: {
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "center",
    },
  }

  return (
    isOpen && (
      <ListSubheader>
        <List>
          <ListItem
            disableGutters
            secondaryAction={
              <IconButton
                onClick={() => {
                  organizationId && navigate("/")
                  dispatch(refuseOrganization())
                }}>
                <CloseIcon />
              </IconButton>
            }>
            <ListItemText
              primary={organization.name}
              primaryTypographyProps={primaryTypographyProps}
            />
          </ListItem>
        </List>
      </ListSubheader>
    )
  )
}
