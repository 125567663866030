import { Button, Checkbox, FormControlLabel, FormGroup, Grid2 as Grid, TextField, Typography } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import DeleteIcon from "@mui/icons-material/Delete"

import ItemCardLayout from "../../layouts/ItemCardLayout"
import { showConfirmDialog } from "../../slices/confirmDialogSlice"
import NewAPIKeyDialog from "./components/NewAPIKeyDialog"
import useAPIKeyCard from "./hooks/useAPIKeyCard"

export default function APIKeyCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    open,
    setOpen,
    handleCreateAPIKey,
    handleUpdateAPIKey,
    handleDeleteAPIKey,
    APIkey,
    name,
    description,
    isActive,
    APIKeyId,
    loading,
    organizationId,
    createdAt,
    updatedAt,
  } = useAPIKeyCard()

  const isValid = [name.isValid].every(Boolean)
  const isChanged = [name.isChanged, description.isChanged, isActive.isChanged].some(Boolean)
  const isDisabled = loading || !isValid || !isChanged

  return (
    <>
      <NewAPIKeyDialog {...{ open, setOpen, APIkey }} />
      <ItemCardLayout
        state={loading}
        actions={
          APIKeyId && (
            <Grid>
              <Button
                disabled={loading}
                color="error"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    showConfirmDialog({
                      title: t("You can't undo this operation"),
                      message: t("Are you sure to delete this API key?"),
                      onConfirm: () => {
                        handleDeleteAPIKey(APIKeyId, organizationId)
                      },
                    })
                  )
                }}
                startIcon={<DeleteIcon />}
              >
                {t("Delete")}
              </Button>
            </Grid>
          )
        }
      >
        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start" py={1}>
          <Grid size={{ xs: 12 }}>
            <TextField
              id="name"
              disabled={loading}
              fullWidth
              onBlur={name.onBlur}
              value={name.value}
              error={name.error}
              onChange={name.onChange}
              helperText={name.helperText}
              label={name.label}
              variant="outlined"
              required
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              id="description"
              disabled={loading}
              fullWidth
              multiline
              minRows={4}
              onBlur={description.onBlur}
              value={description.value}
              error={description.error}
              onChange={description.onChange}
              helperText={description.helperText}
              label={description.label}
              variant="outlined"
            />
          </Grid>
          {(createdAt || updatedAt) && (
            <Grid size={{ xs: 12 }}>
              <Typography color="text.secondary">
                {t("Created at")}: {moment(createdAt).format("lll")}
              </Typography>
              <Typography color="text.secondary">
                {t("Updated at")}: {moment(updatedAt).format("lll")}
              </Typography>
            </Grid>
          )}
          <Grid size={{ xs: 12 }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox disabled={loading} checked={isActive.value} />} label={isActive.label} onChange={isActive.onChange} />
            </FormGroup>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {APIKeyId ? (
              <Button disabled={isDisabled} onClick={handleUpdateAPIKey} variant="contained">
                {t("Apply")}
              </Button>
            ) : (
              <Button disabled={isDisabled} onClick={handleCreateAPIKey} variant="contained">
                {t("Create")}
              </Button>
            )}
          </Grid>
        </Grid>
      </ItemCardLayout>
    </>
  )
}
