import { Alert, AlertTitle, Badge, Box, FormControlLabel, Grid2 as Grid, IconButton, ListItemIcon, Menu, MenuItem, Switch, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import NotificationsIcon from "@mui/icons-material/Notifications"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote"

import useResponseHandler from "../../hooks/useResponseHandler"
import { listEvents } from "../../services/event"
import { addAlerts, clearAllAlerts, removeAlert } from "../../slices/alertSlice"
import { toggleSilentMode } from "../../slices/toggleSilentModeSlice"
import { showNotification } from "../../slices/notificationSlice"
import moment from "moment"

export default function Alerts() {
  const responseHandler = useResponseHandler()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isRunning, setIsRunning] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const alertsCount = 5
  const [data, setData] = useState([])
  const [lastItem, setLastItem] = useState()
  const { alerts } = useSelector((state) => state.alertReducer)
  const { isSilentModeActive } = useSelector((state) => state.toggleSilentModeReducer)
  const { organization } = useSelector((state) => state.organizationReducer)
  const organizationId = organization.id
  const open = Boolean(anchorEl)

  useEffect(() => {
    updateData(organizationId)
    if (isRunning) {
      const interval = setInterval(() => {
        updateData(organizationId)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [isRunning, isSilentModeActive])

  const updateData = (organizationId) => {
    listEvents({ organizationId, limit: 1 })
      .then((response) => {
        const newLastItem = response.body.result[0]
        setLastItem((prev) => {
          if (prev) {
            if (prev.id !== newLastItem.id) {
              const newLimit = newLastItem.id - prev.id
              listEvents({ organizationId, limit: newLimit })
                .then((response) => {
                  const allNewItems = response.body.result
                  dispatch(addAlerts(allNewItems))
                  if (!isSilentModeActive) {
                    dispatch(showNotification({ message: t("newEventReceived", { count: allNewItems.length }), type: "info", withSound: true }))
                  }
                })
                .catch((response) => {
                  responseHandler(response)
                  setData([])
                })
            }
          }
          return newLastItem
        })
      })
      .catch((response) => {
        responseHandler(response)
        setData([])
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getSeverity = (type) => {
    switch (type) {
      case 1:
        return "info"
      case 2:
        return "warning"
      case 3:
        return "error"
      default:
        return "info"
    }
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge color={isSilentModeActive ? "info" : "error"} badgeContent={alerts.length || 0}>
          {isSilentModeActive ? <NotificationsOffIcon /> : <NotificationsIcon />}
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <Box mx={3}>
          <FormControlLabel control={<Switch checked={isSilentModeActive} onChange={() => dispatch(toggleSilentMode())} />} label={t("Do not disturb")} />
        </Box>
      
        <TransitionGroup>
          {alerts.slice(0, alertsCount).map((item, index) => (
            <Collapse key={index}>
              <MenuItem key={index} disableRipple>
                <Alert
                  sx={{ width: "100%" }}
                  key={index}
                  severity={getSeverity(item.type)}
                  onClick={() => {
                    navigate(`/organization/${organizationId}/events`)
                    dispatch(removeAlert(index))
                  }}
                  onClose={(e) => {
                    e.stopPropagation()
                    dispatch(removeAlert(index))
                  }}>
                  <Grid container spacing={2}>
                    <Grid>{moment(item.createdAt).format("LTS")}</Grid>
                    <Grid>
                      <AlertTitle>
                        <SettingsRemoteIcon sx={{ mr: 0.5 }} fontSize="inherit" /> <strong> {item.deviceName} </strong>
                      </AlertTitle>
                      <AlertTitle> {` ${item.name}`}</AlertTitle>
                      {item.description}
                    </Grid>
                  </Grid>
                </Alert>
              </MenuItem>
            </Collapse>
          ))}
    </TransitionGroup>
        {alerts.length > 0 ? (
          <MenuItem
            onClick={() => {
              dispatch(clearAllAlerts())
              navigate(`/organization/${organizationId}/events`)
            }}>
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <Typography> {`${t("Show all")} (${alerts.length})`} </Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => navigate(`/organization/${organizationId}/events`)}>Новых уведомлений нет</MenuItem>
        )}
      </Menu>
    </>
  )
}
