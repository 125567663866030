
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  withSound: false,
  message : undefined,
  open : false
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      // state.message = action.payload
      state.open = false
      state.withSound = action.payload.withSound
      state.title = action.payload.title
      state.message = action.payload.message
      state.type = action.payload.type || "info"
      state.open = true
    },
    hideNotification: (state) => {
        state.open = false
    },
  },
})

export const { showNotification, hideNotification } = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer