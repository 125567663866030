import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function useValidation(validations) {
  const [error, setError] = useState("")
  const { t } = useTranslation()

 const validate = (value) => {
    setError("")
    for (const validation in validations) {
      switch (validation) {
        case "regexp":
          const regexp = validations[validation]
          if (!regexp.test(value)) {
            setError(t("Invalid value format"))
          }
          break
        case "minLength":
          if (0 <= value.length && value.length < validations[validation]) {
            setError(t("The value must be longer than X characters", { characters: validations[validation] }))
          }
          break
        case "isEmptyCheck":
          if (value === "") {
            setError(t("Field cannot be empty"))
          }
          break
        case "minUpperCase":
          const upper = value.match(/["A-Z"]/g) || []
          if (upper.length < validations[validation]) {
            setError(t("The value must have at least X uppercase letter", { letters: validations[validation] }))
          }
          break
        case "minLowerCase":
          const lower = value.match(/["a-z"]/g) || []
          if (lower.length < validations[validation]) {
            setError(t("The value must have at least X lowercase letter", { letters: validations[validation] }))
          }
          break
        case "minDigits":
          const digits = value.match(/[0-9]/g) || []
          if (digits.length < validations[validation]) {
            setError(t("The value must have at least X digits", { digits: validations[validation] }))
          }
          break
        case "minSpecialChar":
          if (!/[-_+=#@&^()*/><{}?!|`~,.\\]/.test(value)) {
            setError(t("The value must contain special characters:") + " -_+=#@&^()*/><{}?!|`~,.")
          }
          break
        case "confirmPassword":
          if (value !== validations[validation]) {
            setError(t("Password mismatch!"))
          }
          break
        case "noSpaces":
          if (!/^\S*$/.test(value)) {
            setError(t("Spaces are not allowed"))
          }
          break
        case "lessThan":
          if (value >= validations[validation]) {
            setError(t(`Value must be less than ${validations[validation]}`))
          }
          break
        case "moreThan":
          if (value <= validations[validation]) {
            setError(t(`Value must be more than ${validations[validation]}`))
          }
          break
        case "isNumber":
          if (isNaN(Number(value))) {
            setError(t(`Value must be a number`))
          }
          break
      }
    }
  }

  return { 
    validate, 
    error }
}
